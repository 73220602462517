import {
    AD,
    ADJUST_SITEID,
    ADJUST_SUB_SITEID,
    ADSET,
    CAMPAIGN,
    CLICKS,
    COUNTRY,
    CPI,
    CREATIVE,
    CREATIVE_CONCEPT,
    DATE,
    DAU14D,
    DAU1D,
    DAU30D,
    DAU3D,
    DAU7D,
    DEVICE_CATEGORY,
    EXCHANGE,
    FB_AUDIENCE,
    FB_COUNTRY_TYPE,
    FB_DEVICE,
    FB_OPTIMIZATION,
    FB_PLACEMENT,
    FCST360,
    FULL_APP_NAME,
    IMPRESSIONS,
    INST14D,
    INST1D,
    INST30D,
    INST3D,
    INST7D,
    INSTALLS,
    IPM,
    KEYWORDS,
    MATCH_TYPE,
    MEDIA_SOURCE,
    MONTH,
    NET_REVENUE_14D_WEB_REPORT,
    NET_REVENUE_1D_WEB_REPORT,
    NET_REVENUE_30D_WEB_REPORT,
    NET_REVENUE_3D_WEB_REPORT,
    NET_REVENUE_60D_WEB_REPORT,
    NET_REVENUE_7D_WEB_REPORT,
    NET_REVENUE_90D_WEB_REPORT,
    NET_REVENUE_WEB_REPORT,
    OS_VERSION,
    PAYERS,
    PAYERS1,
    PAYERS1_PERCENT,
    PAYERS2,
    PAYERS2_PERCENT,
    PAYERS3,
    PAYERS3_PERCENT,
    PAYERS7,
    PAYERS7_PERCENT,
    PAYERS_PERCENT,
    RELEASE,
    RET14D,
    RET1D,
    RET30D,
    RET3D,
    RET7D,
    ROAS,
    ROAS14D_FACT_WEB,
    ROAS1D_FACT_WEB,
    ROAS30D_FACT_WEB,
    ROAS3D_FACT_WEB,
    ROAS60D_FACT_WEB,
    ROAS7D_FACT_WEB,
    ROAS90D_FACT_WEB,
    SITE_NAME,
    SPEND,
    TITLE,
    WEEK_NORMALIZED
} from "../common/ColumnDefs";
import {FilterDef} from "./common/ReportFiltersDef";

export const WEB_REPORT_COLUMNS = [
    MONTH, WEEK_NORMALIZED, TITLE, FULL_APP_NAME, DATE, MEDIA_SOURCE, FB_COUNTRY_TYPE, CAMPAIGN, FB_PLACEMENT,
    FB_OPTIMIZATION, FB_AUDIENCE, FB_DEVICE, CREATIVE_CONCEPT, ADSET, COUNTRY, ADJUST_SITEID, ADJUST_SUB_SITEID, SITE_NAME,
    CREATIVE, AD, EXCHANGE, KEYWORDS, DEVICE_CATEGORY, OS_VERSION, MATCH_TYPE, RELEASE,

    SPEND, IMPRESSIONS, CLICKS,

    INSTALLS, CPI, IPM,

    INST1D, INST3D, INST7D, INST14D, INST30D,
    RET1D, RET3D, RET7D, RET14D, RET30D, DAU1D, DAU3D, DAU7D, DAU14D, DAU30D,
    PAYERS, PAYERS1, PAYERS2, PAYERS3, PAYERS7, PAYERS_PERCENT, PAYERS1_PERCENT, PAYERS2_PERCENT, PAYERS3_PERCENT, PAYERS7_PERCENT,
    NET_REVENUE_WEB_REPORT, NET_REVENUE_1D_WEB_REPORT, NET_REVENUE_3D_WEB_REPORT, NET_REVENUE_7D_WEB_REPORT, NET_REVENUE_14D_WEB_REPORT, NET_REVENUE_30D_WEB_REPORT, NET_REVENUE_60D_WEB_REPORT, NET_REVENUE_90D_WEB_REPORT,
    ROAS1D_FACT_WEB, ROAS3D_FACT_WEB, ROAS7D_FACT_WEB, ROAS14D_FACT_WEB, ROAS30D_FACT_WEB, ROAS60D_FACT_WEB, ROAS90D_FACT_WEB,
    FCST360, ROAS
]

export const WEB_REPORT_DEFAULT_GROUPINGS = [
    FULL_APP_NAME.colId, MONTH.colId, WEEK_NORMALIZED.colId, MEDIA_SOURCE.colId, CAMPAIGN.colId,
    DATE.colId, COUNTRY.colId, AD.colId, ADJUST_SITEID.colId, ADSET.colId
];

export const WEB_REPORT_DEFAULT_SORTING_MODEL = [
    {colId: MONTH.colId, sort: "asc"},
    {colId: WEEK_NORMALIZED.colId, sort: "asc"},
    {colId: DATE.colId, sort: "asc"},
    {colId: SPEND.colId, sort: "desc"}
]

export const WEB_REPORT_FILTERS_CONFIG = [
    FilterDef.TITLE,
    FilterDef.APP,
    FilterDef.MEDIA_SOURCE,
    FilterDef.CAMPAIGN,
    FilterDef.COUNTRY,
    FilterDef.INSTALL_DATE,
    FilterDef.SITE_ID,
]
