import React from "react";
import {Button, UncontrolledDropdown} from "reactstrap";
import DropdownToggle from "reactstrap/es/DropdownToggle";
import DropdownMenu from "reactstrap/es/DropdownMenu";
import DropdownItem from "reactstrap/es/DropdownItem";
import ProfileNameModal from "./ProfileNameModal";

class SearchButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showProfileNameModal: false
        }
        this.toggleProfileNameModal = this.toggleProfileNameModal.bind(this)
        this.handleProfileSave = this.handleProfileSave.bind(this)
    }

    toggleProfileNameModal() {
        this.setState({showProfileNameModal: !this.state.showProfileNameModal})
    }

    handleProfileSave(profileName) {
        return this.props.handleProfileSave(profileName).finally(() => this.toggleProfileNameModal())
    }

    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
                <div style={{minHeight: '10px'}}/>
                <UncontrolledDropdown group style={{alignSelf: 'flex-end', width: '100%'}}>
                    <Button onClick={this.props.onSearchClick} style={{'width': '90%'}} color="success">
                        Search
                    </Button>
                    <DropdownToggle caret color="success"/>
                    <DropdownMenu>
                        <DropdownItem onClick={this.props.onExportClick}>
                            Export
                        </DropdownItem>

                        <DropdownItem onClick={this.toggleProfileNameModal}>
                            Save as profile
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
                <ProfileNameModal showModal={this.state.showProfileNameModal}
                                  handleProfileSave={this.handleProfileSave}
                                  closeModal={this.toggleProfileNameModal}/>
            </div>
        )
    }
}

export default SearchButton;
