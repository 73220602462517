import React from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// core components
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import TikTokCreateTab from "./TikTokCreateTab";
import TikTokUpdateTab from "./TikTokUpdateTab";
import TiktokAutoUploadTab from "./TikTokAutoUploadTab";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};
makeStyles(styles);
export default function TikTokAudience(props) {

    document.title = 'TikTok Audience';

    const [value, setValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div class="tik-tok-container">
            <Tabs
                value={value}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon tabs example">
                <Tab icon={<AccessibilityNewIcon/>} aria-label="favorite" label="Create"/>
                <Tab icon={<AccessibilityNewIcon/>} aria-label="favorite" label="Update"/>
                <Tab icon={<AccessibilityNewIcon/>} aria-label="favorite" label="Autoupload"/>
            </Tabs>
            <TabPanel value={value} index={0}>
                <TikTokCreateTab {...props}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TikTokUpdateTab/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <TiktokAutoUploadTab/>
            </TabPanel>
        </div>
    );
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
