import React, {useEffect} from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {makeStyles} from "@material-ui/core/styles";
import {useSnackbar} from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {useHistory} from "react-router-dom";
import {emptyFbTest, emptyGATest, emptyISTest, emptyLiftoffTest, emptyUnityTest, zeroStub} from "./templates/Mocks";
import ABTestExcelTemplate from "./templates/ABTestExcelTemplate";
import Button from "../../components/CustomButtons/Button";
import {calculateTestMetrics} from "../../common/CreativeTestFunctions";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";


const useStyles = makeStyles((theme) => ({
    bodyBottomPadding: {
        paddingBottom: '150px'
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


export default function FacebookTestPage(props) {
    const classes = useStyles();
    let history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [id] = React.useState(props.id);
    const [isNew] = React.useState(props.isNew);
    const [creativeNames, setCreativeNames] = React.useState([]);
    const [isFetched, setIsFetched] = React.useState(false);
    const [showCalc, setShowCalc] = React.useState(false);
    const [template, setTemplate] = React.useState("Facebook");
    const [response, setResponse] = React.useState(emptyFbTest);
    const [open, setOpen] = React.useState(false);
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        fetchData();
    }, [])

    function showLoading() {
        setLoading(true);
    }

    function hideLoading() {
        setLoading(false);
    }

    async function fetchData() {
        showLoading();
        if (!isNew) {
            fetch("/abtest/" + id, {credentials: "include"})
                .then(resp => resp.json())
                .then(resp => {
                    if (resp === "") {
                        enqueueSnackbar('Error to read "/abtest/"' + id);
                    } else {
                        setResponse(resp);
                    }
                    setIsFetched(true);
                })
                .catch(error => console.log(error));
        } else {
            setResponse(emptyFbTest);
        }
        fetch("/api/catalog/creativeNames", {credentials: "include"})
            .then(resp => resp.json())
            .then(resp => {
                if (resp === "") {

                } else {
                    setCreativeNames(resp);
                }
            })
            .catch(error => console.log(error))
            .finally(() => hideLoading());
    }

    const prepareRequest = () => {
        response.tests.forEach(test => {
            let calc = calculateTestMetrics(test);
            test.result = calc.testResult;
            if (test.id < 0) {
                test.control = zeroStub
                test.variation = zeroStub
            }
        })
        return response;
    }

    const saveAbtest = () => {
        showLoading();
        const cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith('XSRF-TOKEN'))
            .split('=')[1]
        const body = JSON.stringify(prepareRequest())
        const method = isNew ? "POST" : "PUT"
        const requestOptions = {
            method: method,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-XSRF-TOKEN": cookieValue
            },
            body: body
        };
        let url = "/abtest/" + (isNew ? "" : props.id);
        fetch(url, requestOptions)
            .then(async response => {
                let status = response.status;
                if (status === 200) {
                    enqueueSnackbar('A/B test saved!');
                    await response.json();
                    history.push('/tool/newabtest');
                    window.location.reload()
                } else {
                    enqueueSnackbar('Failed to save. Error message: ' + response.text());
                }
            })
            .catch(error => {
                enqueueSnackbar(error);
            })
            .finally(() => hideLoading());
        handleClose();
    };

    const onTemplateUpdate = (template) => {
        let mock;
        switch (template) {
            case "Facebook":
                mock = emptyFbTest;
                break;
            case "Google":
                mock = emptyGATest;
                break;
            case "Ironsource":
                mock = emptyISTest;
                break;
            case "Unity":
                mock = emptyUnityTest;
                break;
            case "Liftoff":
                mock = emptyLiftoffTest;
                break;
            default:
                mock = emptyFbTest;
                break;
        }
        setResponse(mock);
    }

    const onShowCalcClick = () => {
        setShowCalc(!showCalc);
    }

    const handleDateChange = (network) => picker => {
        let testList = response.tests;
        let editedTest = testList.find(test => test.network === network);
        editedTest = {
            ...editedTest,
            startDate: picker.startDate.format("YYYY-MM-DD"),
            endDate: picker.endDate.format("YYYY-MM-DD")
        };
        let newList = [];
        testList.forEach(obj => {
            newList.push(obj.network === editedTest.network ? editedTest : obj);
        })
        setResponse({...response, tests: newList});
    }

    const handleChange = (network, changes) => {
        let testList = response.tests;
        let editedTest = testList.find(test => test.network === network);
        changes.forEach(c => {
            let value;
            let column = c[1];
            let row = c[0] === 0 ? "control" : "variation";
            let changeValue = c[3];
            if (column === 'adName' || column === 'country')
                value = changeValue;
            else {
                let asNumber = Number(changeValue);
                value = Number.isNaN(asNumber) ? editedTest[row][column] : asNumber;
            }
            if (editedTest.id < 0) {
                editedTest.id = 0;
            }
            editedTest = {...editedTest, [row]: {...editedTest[row], [column]: value}};
        })
        let newList = [];
        testList.forEach(obj => {
            newList.push(obj.network === editedTest.network ? editedTest : obj);
        })
        setResponse({...response, tests: newList});
    }

    const handleTemplateChange = (event) => {
        setTemplate(event.target.value);
        onTemplateUpdate(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.bodyBottomPadding}>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        <GridContainer >
            <GridItem xs={2} sm={2} md={2}>
                <Button type={"submit"} variant="contained" onClick={handleClickOpen} color="primary">Save
                    result</Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{"Сохранить результат теста?"}</DialogTitle>
                    <DialogContent>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={saveAbtest} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
                <Button color={showCalc ? "secondary" : "primary"}
                        onClick={onShowCalcClick}>{showCalc ? "Hide calculations" : "Show calculations"}</Button>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
                <Button color="primary"
                        onClick={()=>history.push('/tool/abtests')}>{"Total list"}</Button>
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
                {isNew ?
                    <Select
                        value={template}
                        onChange={handleTemplateChange}
                    >
                        <MenuItem value={"Facebook"}>Facebook</MenuItem>
                        <MenuItem value={"Google"}>Google</MenuItem>
                        <MenuItem value={"Ironsource"}>Ironsource</MenuItem>
                        <MenuItem value={"Unity"}>Unity</MenuItem>
                        <MenuItem value={"Liftoff"}>Liftoff</MenuItem>
                    </Select>
                    : null}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                {
                    isNew || isFetched ?
                        response ? response.tests.filter(abTest => !abTest.network.includes("Live")).map(abTest => {
                            return <ABTestExcelTemplate
                                name={""}
                                abTest={abTest}
                                onChange={handleChange}
                                onDateChange={handleDateChange}
                                onClick={saveAbtest}
                                showCalc={showCalc}
                                creativeNames={creativeNames}
                            />
                        }) : null
                        : "loading"
                }
            </GridItem>
        </GridContainer>
        </div>
    );
}