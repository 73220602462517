import {getParamsData} from "./Utils";
import moment from "moment";

export function incompleteDayRenderer(day) {
    return (params) => {
        if (params.value === null || params.value === undefined) {
            return '<div>N/A</div>';
        }

        let dt = getParamsData(params);
        let cohort_date = dt.install_date;
        if (cohort_date === undefined && dt.normalized_week_start !== undefined) {
            cohort_date = moment(dt.normalized_week_start).add(6, 'days');
        }
        if (cohort_date === undefined) {
            cohort_date = params.endDate;
        }
        let value = params.valueFormatted !== undefined ? params.valueFormatted : params.value;
        if (cohort_date !== undefined && moment().diff(cohort_date, 'days') - 1 < day) {
            return '<div style="background-color: #FEF9CD">' + value + '</div>';
        }
        return '<div>' + value + '</div>';
    }
}

export function roasDriftingRenderer() {
    return (params) => {
        const roasDrifting = params.value
        const value = params.valueFormatted

        if (roasDrifting < -0.1) {
            return '<div style="background-color: #ff9f90">' + value + '</div>';
        }
        if (roasDrifting > 0.1) {
            return '<div style="background-color: #D3F1D4">' + value + '</div>';
        }

        return '<div>' + value + '</div>'
    }
}

export function numberFormatter(scale) {
    return (params) => {
        const valueAsNumber = params.value * 1;
        return parseFloat(valueAsNumber.toFixed(scale)).toLocaleString();
    }
}

export function percentageFormatter(scale) {
    return (params) => {
        return params.value !== null && params.value !== undefined
            ? parseFloat((params.value * 100).toFixed(scale)).toLocaleString() + '%'
            : 'N/A';
    }
}

export function creativeReportHighlighter(column, params) {
    let retargetingColumns = new Set(['LTV360', 'Ret 1D', 'Ret 3D', 'Ret 7D']);
    let dt = getParamsData(params);
    let retargeting = dt.full_app_name !== undefined ? dt.full_app_name.indexOf('Retargeting') !== -1 : false;
    let googleAds = dt.media_source !== undefined ? dt.media_source.indexOf('googleadwords_int') !== -1 : false;
    if ((retargetingColumns.has(column) && retargeting) || googleAds) {
        return {backgroundColor: '#ff9f90'};
    }
    return {};
}
