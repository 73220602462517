import moment from "moment";

export class ReportUtil {

    static buildInitialValues(config) {
        const state = {}
        const filterConfigs = config.filter(filterConfig => filterConfig.type !== FilterType.NEW_LINE)
        for (const filterConfig of filterConfigs) {
            state[filterConfig.key] = filterConfig.defaultValue
        }
        return state;
    }

    static buildInitialOptions(config) {
        const options = {}
        const filterConfigs = config.filter(filterConfig => filterConfig.type !== FilterType.NEW_LINE)
        for (const filterConfig of filterConfigs) {
            if (filterConfig.optionsFunc) {
                options[filterConfig.key + '_options'] = []
            } else if (filterConfig.options) {
                options[filterConfig.key + '_options'] = filterConfig.options
            }
        }
        return options;
    }

    static getDefaultStartDate() {
        return moment('2021-03-30').isBefore(moment().startOf('week').subtract(4, 'week'))
            ? moment().startOf('week').subtract(4, 'week').format("YYYY-MM-DD")
            : moment('2021-03-30').format("YYYY-MM-DD");
    }

    static getDefaultEndDate() {
        return moment().subtract(2, 'days').format("YYYY-MM-DD");
    }

    static buildFilterModel(state, filtersConfigs) {
        let filters = {}
        for (const [filterKey, filterValue] of Object.entries(state)) {
            let filterConfig = filtersConfigs.find(config => config.key === filterKey);

            if (!filterValue) continue
            if (!filterConfig) continue

            if (filterConfig.filterModelFunc) {
                filters = {...filters, ...filterConfig.filterModelFunc(filterValue)}
            } else if (filterConfig.type === FilterType.MULTISELECT) {
                let values = filterValue.map((option) => option['value']);
                if (!values.includes('All')) {
                    filters[filterKey] = {'filterType': 'set', 'values': values};
                }
            } else if (filterConfig.type === FilterType.INPUT) {
                filters[filterKey] = {
                    'filterType': 'string',
                    'filter': `%${filterValue}%`,
                    'type': 'likeCaseInsensitive'
                }
            } else if (filterConfig.type === FilterType.DATE_RANGE) {
                filters[filterKey] = {
                    'filterType': 'date',
                    'type': 'inRange',
                    'filter': filterValue.startDate,
                    'filterTo': filterValue.endDate
                }
            } else if (filterConfig.type === FilterType.SELECT) {
                if (filterValue.value !== 'All') {
                    filters[filterKey] = {'filterType': 'string', 'filter': filterValue.value, "type": "equals"};
                }
            }
        }

        return filters;
    }

    static buildAggregateFilterModel(state, filtersConfigs) {
        let filters = {}
        for (const [filterKey, filterValue] of Object.entries(state)) {
            if (!filterValue) continue

            let filterConfig = filtersConfigs.find(config => config.key === filterKey);
            if (filterConfig.aggregateFilterModelFunc) {
                filters = {...filters, ...filterConfig.aggregateFilterModelFunc(filterValue)}
            }
        }

        return filters;
    }

    static getProfileFromLocalStorage(profileKey) {
        let profile = localStorage.getItem("selected_profile_" + profileKey);
        return profile ? JSON.parse(profile) : null;
    }

    static removeProfileFromLocalStorage(profileKey) {
        return localStorage.removeItem("selected_profile_" + profileKey);
    }

    static saveProfileToLocalStorage(profileKey, profile) {
        return localStorage.setItem("selected_profile_" + profileKey, JSON.stringify(profile));
    }
}

export const FilterType = Object.freeze({
    MULTISELECT: 'multiselect',
    SELECT: 'select',
    DATE_RANGE: 'date_range',
    INPUT: 'input',
    NEW_LINE: 'new_line',
    CHECKBOX: 'checkbox'
});

