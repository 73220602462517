import moment from "moment";

export function getParamsData(params) {
    let dt = params.data;
    if (!dt) {
        dt = params.node.aggData;
    }
    return dt;
}

export function show(column) {
    let clone = Object.assign({}, column);
    clone.hide = false;
    return clone;
}

export function hide(column) {
    let clone = Object.assign({}, column);
    clone.hide = true;
    return clone;
}

export function renameHeader(column, newHeader) {
    let clone = Object.assign({}, column);
    clone.headerName = newHeader;

    return clone;
}

export function setHighlighter(column, highlighter) {
    let clone = Object.assign({}, column);
    clone.cellStyle = curry(highlighter)(clone.headerName);
    return clone;
}

export function curry(func) {
    return (arg) => (secondArg) => func(arg, secondArg);
}

export function addGroupIdToRow(row, groupIds, groupKeys) {
    // id's are created using a simple heuristic based on group keys: i.e. group node ids will
    // be in the following format: 'Russia', 'Russia-2002'
    let groupPart = groupIds
        .map(function (id) {
            return row[id];
        })
        .join('-');
    row.id = groupKeys.length > 0 ? groupKeys.join('-') + groupPart : groupPart;
    return row;
}

export function addGroupIdsToRows(request, rows) {
    let rowGroupIds = request.rowGroupCols.map(function (group) {
        return group.id;
    });
    let groupKeys = request.groupKeys;

    if (groupKeys.length === rowGroupIds.length) return rows;

    let groupIds = rowGroupIds.slice(0, groupKeys.length + 1);
    return rows.map(function (row) {
        return addGroupIdToRow(row, groupIds, groupKeys);
    });
}

export function yesterday() {
    let yesterdayDate = new Date();
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    yesterdayDate.setUTCHours(0, 0, 0, 0);

    return yesterdayDate.getTime();
}

export function deleteCookie(name, path, domain) {
    if (has_cookie(name)) {
        document.cookie = name + "=" +
            ((path) ? ";path=" + path : "") +
            ((domain) ? ";domain=" + domain : "") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
}

export function getBiUserId() {
    let jwtToken = get_cookie('AUTHORIZATION');
    if (jwtToken) {
        let authInfo = parseJwt(jwtToken)
        return authInfo['bi_user_id']
    }
    return null
}

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export function has_cookie(name) {
    return document.cookie.split(';').some(c => c.trim().startsWith(name + '='));
}

export function get_cookie(name) {
    let cookieWithName = document.cookie.split(';').find(c => c.trim().startsWith(name + '='));
    if (cookieWithName) {
        return cookieWithName.split(name + "=")[1];
    }
}

export function getDefaultFilterStartDate() {
    return moment('2021-03-30').isBefore(moment().startOf('week').subtract(4, 'week'))
        ? moment().startOf('week').subtract(4, 'week').format("YYYY-MM-DD")
        : moment('2021-03-30').format("YYYY-MM-DD");
}

export function getDefaultFilterEndDate() {
    return moment().subtract(2, 'days').format("YYYY-MM-DD");
}

export function objectWithoutKey(object, key) {
    const {[key]: deletedKey, ...otherKeys} = object;
    return otherKeys;
}