import moment from "moment";
import {getParamsData} from "./Utils";

/**
 * UAM
 */

export function weekGetter(params) {
    let dt = getParamsData(params);
    return dt ? moment(dt.week_start).format("YYYY-MM-DD") : "";
}

export function monthGetter(params) {
    let dt = getParamsData(params);
    return dt ? moment(dt.month_start).format("YYYY-MM-DD") : "";
}

export function dateGetter(params) {
    let dt = getParamsData(params);
    if (dt)
        return moment(dt.install_date).format("YYYY-MM-DD");
    return "";
}

export function payersGetter(params) {
    let dt = getParamsData(params);
    let installs = dt.installs * 1;
    let payers = dt.payers * 1.0;

    return installs > 0 ? payers / installs : 0;
}

export function payers1Getter(params) {
    let dt = getParamsData(params);
    let installs = dt.installs * 1;
    let payers1 = dt.payers1 * 1.0;

    return installs > 0 ? payers1 / installs : 0;
}

export function payers2Getter(params) {
    let dt = getParamsData(params);
    let installs = dt.installs * 1;
    let payers2 = dt.payers2 * 1.0;

    return installs > 0 ? payers2 / installs : 0;
}

export function payers3Getter(params) {
    let dt = getParamsData(params);
    let installs = dt.installs * 1;
    return installs > 0 ? dt.payers3 * 1.0 / installs : 0;
}

export function payers7Getter(params) {
    let dt = getParamsData(params);
    let installs = dt.installs * 1;
    return installs > 0 ? dt.payers7 * 1.0 / installs : 0;
}

export function cpiGetter(params) {
    let dt = getParamsData(params);
    return (dt.installs && dt.installs > 0) ? dt.spend / dt.installs : 0;
}

export function cpiLoginCompleteGetter(params) {
    let dt = getParamsData(params);
    return (dt.login_complete && dt.login_complete > 0) ? dt.spend / dt.login_complete : 0;
}

export function retention1Getter(params) {
    let dt = getParamsData(params);
    return (dt.inst1 && dt.inst1 > 0) ? dt.dau1 * 1.0 / dt.inst1 : 0;
}

export function retention2Getter(params) {
    let dt = getParamsData(params);
    return (dt.inst2 && dt.inst2 > 0) ? dt.dau2 * 1.0 / dt.inst2 : 0;
}

export function retention1Cost(params) {
    let dt = getParamsData(params);
    return (dt.dau1 && dt.dau1 > 0) ? dt.spend / dt.dau1 : 0;
}

export function retention2Cost(params) {
    let dt = getParamsData(params);
    return (dt.dau2 && dt.dau2 > 0) ? dt.spend / dt.dau2 : 0;
}

export function retentionPerMile1(params) {
    let dt = getParamsData(params);
    return (dt.impressions && dt.impressions > 0) ? dt.dau1 * 1000.0 / dt.impressions : 0;
}

export function retentionPerMile2(params) {
    let dt = getParamsData(params);
    return (dt.impressions && dt.impressions > 0) ? dt.dau2 * 1000.0 / dt.impressions : 0;
}

export function negRetention1Getter(params) {
    let dt = getParamsData(params);
    return (dt.installs && dt.installs > 0) ? dt.adj_dau1 * 1.0 / dt.installs : 0;
}

export function negRetention2Getter(params) {
    let dt = getParamsData(params);
    return (dt.installs && dt.installs > 0) ? dt.adj_dau2 * 1.0 / dt.installs : 0;
}

export function retention3Getter(params) {
    let dt = getParamsData(params);
    let inst3 = dt.inst3 * 1;
    return inst3 > 0 ? dt.dau3 * 1.0 / inst3 : 0;
}

export function negRetention3Getter(params) {
    let dt = getParamsData(params);
    return (dt.installs && dt.installs > 0) ? dt.adj_dau3 * 1.0 / dt.installs : 0;
}

export function retention7Getter(params) {
    let dt = getParamsData(params);
    return (dt.inst7 && dt.inst7 > 0) ? dt.dau7 * 1.0 / dt.inst7 : 0;
}

export function negRetention7Getter(params) {
    let dt = getParamsData(params);
    return (dt.installs && dt.installs > 0) ? dt.adj_dau7 * 1.0 / dt.installs : 0;
}

export function retention14Getter(params) {
    let dt = getParamsData(params);
    return (dt.inst14 && dt.inst14 > 0) ? dt.dau14 * 1.0 / dt.inst14 : 0;
}

export function retention30Getter(params) {
    let dt = getParamsData(params);
    return (dt.inst30 && dt.inst30 > 0) ? dt.dau30 * 1.0 / dt.inst30 : 0;
}

export function retention1TooltipGetter(params) {
    return getRetentionTooltip(params, 1);
}

export function retention2TooltipGetter(params) {
    return getRetentionTooltip(params, 2);
}

export function retention3TooltipGetter(params) {
    return getRetentionTooltip(params, 3);
}

export function retention7TooltipGetter(params) {
    return getRetentionTooltip(params, 7);
}

export function retention14TooltipGetter(params) {
    return getRetentionTooltip(params, 14);
}

export function retention30TooltipGetter(params) {
    return getRetentionTooltip(params, 30);
}

function getRetentionTooltip(params, day) {
    let dt = getParamsData(params);
    let dau = +dt[`dau${day}`]
    let inst = +dt[`inst${day}`]
    let retentionMin = getRangeMin(dau, inst)
    let retentionMax = getRangeMax(dau, inst)
    return `Ret ${day}D min: ${retentionMin.toFixed(4) * 100}%; Ret ${day}D max: ${retentionMax.toFixed(4) * 100}%`;
}

export function payers1TooltipGetter(params) {
    return getPayersTooltip(params, 1);
}

export function payers2TooltipGetter(params) {
    return getPayersTooltip(params, 2);
}

export function payers3TooltipGetter(params) {
    return getPayersTooltip(params, 3);
}

export function payers7TooltipGetter(params) {
    return getPayersTooltip(params, 7);
}

function getPayersTooltip(params, day) {
    let dt = getParamsData(params);
    let payers = +dt[`payers${day}`];
    let installs = +dt.installs;
    let payersMin = getRangeMin(payers, installs)
    let payersMax = getRangeMax(payers, installs)

    return `Payers${day}% min: ${payersMin.toFixed(4) * 100}%; Payers${day}% max: ${payersMax.toFixed(4) * 100}%`;
}

export function cpa1TooltipGetter(params) {
    return getCpaTooltip(params, 1)
}

export function cpa2TooltipGetter(params) {
    return getCpaTooltip(params, 2)
}

export function cpa3TooltipGetter(params) {
    return getCpaTooltip(params, 3)
}

export function cpa7TooltipGetter(params) {
    return getCpaTooltip(params, 7)
}

export function cpa14TooltipGetter(params) {
    return getCpaTooltip(params, 14)
}

function getCpaTooltip(params, day) {
    let dt = getParamsData(params);
    let cpi = cpiGetter(params)
    let payers = +dt[`payers${day}`];
    let payments = +dt[`payments${day}`]
    let installs = +dt.installs;

    let payersMin = getRangeMin(payers, installs)
    let payersMax = getRangeMax(payers, installs)

    let cpaMin = (cpi * payers) / (payersMax * payments)
    let cpaMax = (cpi * payers) / (payersMin * payments)

    return `CPA${day} min: ${cpaMin.toFixed(1)}; CPA${day} max: ${cpaMax.toFixed(1)}`;
}

export function cpp1TooltipGetter(params) {
    return getCppTooltip(params, 1);
}

export function cpp2TooltipGetter(params) {
    return getCppTooltip(params, 2);
}

export function cpp3TooltipGetter(params) {
    return getCppTooltip(params, 3);
}

export function cpp7TooltipGetter(params) {
    return getCppTooltip(params, 7);
}

export function cpp14TooltipGetter(params) {
    return getCppTooltip(params, 14);
}

function getCppTooltip(params, day) {
    let dt = getParamsData(params);
    let payers = +dt[`payers${day}`];
    let installs = +dt.installs;

    let cpi = cpiGetter(params)
    let payersMin = getRangeMin(payers, installs)
    let payersMax = getRangeMax(payers, installs)

    let cppMin = cpi / payersMax
    let cppMax = cpi / payersMin

    return `CPP${day}d min: ${cppMin.toFixed(1)}; CPP${day}d max: ${cppMax.toFixed(1)}`;
}

export function grossRoas0Getter(params) {
    let dt = getParamsData(params);
    return (dt.spend && dt.spend > 0) ? dt.gross_revenue_total_0 / dt.spend : 0;
}

export function roasFctSkanGetter(params) {
    let dt = getParamsData(params);
    let spend = dt.spend * 1;
    let netRevenue = dt.net_revenue * 1;
    let ad_revenue = dt.ad_revenue * 1;

    return spend > 0 ? (netRevenue + ad_revenue) / spend : 0;
}


export function factRoas0Getter(params) {
    let dt = getParamsData(params);
    let spend = dt.spend * 1;
    let netRevenue0 = dt.net_revenue_total_0 * 1;

    return spend > 0 ? netRevenue0 / spend : 0;
}

export function grossRoas1Getter(params) {
    let dt = getParamsData(params);
    return (dt.spend && dt.spend > 0) ? dt.gross_revenue_total_1 / dt.spend : 0;
}

export function factRoas1Getter(params) {
    let dt = getParamsData(params);
    let spend = dt.spend * 1;
    let netRevenue1 = dt.net_revenue_total_1 * 1;

    return spend > 0 ? netRevenue1 / spend : 0;
}

export function grossRoas2Getter(params) {
    let dt = getParamsData(params);
    return (dt.spend && dt.spend > 0) ? dt.gross_revenue_total_2 / dt.spend : 0;
}

export function grossRoas3Getter(params) {
    let dt = getParamsData(params);
    return (dt.spend && dt.spend > 0) ? dt.gross_revenue_total_3 / dt.spend : 0;
}

export function factRoas2Getter(params) {
    let dt = getParamsData(params);
    let spend = dt.spend * 1;
    let netRevenue2 = dt.net_revenue_total_2 * 1;

    return spend > 0 ? netRevenue2 / spend : 0;
}

export function factRoas3Getter(params) {
    let dt = getParamsData(params);
    let spend = dt.spend * 1;
    let netRevenue3 = dt.net_revenue_total_3 * 1;

    return spend > 0 ? netRevenue3 / spend : 0;
}

export function nullCvShare(params) {
    let dt = getParamsData(params);
    let installs = +dt.installs;
    let nullCvCount = +dt.null_cv_count;
    return (installs && installs > 0) ? nullCvCount / installs : 0;
}

export function zeroCvShare(params) {
    let dt = getParamsData(params);
    let installs = +dt.installs;
    let zeroCvCount = +dt.zero_cv_count;
    return (installs && installs > 0) ? zeroCvCount / installs : 0;
}

export function grossRoas7Getter(params) {
    let dt = getParamsData(params);
    return (dt.spend && dt.spend > 0) ? dt.gross_revenue_total_7 / dt.spend : 0;
}

export function factRoas7Getter(params) {
    let dt = getParamsData(params);
    let spend = dt.spend * 1;
    let netRevenue7 = dt.net_revenue_total_7 * 1;

    return spend > 0 ? netRevenue7 / spend : 0;
}

export function grossRoas14Getter(params) {
    let dt = getParamsData(params);
    return (dt.spend && dt.spend > 0) ? dt.gross_revenue_total_14 / dt.spend : 0;
}

export function factRoas14Getter(params) {
    let dt = getParamsData(params);
    let spend = dt.spend * 1;
    let netRevenue14 = dt.net_revenue_total_14 * 1;
    return spend > 0 ? netRevenue14 / spend : 0;
}

export function grossRoas30Getter(params) {
    let dt = getParamsData(params);
    return (dt.spend && dt.spend > 0) ? dt.gross_revenue_total_30 / dt.spend : 0;
}

export function factRoas30Getter(params) {
    let dt = getParamsData(params);
    let spend = dt.spend * 1;
    let netRevenue30 = dt.net_revenue_total_30 * 1;
    return spend > 0 ? netRevenue30 / spend : 0;
}

export function factRoas60Getter(params) {
    let dt = getParamsData(params);
    let spend = dt.spend * 1;
    let netRevenue60 = dt.net_revenue_total_60 * 1;
    return spend > 0 ? netRevenue60 / spend : 0;
}

export function factRoas90Getter(params) {
    let dt = getParamsData(params);
    let spend = dt.spend * 1;
    let netRevenue90 = dt.net_revenue_total_90 * 1;
    return spend > 0 ? netRevenue90 / spend : 0;
}

export function factRoas180Getter(params) {
    let dt = getParamsData(params);
    let spend = dt.spend * 1;
    let netRevenue180 = dt.net_revenue_total_180 * 1;
    return spend > 0 ? netRevenue180 / spend : 0;
}

export function ltvGetter(params) {
    let dt = getParamsData(params);
    return (dt.installs && dt.installs > 0) ? dt.fcst_360 / dt.installs : 0;
}

export function ltv360OldGetter(params) {
    let dt = getParamsData(params);
    return (dt.installs && dt.installs > 0) ? dt.fcst_360_new / dt.installs : 0;
}

export function adLtvGetter(params) {
    let dt = getParamsData(params);
    return (dt.installs && dt.installs > 0) ? dt.ad_fcst_360 / dt.installs : 0;
}

export function ltv60Getter(params) {
    let dt = getParamsData(params);
    return (dt.installs && dt.installs > 0) ? dt.fcst_60 / dt.installs : 0;
}

export function factLtvGetter(params) {
    let dt = getParamsData(params);
    return (dt.installs && dt.installs > 0) ? dt.net_revenue_total_360 / dt.installs : 0;
}

export function profitFCSTGetter(params) {
    var dt = getParamsData(params);
    return dt.fcst_360 - dt.spend;
}

export function ltvPputGetter(params) {
    var dt = getParamsData(params);
    return (dt.payers && dt.payers > 0) ? dt.fcst_360 / dt.payers : 0;
}

export function roas360Getter(params) {
    var dt = getParamsData(params);
    return (dt.spend && dt.spend > 0) ? dt.fcst_360 / dt.spend : 0;
}

export function roas90Getter(params) {
    var dt = getParamsData(params);
    return (dt.spend && dt.spend > 0) ? dt.fcst_90 / dt.spend : 0;
}

export function roas120Getter(params) {
    var dt = getParamsData(params);
    return (dt.spend && dt.spend > 0) ? dt.fcst_120 / dt.spend : 0;
}

export function roas180Getter(params) {
    var dt = getParamsData(params);
    return (dt.spend && dt.spend > 0) ? dt.fcst_180 / dt.spend : 0;
}

export function roasNewGetter(params) {
    var dt = getParamsData(params);
    return (dt.spend && dt.spend > 0) ? dt.fcst_360_new / dt.spend : 0;
}

export function profit360Getter(params) {
    var dt = getParamsData(params);
    var spend = dt.spend || 0;
    var fcst360 = dt.fcst_360 || 0;

    return fcst360 - spend;
}

export function roasDriftingGetter(params) {
    const dt = getParamsData(params);
    const fcst360 = dt.fcst_360;
    const fcst3607dFixed = dt.fcst_360_d7_fixed;

    return fcst3607dFixed > 0 ? (fcst360 - fcst3607dFixed) / fcst3607dFixed : 0;
}

export function roasTestDriftingGetter(params) {
    const dt = getParamsData(params);
    const fcst360Test = dt.fcst_360_cf_test;
    const fcst3607dFixedTest = dt.fcst_360_cf_d7_fixed_test;

    return fcst3607dFixedTest > 0 ? (fcst360Test - fcst3607dFixedTest) / fcst3607dFixedTest : 0;
}

export function roasTestGetter(params) {
    var dt = getParamsData(params);
    return (dt.spend && dt.spend > 0) ? dt.fcst_360_cf_test / dt.spend : 0;
}

export function eventsCountPercentGetter(params) {
    var dt = getParamsData(params);
    return (dt.installs && dt.installs > 0) ? dt[`events_count_${params.colDef.colId}`] * 1.0 / dt.installs : 0;
}

/**
 * Retargeting
 */


export function reEngagementsShareGetter(params) {
    let dt = getParamsData(params);
    return (dt.installs && dt.installs > 0) ? dt.re_engagements * 1.0 / dt.installs : 0;
}

export function cpcGetter(params) {
    let dt = getParamsData(params);
    return (dt.clicks && dt.clicks > 0) ? dt.spend / dt.clicks : 0;
}

export function vtaGetter(params) {
    let dt = getParamsData(params);
    return (dt.installs && dt.installs > 0) ? dt.vta * 1.0 / dt.installs : 0;
}


/**
 * Creative
 */

export function cpmGetter(params) {
    let dt = getParamsData(params);
    return (dt.impressions && dt.impressions > 0) ? 1000 * dt.spend / dt.impressions : 0;
}


export function ctrGetter(params) {
    let dt = getParamsData(params);
    return (dt.impressions && dt.impressions > 0) ? dt.clicks / dt.impressions : 0;
}

export function irGetter(params) {
    let dt = getParamsData(params);
    return (dt.clicks && dt.clicks > 0) ? dt.installs / dt.clicks : 0;
}

export function cvrGetter(params) {
    let dt = getParamsData(params);
    return (dt.impressions && dt.impressions > 0) ? dt.installs / dt.impressions : 0;
}

export function ipmGetter(params) {
    let dt = getParamsData(params);
    return (dt.impressions && dt.impressions > 0) ? dt.installs * 1000.0 / dt.impressions : 0;
}

export function ipmLoginCompleteGetter(params) {
    let dt = getParamsData(params);
    return (dt.impressions && dt.impressions > 0) ? dt.login_complete * 1000.0 / dt.impressions : 0;
}

export function ipmTooltipGetter(params) {
    let dt = getParamsData(params);
    let ipm = ipmGetter(params);

    let installs = dt.installs;
    let impressions = dt.impressions;
    let p = installs / impressions
    let se = Math.sqrt((p * (1 - p)) / impressions) * 1000
    let zScore1 = 1.64;
    let interval = zScore1 * se

    let min = ipm - interval;
    let max = ipm + interval;
    return `IPM min: ${Math.round(min * 1000) / 1000}; IPM max: ${Math.round(max * 1000) / 1000}`;
}

function getRangeMin(conversions, samples) {
    const zScore = 1.64
    let mean = samples === 0 ? 0 : conversions / samples
    let interval = zScore * Math.sqrt((mean * (1 - mean)) / samples)
    return mean - interval;
}

function getRangeMax(conversions, samples) {
    const zScore = 1.64
    let mean = samples === 0 ? 0 : conversions / samples
    let interval = zScore * Math.sqrt((mean * (1 - mean)) / samples)
    return mean + interval;
}

export function csGetter(params) {
    let ret3d = retention3Getter(params)
    let ipm = ipmGetter(params)
    return ipm * ret3d;
}

export function csTooltipGetter(params) {
    let dt = getParamsData(params);

    const zScore = 1.64
    let cs = csGetter(params)
    let ret3d = retention3Getter(params)
    let ipm = ipmGetter(params)
    let inst3 = +dt.inst3
    let impressions = +dt.impressions

    let seRet3d = Math.sqrt((ret3d * (1 - ret3d)) / inst3)

    let p = inst3 / impressions
    let seIPM = Math.sqrt((p * (1 - p)) / impressions) * 1000

    let se = cs * Math.sqrt(Math.pow(seRet3d / ret3d, 2) + Math.pow(seIPM / ipm, 2))

    let interval = zScore * se
    let min = cs - interval
    let max = cs + interval

    return `CS min: ${Math.round(min * 10000) / 10000}; CS max: ${Math.round(max * 10000) / 10000}`;
}

export function csCreativeReportGetter(params) {
    let ret3d = retention3CreativeReportGetter(params)
    let ipm = ipmGetter(params)
    return ipm * ret3d;
}

export function cpaGetter(params) {
    let dt = getParamsData(params);
    return (dt.payments && dt.payments > 0) ? dt.spend / dt.payments : 0;
}

export function cpa1Getter(params) {
    let dt = getParamsData(params);
    return (dt.payments1 && dt.payments1 > 0) ? dt.spend / dt.payments1 : 0;
}

export function cpa2Getter(params) {
    let dt = getParamsData(params);
    return (dt.payments2 && dt.payments2 > 0) ? dt.spend / dt.payments2 : 0;
}

export function cpa3Getter(params) {
    let dt = getParamsData(params);
    return (dt.payments3 && dt.payments3 > 0) ? dt.spend / dt.payments3 : 0;
}

export function cpa7Getter(params) {
    let dt = getParamsData(params);
    return (dt.payments7 && dt.payments7 > 0) ? dt.spend / dt.payments7 : 0;
}

export function cpa14Getter(params) {
    let dt = getParamsData(params);
    return (dt.payments14 && dt.payments14 > 0) ? dt.spend / dt.payments14 : 0;
}

export function cppGetter(params) {
    let dt = getParamsData(params);
    return (dt.payers && dt.payers > 0) ? dt.spend / dt.payers : 0;
}

export function cpp1Getter(params) {
    let dt = getParamsData(params);
    return (dt.payers1 && dt.payers1 > 0) ? dt.spend / dt.payers1 : 0;
}

export function cpp2Getter(params) {
    let dt = getParamsData(params);
    return (dt.payers2 && dt.payers2 > 0) ? dt.spend / dt.payers2 : 0;
}

export function cpp3Getter(params) {
    let dt = getParamsData(params);
    return (dt.payers3 && dt.payers3 > 0) ? dt.spend / dt.payers3 : 0;
}

export function cpp7Getter(params) {
    let dt = getParamsData(params);
    return (dt.payers7 && dt.payers7 > 0) ? dt.spend / dt.payers7 : 0;
}

export function cpp14Getter(params) {
    let dt = getParamsData(params);
    return (dt.payers14 && dt.payers14 > 0) ? dt.spend / dt.payers14 : 0;
}

export function tCpa3Getter(percentage, params) {
    let dt = getParamsData(params);
    let cpa3 = (dt.payments3 && dt.payments3 > 0) ? dt.spend / dt.payments3 : 0;
    let roi = 100 * dt.fcst_360 / dt.spend;
    return cpa3 * roi / percentage;
}

export function tCpa7Getter(percentage, params) {
    let dt = getParamsData(params);
    let cpa7 = (dt.payments7 && dt.payments7 > 0) ? dt.spend / dt.payments7 : 0;
    let roi = 100 * dt.fcst_360 / dt.spend;
    return cpa7 * roi / percentage;
}

export function cr(numerator, denominator) {
    return (params) => {
        let dt = getParamsData(params);
        return (dt[denominator] && dt[denominator] > 0) ? dt[numerator] * 1.0 / dt[denominator] : 0;
    }
}

export function retention1CreativeReportGetter(params) {
    let dt = getParamsData(params);
    return (dt.inst1dCreativeReport && dt.inst1dCreativeReport > 0) ? dt.dau1 * 1.0 / dt.inst1dCreativeReport : 0;
}

export function retention3CreativeReportGetter(params) {
    let dt = getParamsData(params);
    return (dt.inst3dCreativeReport && dt.inst3dCreativeReport > 0) ? dt.dau3 * 1.0 / dt.inst3dCreativeReport : 0;
}

export function retention7CreativeReportGetter(params) {
    let dt = getParamsData(params);
    return (dt.inst7dCreativeReport && dt.inst7dCreativeReport > 0) ? dt.dau7 * 1.0 / dt.inst7dCreativeReport : 0;
}

export function ltvCfCombineGetter(params) {
    let dt = getParamsData(params);
    return (dt.installs && dt.installs > 0) ? dt.fcst_360 / dt.installs : 0;
}

export function adLtvCfCombineGetter(params) {
    let dt = getParamsData(params);
    return (dt.installs && dt.installs > 0) ? dt.ad_fcst_360 / dt.installs : 0;
}

export function ltvCfTestGetter(params) {
    let dt = getParamsData(params);
    return (dt.installs && dt.installs > 0) ? dt.fcst_360_cf_test / dt.installs : 0;
}

export function adLtvCfTestGetter(params) {
    let dt = getParamsData(params);
    return (dt.installs && dt.installs > 0) ? dt.ad_fcst_360_cf_test / dt.installs : 0;
}

export function ltvPpuCfCombineGetter(params) {
    var dt = getParamsData(params);
    return (dt.payers && dt.payers > 0) ? dt.fcst_360 / dt.payers : 0;
}

export function c2wGetter(params) {
    let dt = getParamsData(params);
    return (dt.installs && dt.installs > 0) ? +dt.whales / +dt.installs : 0;
}

export function cpwGetter(params) {
    let dt = getParamsData(params);
    return (dt.whales && dt.whales > 0) ? +dt.spend / +dt.whales : 0;
}

export function roasMin(params, dateRangeMin, dateRangeMax) {
    let dt = getParamsData(params);

    let cohortDay = calculateCohortDay(dt, dateRangeMin, dateRangeMax);
    let installs = +dt.installs;
    let spend = +dt.spend;
    let payers = +dt.payers;
    let netRevenue = +dt.net_revenue_total;
    let fcst360 = +dt.fcst_360;
    let adFcst360 = +dt.ad_fcst_360;
    let inappFcst360 = fcst360 - adFcst360;

    return calculateRoasMin(cohortDay, installs, spend, payers, fcst360, adFcst360, inappFcst360, netRevenue)
}

export function calculateRoasMin(cohortDay, installs, spend, payers, fcst360, adFcst360, inappFcst360, netRevenue) {
    let zScore = 1.96;
    let retFake = 0.3;

    // calculate inapp FCST MIN
    let inappConversion = installs > 0 ? payers / (installs + 1) : 0;
    let inappDiaShift = installs > 0 ? (zScore * Math.sqrt((inappConversion * (1 - inappConversion)) / installs)) : 0;
    let inappShiftPlusDays = (cohortDay < 8
        ? 2 - (cohortDay - 1) / 6
        : Math.max(0, 1 - (cohortDay - 7) / (360 - 7))) * inappDiaShift;
    let inappFcst360Min = inappConversion > 0
        ? inappFcst360 * (inappConversion - inappShiftPlusDays) / inappConversion
        : 0;

    // calculate ad FCST MIN
    let retFakeShift = installs > 0 ? (zScore * Math.sqrt((retFake * (1 - retFake)) / installs)) : 0;
    let retFakeShiftPlusDay = (cohortDay < 8
        ? 1.5 - (cohortDay - 1) / 6
        : Math.max(0, 1 - (cohortDay - 7) / (360 - 7))) * retFakeShift;
    let adFcst360Min = adFcst360 * (retFake - retFakeShiftPlusDay) / retFake;

    // calculate ROAS MIN and ROAS FACT
    let roasMin = spend > 0 ? (inappFcst360Min + adFcst360Min) / spend : 0;
    let roasFact = spend > 0 ? netRevenue / spend : 0;

    return Math.max(roasMin, roasFact);
}

export function roasMax(params, dateRangeMin, dateRangeMax) {
    let dt = getParamsData(params);

    let cohortDay = calculateCohortDay(dt, dateRangeMin, dateRangeMax);
    let installs = +dt.installs;
    let payers = +dt.payers;
    let fcst360 = +dt.fcst_360;
    let adFcst360 = +dt.ad_fcst_360;
    let inappFcst360 = fcst360 - adFcst360;
    let spend = +dt.spend;
    let netRevenue = +dt.net_revenue_total;

    return calculateRoasMax(cohortDay, installs, spend, payers, fcst360, adFcst360, inappFcst360, netRevenue)
}

export function calculateRoasMax(cohortDay, installs, spend, payers, fcst360, adFcst360, inappFcst360, netRevenue) {
    let zScore = 1.96;
    let retFake = 0.3;

    // calculate inapp FCST MAX
    let inappConversion = installs > 0 ? payers / (installs + 1) : 0;
    let inappDiaShift = installs > 0 ? (zScore * Math.sqrt((inappConversion * (1 - inappConversion)) / (installs + 1))) : 0;
    let inappShiftPlusDays = (cohortDay < 8
        ? 2 - (cohortDay - 1) / 6
        : Math.max(0, 1 - (cohortDay - 7) / (360 - 7))) * inappDiaShift;
    let inappFcst360Max = inappConversion > 0
        ? inappFcst360 * (inappConversion + inappShiftPlusDays) / inappConversion
        : 0;


    // calculate ad FCST MAX
    let retFakeShift = installs > 0 ? (zScore * Math.sqrt((retFake * (1 - retFake)) / (installs + 1))) : 0;
    let retFakeShiftPlusDay = (cohortDay < 8
        ? 1.5 - (cohortDay - 1) / 6
        : Math.max(0, 1 - (cohortDay - 7) / (360 - 7))) * retFakeShift;
    let adFcst360Max = adFcst360 * (retFake + retFakeShiftPlusDay) / retFake;


    // calculate ROAS MAX and ROAS FACT
    let roasMax = spend > 0 ? (inappFcst360Max + adFcst360Max) / spend : 0;
    let roasFact = spend > 0 ? netRevenue / spend : 0;

    return Math.max(roasMax, roasFact);
}

export function avgCohortDay(params, dateRangeMin, dateRangeMax) {
    let dt = getParamsData(params);

    return calculateCohortDay(dt, dateRangeMin, dateRangeMax);
}

function calculateCohortDay(dt, dateRangeMin, dateRangeMax) {
    let installDate = dt.install_date ? moment(dt.install_date) : null;
    let weekStart = dt.week_start ? moment(dt.week_start) : null;
    let monthStart = dt.month_start ? moment(dt.month_start) : null;
    let now = moment();

    let cohortDay;
    if (installDate) {
        cohortDay = moment().diff(installDate, 'days') - 1;
    } else if (weekStart) {
        let realWeekStart = weekStart.isBefore(dateRangeMin) ? dateRangeMin : weekStart;
        let calendarWeekEnd = weekStart.clone().day(7);
        let realWeekEnd = calendarWeekEnd.isAfter(dateRangeMax) ? dateRangeMax : calendarWeekEnd;

        let weekEndDaysOffset = Math.round(realWeekEnd.diff(realWeekStart, 'days') * 0.4);
        let avgWeekCohortDate = realWeekEnd.clone().subtract(weekEndDaysOffset, 'days');

        cohortDay = now.clone().diff(avgWeekCohortDate, 'days') - 1;
        // console.log(`realWeekStart: ${realWeekStart.format('YYYY-MM-DD')}'; realWeekEnd: ${realWeekEnd.format('YYYY-MM-DD')}; weekEndDaysOffset: ${weekEndDaysOffset}; avgWeekCohortDate: ${avgWeekCohortDate.format('YYYY-MM-DD')}; cohortDay: ${cohortDay}`)
    } else if (monthStart) {
        let realMonthStart = monthStart.isBefore(dateRangeMin) ? dateRangeMin : monthStart;
        let calendarMonthEnd = monthStart.clone().endOf('month').set({hour: 0, minute: 0, second: 0, millisecond: 0});
        let realMonthEnd = calendarMonthEnd.isAfter(dateRangeMax) ? dateRangeMax : calendarMonthEnd;

        let monthEndDaysOffset = Math.round(realMonthEnd.diff(realMonthStart, 'days') * 0.4);
        let avgMonthCohortDate = realMonthEnd.clone().subtract(monthEndDaysOffset, 'days');

        cohortDay = now.clone().diff(avgMonthCohortDate, 'days') - 1;
        // console.log(`realMonthStart: ${realMonthStart.format('YYYY-MM-DD')}; realMonthEnd: ${realMonthEnd.format('YYYY-MM-DD')}; monthEndDaysOffset: ${monthEndDaysOffset} avgMonthCohortDate: ${avgMonthCohortDate.format('YYYY-MM-DD')}; cohortDay=${cohortDay}`)
    } else {
        let dateRangeDaysOffset = Math.round(dateRangeMax.diff(dateRangeMin, 'days') * 0.4);
        let avgDateRangeCohortDate = dateRangeMax.clone().subtract(dateRangeDaysOffset, 'days');

        cohortDay = now.clone().diff(avgDateRangeCohortDate, 'days') - 1;
        // console.log(`dateRangeMin: ${dateRangeMin.format('YYYY-MM-DD')}; dateRangeMax: ${dateRangeMax.format('YYYY-MM-DD')}; dateRangeDaysOffset: ${dateRangeDaysOffset} avgDateRangeCohortDate: ${avgDateRangeCohortDate.format('YYYY-MM-DD')}; cohortDay=${cohortDay}`)
    }

    return cohortDay;
}

export function inappFcst360(params) {
    let dt = getParamsData(params);

    let adFcst360 = +dt.ad_fcst_360;
    let fcst360 = +dt.fcst_360;

    return fcst360 - adFcst360;
}
