import React from 'react';
import '../app/App.css';
import {withCookies} from 'react-cookie';
import {withRouter} from 'react-router-dom';
import {withSnackbar} from 'notistack';
import {ReportApi} from "../api/ReportApi";
import {
    ASO_REPORT_COLUMNS,
    ASO_REPORT_DEFAULT_GROUPINGS,
    ASO_REPORT_DEFAULT_SORTING_MODEL,
    ASO_REPORT_FILTERS_CONFIG
} from "./AsoReportConfig";
import {CatalogApi} from "../api/CatalogApi";
import Report from "./common/Report";
import AppNavbar from "../navigation/AppNavbar";


class AsoReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeCampaigns: []
        }
    }

    componentDidMount() {
        document.title = 'BI ASO';
        CatalogApi.getActiveCampaigns().then(activeCampaigns => this.setState({activeCampaigns}))
    }

    render() {
        return (
            <React.Fragment>
                <AppNavbar authorities={this.props.authorities}/>
                <Report authorities={this.props.authorities}
                        profileKey="ASO_REPORT"
                        filterDefs={ASO_REPORT_FILTERS_CONFIG}
                        gridColumnDefs={ASO_REPORT_COLUMNS}
                        gridDefaultGroupings={ASO_REPORT_DEFAULT_GROUPINGS}
                        gridDefaultSortingModel={ASO_REPORT_DEFAULT_SORTING_MODEL}
                        gridActiveCampaigns={this.state.activeCampaigns}
                        loadFunc={ReportApi.getAsoData}
                />
            </React.Fragment>
        );
    }
}

export default withCookies(withSnackbar(withRouter(AsoReport)));

