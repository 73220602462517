import React, {Component} from 'react';

export default class CustomNoRowsOverlay extends Component {
    render() {
        return (
            <div className="ag-overlay-loading-center" style={{backgroundColor: "lightcoral", height: "9%", fontSize:"2rem"}}>
                <span> {this.props.noRowsMessageFunc()}</span>
            </div>
        );
    }
}