import React, {useEffect} from "react";
import {Backdrop, Button, Card, CardContent, CircularProgress, TextField} from '@material-ui/core';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import {makeStyles} from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useForm} from 'react-hook-form'
import Snackbar from "../../../components/Snackbar/Snackbar";
import AddAlert from "@material-ui/icons/AddAlert";
import MaterialTable from "material-table";
import tableIcons from "assets/jss/material-dashboard-react/components/materialTableStyle.js";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from "@material-ui/core/IconButton";
import {del, get, post} from "../../../common/ApiUtils";
import {CatalogApi} from "../../../api/CatalogApi";


const useStyles = makeStyles((theme) => ({
    root: {
        'margin-top': '1rem',
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


export default function TiktokAutoUploadTab() {
    const classes = useStyles();

    const [audience, setAudience] = React.useState('');
    const [audienceOptions, setAudienceOptions] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [query, setQuery] = React.useState({filters: []});
    const [queryOptions, setQueryOptions] = React.useState([]);
    const [appId, setAppId] = React.useState('');
    const [appOptions, setAppOptions] = React.useState([]);
    const {register, handleSubmit} = useForm()
    const [tl, setTL] = React.useState(false);
    const [tr, setTR] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    function showLoading() {
        setLoading(true);
    }

    function hideLoading() {
        setLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [])

    async function fetchData() {
        CatalogApi.getMainAppWithPlatformOptions().then(apps => {
            setAppOptions(apps);
            setAppId(apps[0])
            loadAudiencesByAppId(apps[0].value)
            loadTableData(apps[0].value)
        })
        CatalogApi.getAutoUpdateAudienceQueryOptions().then(options => {
            setQuery(options[0]);
            setQueryOptions(options);
        })
    }


    function loadTableData(appId) {
        showLoading();

        get("/api/tiktok/auto-upload?appId=" + appId)
            .then(resp => {
                if (resp.status !== 404) {
                    setTableData(resp.body);
                }
            })
            .finally(() => {
                hideLoading()
            });
    }

    const showNotification = place => {
        switch (place) {
            case "tl":
                setTL(true);
                break;
            case "tr":
                setTR(true);
                break;
            default:
                break;
        }
    }

    function createAutoUploadJob(data) {
        showLoading();

        let body = getBody(data);
        post("/api/tiktok/auto-upload", body)
            .then(async response => {
                let status = response.status;
                if (status === 200) {
                    setSuccessMessage(`Autoupdate under account ${body.advertiserId} successfully created`)
                    showNotification("tl");
                } else {
                    setErrorMessage(response.body);
                    showNotification("tr");
                }
            })
            .catch(error => {
                setErrorMessage(error.toString());
                showNotification("tr");
            })
            .finally(() => {
                hideLoading();
                loadTableData(appId.value)
            });
    }

    function getBody(data) {
        let filters = {}
        query.filters.forEach(filter => {
            if (filter.name === "eventDate" || filter.name === "installDate") {
                filters.startDate = data.startDate
                filters.endDate = data.endDate
            } else if (filter.name === "appId") {
                filters.appId = appId.value;
            } else {
                let value = data[filter.name].value;
                if (value) {
                    filters[filter.name] = value
                } else {
                    filters[filter.name] = data[filter.name]
                }
            }
        })
        return {
            appId: appId.value,
            audienceId: audience.value,
            advertiserId: audience.advertiserId,
            queryId: query.value,
            filters: JSON.stringify(filters)
        };
    }

    function loadAudiencesByAppId(appId) {
        setAudience('')
        setAudienceOptions([])
        get("/api/tiktok/audiences?appId=" + appId, {credentials: "include"})
            .then(resp => {
                const audienceOptions = [];
                resp.body.forEach(elem => {
                    audienceOptions.push({
                        value: elem.id,
                        label: elem.name,
                        advertiserId: elem.advertiserId,
                        id: "audience"
                    })
                });
                setAudienceOptions(audienceOptions);
                setAudience(audienceOptions[0]);
            });
    }

    function deleteJob(id) {
        showLoading();
        del("/api/tiktok/auto-upload?jobId=" + id)
            .then(async response => {
                if (response.status === 200) {
                    setSuccessMessage(`Autoupdate job with id=${id} successfully deleted`)
                    showNotification("tl");
                } else {
                    setErrorMessage(response.body);
                    showNotification("tr");
                }
                hideLoading()
            })
            .catch(error => {
                setErrorMessage(error.toString());
                showNotification("tr");
            })
            .finally(() => {
                hideLoading()
                loadTableData(appId.value)
            });
    }

    const formInvalid = () => {
        let formValid = !!appId && !!audience && !!query;

        return !formValid
    }

    return (
        <div>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Card>
                <CardContent>
                    <form className={classes.form} noValidate onSubmit={handleSubmit(createAutoUploadJob)}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    value={appId}
                                    onChange={(event, newValue) => {
                                        setAppId(newValue);
                                        loadAudiencesByAppId(newValue.value)
                                        loadTableData(newValue.value)
                                    }}
                                    options={appOptions}
                                    getOptionLabel={(option) => option.label || ''}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="App"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={8}>
                                <Autocomplete
                                    fullWidth
                                    value={audience}
                                    onChange={(event, newValue) => {
                                        setAudience(newValue);
                                    }}
                                    options={audienceOptions}
                                    getOptionLabel={(option) => option.label || ''}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Audience"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    value={query}
                                    onChange={(event, newValue) => {
                                        setQuery(newValue);
                                    }}
                                    options={queryOptions}
                                    getOptionLabel={(option) => option.label || ''}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Query"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                                <Button type={"submit"} disabled={formInvalid()} variant="contained" color="primary">Create
                                    job</Button>
                                <Snackbar
                                    place="tl"
                                    color="success"
                                    icon={AddAlert}
                                    message={successMessage}
                                    open={tl}
                                    closeNotification={() => setTL(false)}
                                    close
                                />
                                <Snackbar
                                    place="tr"
                                    color="error"
                                    icon={AddAlert}
                                    message={errorMessage}
                                    open={tr}
                                    closeNotification={() => setTR(false)}
                                    close
                                />
                            </GridItem>
                        </GridContainer>
                    </form>
                </CardContent>
            </Card>
            <div style={{color: 'red', 'text-align': 'left', 'margin-top': '1rem'}}>
                Описание аудиторий из поля Query:
                <ul>
                    <li>Active Users - выгружаются IDFA и GAID активных за вчерашний день пользователей</li>
                </ul>
            </div>
            {tableData.length === 0
                ? <div>No jobs to display</div>
                : <MaterialTable style={{'margin-top': '1.5rem'}}
                                 icons={tableIcons}
                                 columns={[
                                     {title: "Advertiser ID", field: "advertiserId"},
                                     {title: "Audience", field: "audienceName"},
                                     {title: "Query", field: "queryName"},
                                     {title: "Last executed", field: "lastExecuted"},
                                     {
                                         title: "Status",
                                         field: "status"
                                     },
                                     {
                                         title: "Action",
                                         render: rowData =>
                                             <div>
                                                 <IconButton color="secondary" onClick={() => deleteJob(rowData.id)}>
                                                     <DeleteForeverIcon/>
                                                 </IconButton>
                                             </div>
                                     },
                                 ]}
                                 data={tableData}
                                 title="Current jobs"
                />
            }
        </div>
    );

}
