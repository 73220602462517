import React from "react";
import ReportFilters from "./ReportFilters";
import {Button} from "reactstrap";
import {withSnackbar} from "notistack";
import {ReportUtil} from "./ReportUtils";
import ReportGrid from "./ReportGrid";
import {UserApi} from "../../page/UserApi";

class Report extends React.Component {

    constructor(props) {
        super(props);
        this.profileKey = props.profileKey

        this.state = {
            filters: null
        }
        this.reportGrid = React.createRef();
        this.loadData = this.loadData.bind(this);
        this.onExportClick = this.onExportClick.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.handleProfileSave = this.handleProfileSave.bind(this)
        this.handleProfileCancel = this.handleProfileCancel.bind(this)
        this.handleProfileRefresh = this.handleProfileRefresh.bind(this)
        this.handleProfileChoose = this.handleProfileChoose.bind(this)
        this.resetGrid = this.resetGrid.bind(this)
        this.onGridReady = this.onGridReady.bind(this)
        this.updateGridState = this.updateGridState.bind(this)
    }

    loadData(request) {
        request.filterModel = ReportUtil.buildFilterModel(this.state.filters, this.props.filterDefs);
        request.aggregateFilterModel = ReportUtil.buildAggregateFilterModel(this.state.filters, this.props.filterDefs)
        request.weekStart = this.state.filters['week_start'] ? this.state.filters['week_start']['value'] : 0;

        return this.props.loadFunc(request)
    }

    onSearchClick(filters) {
        this.setState({filters})
        if (this.reportGrid.current) this.reportGrid.current.triggerFilterChanged()
    }

    onExportClick(filters) {
        this.setState({filters})
        let request = this.reportGrid.current.makeRequestForExport();
        request.filterModel = ReportUtil.buildFilterModel(filters, this.props.filterDefs);
        this.reportGrid.current.showLoadingOverlay()
        this.props.exportFunc(request)
            .then(_response => this.reportGrid.current.hideLoadingOverlay())
            .catch(response => this.reportGrid.current.showExportError(response));
    }

    handleProfileSave(profileToSave) {
        profileToSave = {
            ...profileToSave,
            grouping: this.reportGrid.current.gridColumnApi.getRowGroupColumns().map(col => col.colId),
            columns: this.reportGrid.current.gridColumnApi.getAllDisplayedColumns().map(col => col.colId),
            pinnedLeftColumns: this.reportGrid.current.gridColumnApi.getDisplayedLeftColumns().map(col => col.colId),
            pinnedRightColumns: this.reportGrid.current.gridColumnApi.getDisplayedRightColumns().map(col => col.colId)
        }

        return UserApi.saveProfile(profileToSave).then(resp => resp.body)
    }

    handleProfileCancel(filters) {
        this.setState({filters: filters}, this.resetGrid)
    }

    handleProfileRefresh(selectedProfile) {
        let profile = {
            ...selectedProfile,
            grouping: this.reportGrid.current.gridColumnApi.getRowGroupColumns().map(col => col.colId),
            columns: this.reportGrid.current.gridColumnApi.getAllDisplayedColumns().map(col => col.colId),
            pinnedLeftColumns: this.reportGrid.current.gridColumnApi.getDisplayedLeftColumns().map(col => col.colId),
            pinnedRightColumns: this.reportGrid.current.gridColumnApi.getDisplayedRightColumns().map(col => col.colId)
        }

        return UserApi.updateProfile(profile).then(resp => resp.body)
    }

    resetGrid() {
        this.reportGrid.current.gridColumnApi.setRowGroupColumns(this.props.gridDefaultGroupings);

        const allColumns = [this.reportGrid.current.gridColumnApi.getColumn('ag-Grid-AutoColumn'), ...this.reportGrid.current.gridColumnApi.getAllColumns()];

        for (let column of allColumns) {
            this.reportGrid.current.gridColumnApi.setColumnPinned(column.colId, false)

            const initialColumnDefIndex = this.props.gridColumnDefs.findIndex(c => c.colId === column.colId);
            const initialColumnDef = this.props.gridColumnDefs[initialColumnDefIndex];
            if (initialColumnDef) {
                this.reportGrid.current.gridColumnApi.setColumnVisible(column.colId, !initialColumnDef.hide)
                this.reportGrid.current.gridColumnApi.moveColumn(column, initialColumnDefIndex + 1);
            }
        }
    }

    handleProfileChoose(filters, columns, grouping, pinnedLeftColumns, pinnedRightColumns) {
        this.setState({filters})
        this.updateGridState(columns, grouping, pinnedLeftColumns, pinnedRightColumns)
    }

    onGridReady() {
        const localStorageProfile = ReportUtil.getProfileFromLocalStorage(this.profileKey)
        if (localStorageProfile) {
            this.updateGridState(localStorageProfile.columns, localStorageProfile.grouping, localStorageProfile.pinnedLeftColumns, localStorageProfile.pinnedRightColumns)
        }
    }

    updateGridState(columns, grouping, pinnedLeftColumns, pinnedRightColumns) {
        this.reportGrid.current.gridColumnApi.setRowGroupColumns(grouping);

        // display columns and pin/unpin
        let allColumns = [this.reportGrid.current.gridColumnApi.getColumn('ag-Grid-AutoColumn'), ...this.reportGrid.current.gridColumnApi.getAllColumns()];
        for (const column of allColumns) {
            this.reportGrid.current.gridColumnApi.setColumnVisible(column.colId, columns.includes(column.colId))

            if (pinnedLeftColumns.includes(column.colId)) {
                this.reportGrid.current.gridColumnApi.setColumnPinned(column.colId, 'left')
            } else if (pinnedRightColumns.includes(column.colId)) {
                this.reportGrid.current.gridColumnApi.setColumnPinned(column.colId, 'right')
            } else {
                this.reportGrid.current.gridColumnApi.setColumnPinned(column.colId, false)
            }
        }

        // move all displayed columns to the beginning so that they will be ordered correctly
        this.reportGrid.current.gridColumnApi.moveColumns(columns, 1);
    }

    showError(message) {
        const action = key => (
            <React.Fragment>
                <Button color="danger" onClick={() => this.props.closeSnackbar(key)}>'Close'</Button>
            </React.Fragment>
        );
        this.props.enqueueSnackbar(message, {variant: 'error', autoHideDuration: 10000, action});
    }

    render() {
        return (
            <div>
                <div className="text-center">
                    <div className="children">
                        <ReportFilters filterDefs={this.props.filterDefs}
                                       onSearchClick={this.onSearchClick}
                                       onExportClick={this.onExportClick}
                                       handleProfileSave={this.handleProfileSave}
                                       handleProfileCancel={this.handleProfileCancel}
                                       handleProfileRefresh={this.handleProfileRefresh}
                                       handleProfileChoose={this.handleProfileChoose}
                                       profileKey={this.profileKey}/>

                        {!!this.state.filters &&
                        <ReportGrid ref={this.reportGrid}
                                    columnDefs={this.props.gridColumnDefs}
                                    defaultGroupings={this.props.gridDefaultGroupings}
                                    defaultSortingModel={this.props.gridDefaultSortingModel}
                                    activeCampaigns={this.props.gridActiveCampaigns}
                                    loadRowsFunctions={this.loadData}
                                    gridReady={this.onGridReady}
                                    cellRendererParams={{
                                        startDate: this.state.filters.install_date.startDate,
                                        endDate: this.state.filters.install_date.endDate
                                    }}>
                        </ReportGrid>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default withSnackbar(Report);
