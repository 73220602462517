import React, {useEffect} from "react";
import {HotColumn, HotTable} from '@handsontable/react';
import {Backdrop, Button, Card, CardContent, CircularProgress, FormControl, Grid, TextField} from '@material-ui/core';
import moment from 'moment'
import {makeStyles} from "@material-ui/core/styles";
import {Autocomplete} from '@material-ui/lab';
import {APPS_OPTIONS, TEST_RESULT_OPTIONS} from "./AbTest";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {get, post} from "../../common/ApiUtils";
import {LinkRenderer} from "./ActiveTests";
import {CreativeNameRenderer} from "./AbTestUtils";

const useStyles = makeStyles((_theme) => ({
    root: {
        'margin-top': '1rem',
    },
    backdrop: {
        zIndex: _theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function TotalTestResult(props) {

    const classes = useStyles();
    const [app, setApp] = React.useState(null);
    const [startDate, setStartDate] = React.useState(moment().startOf('week').subtract(4, 'week'));
    const [endDate, setEndDate] = React.useState(moment().endOf('day'));
    const [creative, setCreative] = React.useState();
    const [campaignName, setCampaignName] = React.useState(null);
    const [geo, setGeo] = React.useState(null);
    const [fanTestResult, setFanTestResult] = React.useState();
    const [feedTestResult, setFeedTestResult] = React.useState();
    const [fanBenchCreative, setFanBenchCreative] = React.useState(null);
    const [feedBenchCreative, setFeedBenchCreative] = React.useState(null);
    const [allTests, setAllTests] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [CREATIVES_OPTIONS, setCreativesOptions] = React.useState([]);

    // eslint-disable-next-line
    useEffect(() => {
        loadCreativesOptions(app);
        loadAllTests();
    }, []);

    const loadCreativesOptions = (app) => {
        get('/api/facebook-ab-test/creative-names-by-app' + (app ? `?app=${app}` : '')).then(resp => setCreativesOptions(resp.body))
    }

    const loadAllTests = () => {
        const body = {
            status: 'APPROVED',
            app, startDate, endDate, creative, geo, fanTestResult, feedTestResult, fanBenchCreative, feedBenchCreative,
            campaignName: !!campaignName ? campaignName : null
        }

        setLoading(true);
        post('/api/facebook-ab-test/grid', body)
            .then(response => setAllTests(response.body))
            .finally((_) => {
                setLoading(false)
            })
    }

    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Card>
                <CardContent>
                    <h5>Filters</h5>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <Autocomplete fullWidth options={Object.keys(APPS_OPTIONS)}
                                          value={app}
                                          getOptionLabel={(option) => APPS_OPTIONS[option]}
                                          onChange={(event, option) => {
                                              setApp(option)
                                              setCreative(null)
                                              setFanBenchCreative(null)
                                              setFeedBenchCreative(null)
                                              loadCreativesOptions(option)
                                          }}
                                          disableClearable
                                          renderInput={(params) => <TextField {...params} required
                                                                              label="Title name"/>}/>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                                <DateRangePicker
                                    onApply={(event, picker) => {
                                        setStartDate(picker.startDate)
                                        setEndDate(picker.endDate)
                                    }}
                                    initialSettings={{
                                        startDate: startDate,
                                        endDate: endDate,
                                    }}
                                    alwaysShowCalendars={true}>
                                    <div className="form-group">
                                        <TextField id="standard-basic" style={{width: "100%"}} readOnly
                                                   label="Date range"
                                                   value={startDate.format('MM/DD/YYYY') + ' - ' + endDate.format('MM/DD/YYYY')}/>
                                    </div>
                                </DateRangePicker>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Autocomplete fullWidth options={CREATIVES_OPTIONS}
                                          value={creative}
                                          onChange={(event, newValue) => setCreative(newValue)}
                                          renderInput={(params) => <TextField {...params}
                                                                              label="Creative"/>}/>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField fullWidth label="Campaign name" с
                                       value={campaignName}
                                       onChange={(event) => setCampaignName(event.target.value)}/>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Autocomplete fullWidth options={TEST_RESULT_OPTIONS}
                                          value={fanTestResult}
                                          onChange={(event, newValue) => setFanTestResult(newValue)}
                                          renderInput={(params) => <TextField {...params}
                                                                              label="FAN Test Result"/>}/>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Autocomplete fullWidth options={TEST_RESULT_OPTIONS}
                                          value={feedTestResult}
                                          onChange={(event, newValue) => setFeedTestResult(newValue)}
                                          renderInput={(params) => <TextField {...params}
                                                                              label="FEED Test Result"/>}/>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Autocomplete fullWidth options={CREATIVES_OPTIONS}
                                          value={fanBenchCreative}
                                          onChange={(event, newValue) => setFanBenchCreative(newValue)}
                                          renderInput={(params) => <TextField {...params}
                                                                              label="FAN Bench creative"/>}/>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Autocomplete fullWidth options={CREATIVES_OPTIONS}
                                          value={feedBenchCreative}
                                          onChange={(event, newValue) => setFeedBenchCreative(newValue)}
                                          renderInput={(params) => <TextField {...params}
                                                                              label="Feed Bench creative"/>}/>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Autocomplete fullWidth options={props.countryOptions}
                                          getOptionDisabled={(option) => option === '--'}
                                          value={geo}
                                          onChange={(event, newValue) => setGeo(newValue)}
                                          renderInput={(params) => <TextField {...params}
                                                                              label="GEO"/>}/>
                        </Grid>
                    </Grid>
                    <div style={{'margin-top': '1rem'}}>
                        <Button variant="contained" color="primary" onClick={() => loadAllTests()}>Find</Button>
                    </div>
                </CardContent>
            </Card>


            {allTests.map((test) =>
                <Card className={classes.root}>
                    <CardContent>
                        <h5>Test
                            #{test.testId}: {new Date(test.testStartDate).toLocaleDateString()}</h5>

                        <HotTable
                            data={test.campaigns}
                            nestedRows={true}
                            fixedColumnsLeft={2}
                            height="auto"
                            style={{textAlign: 'left'}}
                            licenseKey="non-commercial-and-evaluation">

                            <HotColumn title="Campaign name" data="campaignName" settings={{readOnly: true}}/>
                            <HotColumn title="Creative name" data="creativeName" width={180}
                                       settings={{readOnly: true}}>
                                <CreativeNameRenderer hot-renderer/>
                            </HotColumn>
                            <HotColumn title="FAN Test Result" data="fanTestResult" settings={{readOnly: true}}/>
                            <HotColumn title="Feed Test Result" data="feedTestResult" settings={{readOnly: true}}/>
                            <HotColumn title="CTR" data="ctr" settings={{readOnly: true, type: 'numeric'}}/>
                            <HotColumn title="IR" data="ir" settings={{readOnly: true, type: 'numeric'}}/>
                            <HotColumn title="IPM" data="ipm" settings={{readOnly: true, type: 'numeric'}}/>
                            <HotColumn title="CPI" data="cpi" settings={{readOnly: true, type: 'numeric'}}/>
                            <HotColumn title="CRetU1d" data="cretU1d" settings={{readOnly: true, type: 'numeric'}}/>
                            <HotColumn title="CPM" data="cpm" settings={{readOnly: true, type: 'numeric'}}/>
                            <HotColumn title="RPM 1d" data="rpm1d" settings={{readOnly: true, type: 'numeric'}}/>
                            <HotColumn title="RET 1d" data="ret1d" settings={{readOnly: true, type: 'numeric'}}/>
                            <HotColumn title="Asana Link" data="asanaLink" settings={{readOnly: true}}>
                                <LinkRenderer hot-renderer/>
                            </HotColumn>
                            <HotColumn title="GDrive link" data="driveLink" settings={{readOnly: true}}>
                                <LinkRenderer hot-renderer/>
                            </HotColumn>
                            <HotColumn title="Note" width={300} data="note"/>
                        </HotTable>
                    </CardContent>
                </Card>
            )}
        </React.Fragment>
    );
}