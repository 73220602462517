import {
    AD,
    ADJUST_SITEID,
    ADSET,
    CAMPAIGN,
    CLICKS,
    COUNTRY,
    CPA,
    CPA14D,
    CPA1D,
    CPA3D,
    CPA7D,
    CPI,
    CPM,
    CPP,
    CPP14D,
    CPP1D,
    CPP3D,
    CPP7D,
    DATE,
    DAU14D,
    DAU1D,
    DAU30D,
    DAU3D,
    DAU7D,
    DEVICE_TYPE,
    EXCHANGE,
    FCST360,
    GROSS_REVENUE_TOTAL,
    GROSS_REVENUE0D,
    GROSS_REVENUE_TOTAL_14D,
    GROSS_REVENUE_TOTAL_1D,
    GROSS_REVENUE_TOTAL_30D,
    GROSS_REVENUE_TOTAL_7D,
    GROSS_ROAS0D,
    GROSS_ROAS14D,
    GROSS_ROAS1D,
    GROSS_ROAS30D,
    GROSS_ROAS3D,
    GROSS_ROAS7D,
    IMPRESSIONS,
    INST14D,
    INST1D,
    INST30D,
    INST3D,
    INST7D,
    INSTALLS,
    IPM,
    LTV360,
    LTVPPU,
    MEDIA_SOURCE,
    NET_REVENUE_TOTAL,
    NET_REVENUE_TOTAL_14D,
    NET_REVENUE_TOTAL_1D,
    NET_REVENUE_TOTAL_30D,
    NET_REVENUE_TOTAL_3D,
    NET_REVENUE_TOTAL_7D,
    PAYERS,
    PAYERS1,
    PAYERS14,
    PAYERS3,
    PAYERS3_PERCENT,
    PAYERS7,
    PAYERS7_PERCENT,
    PAYERS_PERCENT,
    PAYMENTS,
    PAYMENTS1,
    PAYMENTS14,
    PAYMENTS3,
    PAYMENTS7,
    PLATFORM,
    PRFCST,
    RET14D,
    RET1D,
    RET30D,
    RET3D,
    RET7D,
    NET_REVENUE_TOTAL_0D,
    ROAS,
    ROAS0D_FACT,
    ROAS14D_FACT,
    ROAS1D_FACT,
    ROAS30D_FACT,
    ROAS3D_FACT,
    ROAS7D_FACT,
    SPEND,
    TITLE,
    TRAFFIC_TYPE,
    WEEK
} from "../common/ColumnDefs";
import {FilterDef} from "./common/ReportFiltersDef";

export const DEVICE_REPORT_COLUMNS = [
    WEEK, TITLE, PLATFORM, TRAFFIC_TYPE, DEVICE_TYPE, DATE, MEDIA_SOURCE, CAMPAIGN, ADSET, COUNTRY,
    ADJUST_SITEID, AD, EXCHANGE, INSTALLS, ROAS, LTVPPU, PAYERS_PERCENT, CPA3D, CPI, LTV360, SPEND, IPM,
    CPM, PRFCST, ROAS0D_FACT, ROAS1D_FACT, ROAS3D_FACT, ROAS7D_FACT, RET1D, RET3D, RET7D, GROSS_ROAS7D,
    IMPRESSIONS, CLICKS, PAYERS, PAYERS3, PAYERS3_PERCENT, PAYERS7, PAYERS7_PERCENT,
    GROSS_ROAS0D, GROSS_REVENUE_TOTAL, GROSS_REVENUE0D, GROSS_REVENUE_TOTAL_1D, GROSS_REVENUE_TOTAL_7D, GROSS_REVENUE_TOTAL_14D, GROSS_REVENUE_TOTAL_30D,
    NET_REVENUE_TOTAL, NET_REVENUE_TOTAL_1D, NET_REVENUE_TOTAL_3D, NET_REVENUE_TOTAL_7D, NET_REVENUE_TOTAL_14D, NET_REVENUE_TOTAL_30D,
    GROSS_ROAS1D, GROSS_ROAS3D, DAU1D,
    DAU3D, DAU7D, DAU14D, DAU30D, GROSS_ROAS7D,
    GROSS_ROAS14D, RET14D, ROAS14D_FACT, GROSS_ROAS30D, ROAS30D_FACT, RET30D, FCST360,
    INST1D, INST3D, INST7D, INST14D, INST30D, CPA, CPA1D, CPA7D, CPA14D, CPP, CPP1D, CPP3D, CPP7D,
    CPP14D, PAYERS1, PAYERS14, PAYMENTS, PAYMENTS1, PAYMENTS3, PAYMENTS7, PAYMENTS14, NET_REVENUE_TOTAL_0D,
]

export const DEVICE_REPORT_DEFAULT_GROUPINGS = [
    TRAFFIC_TYPE.colId, TITLE.colId, PLATFORM.colId, MEDIA_SOURCE.colId, COUNTRY.colId, DEVICE_TYPE.colId
];

export const DEVICE_REPORT_DEFAULT_SORTING_MODEL = [
    {colId: WEEK.colId, sort: "asc"},
    {colId: DATE.colId, sort: "asc"},
    {colId: SPEND.colId, sort: "desc"}
]


export const DEVICE_REPORT_FILTERS_CONFIG = [
    FilterDef.TRAFFIC_TYPE,
    FilterDef.TITLE,
    FilterDef.PLATFORM,
    FilterDef.MEDIA_SOURCE,
    FilterDef.COUNTRY,
    FilterDef.CAMPAIGN,
    FilterDef.INSTALL_DATE,
]
