import {
    CAMPAIGN,
    COUNTRY,
    CPI,
    DATE,
    DAU1D,
    DAU3D,
    DAU7D,
    FCST360,
    FULL_APP_NAME,
    INST1D,
    INST3D,
    INST7D,
    INSTALLS,
    LTV360,
    MEDIA_SOURCE,
    PAYERS,
    PAYERS1,
    PAYERS1_PERCENT,
    PAYERS3,
    PAYERS3_PERCENT,
    PAYERS7,
    PAYERS7_PERCENT,
    PLATFORM,
    RET1D,
    RET3D,
    RET7D,
    ROAS,
    SPEND,
    TITLE,
    WEEK
} from "../common/ColumnDefs";
import {show} from "../common/Utils";
import {FilterDef} from "./common/ReportFiltersDef";

export const EVENT_REPORT_COLUMNS = [
    WEEK, TITLE, FULL_APP_NAME, PLATFORM, DATE, MEDIA_SOURCE, CAMPAIGN, COUNTRY, PAYERS1_PERCENT, PAYERS1, PAYERS3,
    PAYERS7, DAU1D, DAU3D, DAU7D, INST1D, INST3D, INST7D, FCST360, INSTALLS, SPEND, ROAS, CPI, LTV360,
    show(PAYERS), show(PAYERS3_PERCENT), show(PAYERS7_PERCENT), show(RET1D), show(RET3D), show(RET7D),
]

export const EVENT_REPORT_DEFAULT_GROUPINGS = ["full_app_name", "platform", "week_start", "media_source", "campaign", "install_date", "country_code"];

export const EVENT_REPORT_DEFAULT_SORTING_MODEL = [
    {colId: "week_start", sort: "asc"},
    {colId: "install_date", sort: "asc"},
    {colId: "spend", sort: "desc"}
]

export const EVENT_REPORT_FILTERS_CONFIG = [
    FilterDef.TITLE,
    FilterDef.APP,
    FilterDef.PLATFORM,
    FilterDef.MEDIA_SOURCE,
    FilterDef.CAMPAIGN,
    FilterDef.COUNTRY,
    FilterDef.INSTALL_DATE,
]
