import React from "react";


export default function Description(props) {
    return (
        <div style={{color: 'red', 'margin-top': '1rem', 'text-align': 'left'}}>
            <p>
                Загрузка аудитории может занять несколько минут. Не закрывайте вкладку, чтобы убедиться, что аудитория
                загружена успешно.
            </p>
            Описание аудиторий из поля Query:

            <ul>
                <li>Supressions by Sessions - выгружаются IDFA и GAID пользователей, у которых была хотя бы одна
                    сессия за все время</li>
                <li>Active Users - выгружаются IDFA и GAID активных за вчерашний день пользователей</li>
            </ul>
        </div>
    );
}
