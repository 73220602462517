import React, {Component} from 'react';
import {Spinner} from "reactstrap";

export default class CustomLoadingOverlay extends Component {
    render() {
        return (
            <div className="ag-overlay-loading-center" style={{backgroundColor: 'lightsteelblue', height: '9%'}}>
                <Spinner style={{ width: '2rem', height: '2rem' }} />
                <span style={{verticalAlign: "middle", display: "table-cell", fontSize:"2rem"}}>&nbsp;{this.props.loadingMessage}</span>
            </div>
        );
    }
}