import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import Card from "../../../components/Card/Card";
import {getRows} from "../../../common/CreativeTestFunctions";
import MaterialDateRange from "../../../components/DateRangeSelect/MaterialDateRange";
import "../../../index.css";
import CalculationsTable from "./CalculationsTable";
import TopCalculationsTable from "./TopCalculationsTable";
import 'handsontable/dist/handsontable.full.css';
import {HotTable} from '@handsontable/react';

import {baseMetricRenderer, calcMetricRenderer, resultRenderer} from "./HandsonTableUtils";

const styles = {
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    },
    inputCountry: {
        fontSize: "10px",
        width: "40px"
    },
    inputAdname: {
        fontSize: "10px",
        width: "170px"
    },
    inputSpends: {
        fontSize: "10px",
        width: "40px"
    },
    inputImpressions: {
        fontSize: "10px",
        width: "80px"
    },
    inputClicks: {
        fontSize: "10px",
        width: "40px"
    },
    inputInstalls: {
        fontSize: "10px",
        width: "40px"
    }
};

const useStyles = makeStyles(styles);


export default function ABTestExcelTemplate(props) {
    const classes = useStyles();

    const columns = [
        {data: "country"},
        {
            data: "adName",
            type: 'autocomplete',
            strict: false,
            source: props.creativeNames,
            visibleRows: 5,
            width: 200
        },
        {
            data: "spend",
            type: 'numeric',
            numericFormat: {
                pattern: '$0.00',
                culture: 'en-US' // this is the default culture, set up for USD
            },
            renderer: baseMetricRenderer
        },
        {
            data: "impressions",
            renderer: baseMetricRenderer
        },
        {
            data: "clicks",
            renderer: baseMetricRenderer
        },
        {
            data: "installs",
            renderer: baseMetricRenderer
        },
        {
            data: "cpi",
            type: 'numeric',
            numericFormat: {
                pattern: '$0.00',
                culture: 'en-US'
            },
            renderer: calcMetricRenderer,
            editor: false
        },
        {
            data: "ctr",
            type: 'numeric',
            numericFormat: {
                pattern: '0.00%'
            },
            renderer: calcMetricRenderer,
            editor: false
        },
        {
            data: "ir",
            type: 'numeric',
            numericFormat: {
                pattern: '0.00%'
            },
            renderer: calcMetricRenderer,
            editor: false
        },
        {
            data: "cvr",
            type: 'numeric',
            numericFormat: {
                pattern: '0.00%'
            },
            renderer: calcMetricRenderer,
            editor: false
        },
        {
            data: "cpm",
            type: 'numeric',
            numericFormat: {
                pattern: '$0.00',
                culture: 'en-US'
            },
            renderer: calcMetricRenderer,
            editor: false
        },
        {data: "result", renderer: resultRenderer, width: 70},
    ];


    const render = () => {
        return (
            <Card>
                <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>{props.abTest.network}</h4>
                    <p className={classes.cardCategoryWhite}>
                        {/*Here is a subtitle for this table*/}
                    </p>

                </CardHeader>
                <CardBody>
                    Даты теста:&nbsp;<MaterialDateRange style={{fontSize: "10px", width: "200px"}} name=""
                                                        onDatePickerChange={props.onDateChange(props.abTest.network)}
                                                        startDate={props.abTest.startDate}
                                                        endDate={props.abTest.endDate}/>
                    <div id="hot-app">
                        <HotTable
                            data={getRows(props.abTest)}
                            rowHeaders={true}
                            mergeCells={[
                                {row: 0, col: 11, rowspan: 2, colspan: 1}
                            ]}
                            columns={columns}
                            colHeaders={['Country', 'Ad Name', 'Spend', 'Impressions', 'Clicks', 'Installs', 'CPI', 'CTR', 'IR', 'CVR', 'CPM', 'Result']}
                            height="150"
                            afterChange={(changes, source) => {
                                let events = ["CopyPaste.paste", "edit"]
                                if (events.includes(source)) {
                                    props.onChange(props.abTest.network, changes);
                                }
                            }
                            }
                            licenseKey="non-commercial-and-evaluation"/>
                    </div>
                    {props.showCalc ? <TopCalculationsTable abTest={props.abTest}/> : null}
                    {props.showCalc ? <CalculationsTable abTest={props.abTest}/> : null}
                </CardBody>
            </Card>
        );
    }

    return (
        <div>
            {render()}
        </div>
    );
}