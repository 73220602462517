import React, {Component} from 'react';
import './App.css';
import {Link, Redirect, Route, Switch} from 'react-router-dom';
import {withCookies} from 'react-cookie';
import Logo from "../assets/logo.png";
import CreativeReport from "../report/CreativeReport";
import DeviceReport from "../report/DeviceReport";
import Admin from "../layouts/Admin";
import AsoReport from "../report/AsoReport";
import RetargetingReport from "../report/RetargetingReport";
import UamSkanOnlyReport from "../report/UamSkanOnlyReport";
import EventReport from "../report/EventReport";
import UamReport from "../report/UamReport";
import {get} from "../common/ApiUtils";
import WebReport from "../report/WebReport";

class App extends Component {

    constructor(props) {
        super(props);
        const {cookies} = props;
        this.state = {
            isLoading: false,
            isAuthenticated: false,
            user: {},
            buttonClicked: false,
            csrfToken: cookies.get('XSRF-TOKEN'),
            rowData: []
        };
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
    }

    async componentDidMount() {
        this.setState(({isAuthenticated: false}))
        get('/api/user', {credentials: 'include'})
            .then(response => this.setState({isAuthenticated: true, user: response.body}))
            .catch(error => console.log(error));
    }


    login() {
        let port = (window.location.port ? ':' + window.location.port : '');
        if (port === ':3000') {
            port = ':8080';
            // port = ':8088';
        }
        window.location.href = '//' + window.location.hostname + port + '/oauth2/authorization/google';
    }


    logout() {
        fetch('/api/logout', {
            method: 'POST', credentials: 'include',
            headers: {'X-XSRF-TOKEN': this.state.csrfToken}
        }).then(res => res.json())
            .then(response => {
                window.location.href = response.logoutUrl + "?id_token_hint=" +
                    response.idToken + "&post_logout_redirect_uri=" + window.location.origin;
            });
    }

    getUserRoutes() {
        let result = [];
        if (this.state.user.authorities) {

            this.state.user.authorities.forEach(e => {

                switch (e) {
                    case "READ_UAM_REPORT":
                        result.push(<Route key="uam" path="/uam" render={(props) => <UamReport {...props}
                                                                                               authorities={this.state.user.authorities}/>}/>);
                        result.push(<Route key="uamSkanOnly" path="/uamSkanOnly"
                                           render={(props) => <UamSkanOnlyReport {...props}
                                                                                 authorities={this.state.user.authorities}/>}/>);
                        result.push(<Route key="web" path="/web" render={(props) => <WebReport {...props}
                                                                                               authorities={this.state.user.authorities}/>}/>);
                        result.push(<Route key="event" path="/event" render={(props) => <EventReport {...props}
                                                                                                     authorities={this.state.user.authorities}/>}/>);
                        break;
                    case "READ_DEVICE_REPORT":
                        result.push(<Route key="device" path="/device" render={(props) => <DeviceReport {...props}
                                                                                                        authorities={this.state.user.authorities}/>}/>);
                        break;
                    case "READ_RETARGETING_REPORT":
                        result.push(<Route key="retargeting" path="/retargeting"
                                           render={(props) => <RetargetingReport {...props}
                                                                                 authorities={this.state.user.authorities}/>}/>);
                        break;
                    case "READ_CREATIVE_REPORT":
                        result.push(<Route key="creative" path="/creative" render={(props) => <CreativeReport {...props}
                                                                                                              authorities={this.state.user.authorities}/>}/>);
                        break;
                    case "READ_ASO_REPORT":
                        result.push(<Route key="aso" path="/aso" render={(props) => <AsoReport {...props}
                                                                                               authorities={this.state.user.authorities}/>}/>);
                        break;
                    default:
                }
            })
        }
        result.push(<Route key="admin" path="/admin" component={Admin}/>);
        result.push(<Route key="tool" path="/tool"
                           render={(props) => <Admin {...props} authorities={this.state.user.authorities}/>}/>);
        result.push(<Route key="report" path="/report" component={Admin}/>);
        let defaultUrl = this.state.user.authorities.includes("READ_UAM_REPORT") ? "/uam" : "/tool"
        result.push(<Redirect key="defaultUrl" from="/" to={defaultUrl}/>);
        return result;
    }

    render() {
        if (this.state.isAuthenticated) {
            const routes = this.getUserRoutes();
            return (
                <div className="App">
                    <div>
                        <div className="children">
                            <Switch>
                                {routes}
                            </Switch>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div>
                <img src={Logo} alt="" className="logo-content"/>
                <form className="align-items-center">

                    <div className="login-container">
                        <div className="login-content">
                            <h1 className="h3 mb-3 font-weight-normal">Belka marketing dashboard</h1>
                            <div className="or-separator">
                                <span className="or-text"/>
                            </div>
                            <div className="social-login pb-5">
                                <Link className="btn btn-primary" onClick={this.login} to="/"> Log in with Google</Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );

    }

}

export default withCookies(App);
