import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import {UserApi} from "../../page/UserApi";
import MultiSelect from "../../components/CustomMultiSelect/MultiSelect";
import {CatalogApi} from "../../api/CatalogApi";

class ProfileShareModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loadFlag: false,
            selectedUsers: [],
            userOptions: []
        }
        this.shareProfile = this.shareProfile.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.toggleLoadFlag = this.toggleLoadFlag.bind(this)
    }

    componentDidMount() {
        CatalogApi.getUsersForProfileShare()
            .then((userOptions) => this.setState((prevState) => ({...prevState, userOptions})))
    }

    toggleLoadFlag(load) {
        this.setState((prevState) => ({...prevState, loadFlag: load}))
    }

    shareProfile() {
        let userIds = this.state.selectedUsers.map(user => user.value);
        if (userIds.length > 0) {
            this.toggleLoadFlag(true)
            UserApi.shareProfile(this.props.profileToShare.id, userIds)
                .then(this.closeModal)
                .finally(() => this.toggleLoadFlag(true))
        }
    }

    closeModal() {
        this.props.closeModal()
    }

    render() {
        return (
            <Modal isOpen={true} toggle={this.closeModal} unmountOnClose={true}>
                <ModalHeader toggle={this.closeModal}>
                    Share "{this.props.profileToShare.name}" profile with users
                </ModalHeader>
                <ModalBody>
                    <MultiSelect
                        options={this.state.userOptions}
                        value={this.state.selectedUsers}
                        onChange={(options) => this.setState((prevState) => {
                            return {...prevState, selectedUsers: options};
                        })}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.loadFlag} color="primary" onClick={this.shareProfile}>
                        {this.state.loadFlag && <span><Spinner size="sm"> </Spinner>&nbsp;&nbsp;&nbsp;</span>}
                        Share
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ProfileShareModal;
