import React, {useEffect} from "react";
import {withRouter} from "react-router-dom";
import {withCookies} from "react-cookie";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FacebookIcon from '@material-ui/icons/Facebook';
import AddTest from "./AddTest";
import TotalTestResult from "./TotalTestResult";
import ActiveTests from "./ActiveTests";
import {CatalogApi} from "../../api/CatalogApi";

export const APPS_OPTIONS = {
    SC: 'Solitaire Cruise',
    BA: 'Bermuda Adventures',
    CM: 'Clockmaker',
    FE: 'Funky Bay',
}

export const TEST_RESULT_OPTIONS = [
    'bad', 'normal', 'good', 'best'
]

function AbTest() {

    document.title = 'Facebook A/B Tests';

    const [activeTabPanel, setActiveTabPanel] = React.useState(0);
    const [COUNTRY_OPTIONS, setCountryOptions] = React.useState([]);

    useEffect(() => {
        CatalogApi.getCountries()
            .then(resp => resp.body)
            .then(options => options.filter(option => option !== 'US' && option !== 'MX' && option !== 'BR'))
            .then(options => {
                options.unshift('--')
                options.unshift('BR')
                options.unshift('MX')
                options.unshift('US')
                return options
            })
            .then(options => setCountryOptions(options))
            .catch(error => console.log(error))
    }, [])

    return (
        <React.Fragment>
            <Tabs value={activeTabPanel} onChange={(event, newValue) => setActiveTabPanel(newValue)}
                  indicatorColor="primary"
                  textColor="primary">

                <Tab icon={<FacebookIcon/>} aria-label="phone" label="Add test"/>
                <Tab icon={<FacebookIcon/>} aria-label="phone" label="Active tests"/>
                <Tab icon={<FacebookIcon/>} aria-label="phone" label="Total test result"/>
            </Tabs>
            <TabPanel value={activeTabPanel} index={0}>
                <AddTest countryOptions={COUNTRY_OPTIONS}/>
            </TabPanel>
            <TabPanel value={activeTabPanel} index={1}>
                <ActiveTests countryOptions={COUNTRY_OPTIONS}/>
            </TabPanel>
            <TabPanel value={activeTabPanel} index={2}>
                <TotalTestResult countryOptions={COUNTRY_OPTIONS}/>
            </TabPanel>
        </React.Fragment>
    );
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div role="tabpanel"
             hidden={value !== index}
             id={`simple-tabpanel-${index}`}
             aria-labelledby={`simple-tab-${index}`}
             {...other}>

            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default withCookies(withRouter(AbTest));
