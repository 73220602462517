import React, {useEffect, useState} from "react";
import {withCookies} from "react-cookie";
import {withRouter} from "react-router-dom";
import MaterialTable from "material-table";
import tableIcons from "../assets/jss/material-dashboard-react/components/materialTableStyle";
import MuiAlert from '@material-ui/lab/Alert';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    Snackbar
} from '@material-ui/core';
import {Button} from "reactstrap";
import {UserApi} from "./UserApi";

function UserManagement() {

    document.title = 'User Management';
    const defaultState = {email: '', userRoleId: 1, apps: [], active: true};
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [formValues, setFormValues] = useState(defaultState);
    const [errorMessage, setErrorMessage] = useState(null);

    const APPS = [
        {value: 'com.samfinaco.paradise', text: 'Bermuda Adventures Android'},
        {value: 'id1558716835', text: 'Bermuda Adventures IOS'},
        {value: 'com.belkagames.rtts', text: 'Claim to Fame Android'},
        {value: 'com.belkatechnologies.clockmaker-amazon', text: 'Clockmaker Amazon'},
        {value: 'com.belkatechnologies.clockmaker', text: 'Clockmaker Android'},
        {value: 'cm_canvas', text: 'Clockmaker FB'},
        {value: 'com.belkatechnologies.clockmakerHu', text: 'Clockmaker HM'},
        {value: 'id929195587', text: 'Clockmaker IOS'},
        {value: '9P664ZD4R63X', text: 'Clockmaker WN'},
        {value: 'com.belkatechnologies.fe-Amazon', text: 'Funky Bay Amazon'},
        {value: 'com.belkatechnologies.fe', text: 'Funky Bay Android'},
        {value: 'id1200864579', text: 'Funky Bay IOS'},
        {value: 'com.belkagames.solitaire-amazon', text: 'Solitaire Cruise Amazon'},
        {value: 'com.belkagames.solitaire', text: 'Solitaire Cruise Android'},
        {value: 'id1500251668', text: 'Solitaire Cruise IOS'},
        {value: 'com.belkagames.solitaireweb', text: 'Solitaire Cruise Web'},
        {value: '9PD42D0HTR69', text: 'Solitaire Cruise WN'},
    ]

    useEffect(() => {
        setIsLoading(true);
        UserApi.getUsers()
            .then((response) => setData(response.body))
            .catch(error => console.log(error))
            .finally(() => setIsLoading(false))
    }, [])


    const save = (event) => {
        event.preventDefault()
        setIsLoading(true)

        UserApi.saveUser(formValues)
            .then((response) => {
                setData(response.body)
                setFormValues(defaultState)
            })
            .catch(error => {
                setErrorMessage(error.body.message)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const cancel = (event) => {
        event.preventDefault()
        setFormValues(defaultState);
    }

    const handleInputChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const name = e.target.name;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const selectRow = (event, rowData) => {
        setFormValues({
            userId: rowData['userId'],
            email: rowData['email'],
            userRoleId: rowData['userRoleId'],
            apps: rowData['apps'],
            active: rowData['active']
        });
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>

                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel id="email" shrink>Email</InputLabel>
                        <Input disabled={!!formValues.userId} id="email" name="email" disabled={isLoading}
                               value={formValues.email}
                               label="Email"
                               onChange={handleInputChange}
                               aria-describedby="email"/>
                    </FormControl>
                </Grid>

                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel id="userRoleId" shrink>Role</InputLabel>
                        <Select labelId="userRoleId" id="userRoleId" disabled={isLoading} name="userRoleId"
                                value={formValues.userRoleId}
                                onChange={handleInputChange}
                                label="Role">
                            <MenuItem key={1} value={'9117652959744626544'}>Marketing Producer</MenuItem>
                            <MenuItem key={2} value={'-1586790422529263431'}>Designer</MenuItem>
                            <MenuItem key={3} value={'7724744145410653042'}>Applovin Designer</MenuItem>
                            <MenuItem key={4} value={'5464634195882848154'}>Applovin Uam</MenuItem>
                            <MenuItem key={5} value={'-8352205731287951225'}>Game Producer</MenuItem>
                            <MenuItem key={6} value={'7151747610696485412'}>Admin</MenuItem>
                            <MenuItem key={7} value={'-3376210137574309547'}>Top Manager</MenuItem>
                            <MenuItem key={8} value={'-5240837589292706907'}>Aso Specialist</MenuItem>
                            <MenuItem key={9} value={'-4805167447319617044'}>Limited User</MenuItem>
                            <MenuItem key={10} value={'-6133776189199042448'}>UAM </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <InputLabel id="apps" shrink>Apps</InputLabel>
                        <Select labelId="apps" id="apps" name="apps" multiple disabled={isLoading} displayEmpty={true}
                                onChange={handleInputChange}
                                renderValue={(selected) => {
                                    return selected.length === 0
                                        ? 'All apps'
                                        : selected.length + ' apps selected'
                                }}
                                value={formValues.apps}>
                            {APPS.map((app) => (
                                <MenuItem key={app.value} value={app.value}>
                                    {app.text}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={2} fullWidth>
                    <FormControlLabel
                        control={<Checkbox name="active" checked={formValues.active} disabled={isLoading}
                                           onChange={handleInputChange}/>}
                        label="Active"/>
                </Grid>

                <Grid item xs={1}>
                    <Button color="success" onClick={save} disabled={isLoading}>Save</Button>
                </Grid>

                <Grid item xs={1}>
                    {formValues.userId && <Button color="danger" onClick={cancel}>Cancel</Button>}
                </Grid>

                <Grid item xs={12}>
                    <MaterialTable
                        icons={tableIcons}
                        columns={[
                            {
                                title: 'Email',
                                field: 'email',
                            },
                            {
                                title: 'Role',
                                field: 'userRoleId',
                                align: 'left',
                                type: 'numeric',
                                lookup: {
                                    '9117652959744626544': "Marketing Producer",
                                    '-1586790422529263431': "Designer",
                                    '7724744145410653042': "Applovin Designer",
                                    '5464634195882848154': "Applovin Uam",
                                    '-8352205731287951225': "Game Producer",
                                    '7151747610696485412': "Admin",
                                    '-3376210137574309547': "Top Manager",
                                    '-5240837589292706907': "Aso Specialist",
                                    '-4805167447319617044': "Limited User",
                                    '-6133776189199042448': "UAM"
                                }
                            },
                            {
                                title: 'App',
                                field: 'apps',
                                render: (rowData) => (rowData.apps.length === 0 ? 'All apps' : rowData.apps.length + ' apps')
                            },
                            {
                                title: 'Active', field: 'active', type: 'boolean'
                            }
                        ]}
                        data={data}
                        isLoading={isLoading}
                        onRowClick={selectRow}
                        options={
                            {pageSize: 5}
                        }
                        title="Users"
                        editable={{
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    UserApi.deleteUser(oldData.userId)
                                        .then((response) => {
                                            setData(response.body)
                                            resolve()
                                        })
                                        .catch(_error => reject())
                                        .finally(() => setIsLoading(false));
                                }),
                        }}
                    />
                </Grid>

            </Grid>
            <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={() => setErrorMessage(null)}>
                <MuiAlert severity="error">{errorMessage}</MuiAlert>
            </Snackbar>
        </React.Fragment>
    );
}

export default withCookies(withRouter(UserManagement));
