import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';

export default function AddCreativeDialog(props) {
    const [creative, setCreative] = React.useState(null);
    const [campaign, setCampaign] = React.useState(null);

    if (!props.testToModify) {
        return null;
    }

    const handleCloseWithoutSubmit = () => {
        props.handleClose(null)
        setCreative(null)
        setCampaign(null)
    }

    const handleCloseWithSubmit = () => {
        if (!!creative && !!campaign) {
            props.handleClose({testId: props.testToModify.testId, campaignId: campaign.value, creative})
            setCreative(null)
            setCampaign(null)
        }
    }

    return (
        <Dialog fullWidth={true}
                maxWidth="md"
                open={props.open}
                onClose={() => handleCloseWithoutSubmit()}
                aria-labelledby="responsive-dialog-title">

            <DialogTitle id="responsive-dialog-title">Add creative to test #{props.testToModify.testId}</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Autocomplete fullWidth
                                      options={props.testToModify.campaigns.map(data => ({
                                          title: data.campaignName,
                                          value: data.campaignId
                                      }))}
                                      getOptionLabel={(option) => option.title}
                                      value={campaign}
                                      onChange={(event, option) => setCampaign(option)}
                                      renderInput={(params) => <TextField {...params} label="Campaign"/>}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete fullWidth options={props.creativeOptions}
                                      value={creative}
                                      onChange={(event, newValue) => setCreative(newValue)}
                                      renderInput={(params) => <TextField {...params} label="Creative"/>}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => handleCloseWithoutSubmit()} color="primary">Cancel</Button>
                <Button onClick={() => handleCloseWithSubmit()} color="primary" autoFocus>Add</Button>
            </DialogActions>
        </Dialog>
    );
}