import React from 'react';
import '../app/App.css';
import {withRouter} from 'react-router-dom';
import {withCookies} from 'react-cookie';

import {CatalogApi} from "../api/CatalogApi";
import {ReportApi} from "../api/ReportApi";
import {
    RETARGETING_REPORT_COLUMNS,
    RETARGETING_REPORT_DEFAULT_GROUPINGS,
    RETARGETING_REPORT_DEFAULT_SORTING_MODEL,
    RETARGETING_REPORT_FILTERS_CONFIG
} from "./RetargetingReportConfig";
import Report from "./common/Report";
import AppNavbar from "../navigation/AppNavbar";


class RetargetingReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {activeCampaigns: []}
    }

    componentDidMount() {
        document.title = 'BI Retargeting';
        CatalogApi.getActiveCampaigns().then(activeCampaigns => this.setState({activeCampaigns}))
    }

    render() {
        return (
            <React.Fragment>
                <AppNavbar authorities={this.props.authorities}/>
                <Report authorities={this.props.authorities}
                        profileKey="RETARGETING_REPORT"
                        filterDefs={RETARGETING_REPORT_FILTERS_CONFIG}
                        gridColumnDefs={RETARGETING_REPORT_COLUMNS}
                        gridDefaultGroupings={RETARGETING_REPORT_DEFAULT_GROUPINGS}
                        gridDefaultSortingModel={RETARGETING_REPORT_DEFAULT_SORTING_MODEL}
                        gridActiveCampaigns={this.state.activeCampaigns}
                        loadFunc={ReportApi.getRetargetingData}
                        exportFunc={ReportApi.exportRetargetingData}
                />
            </React.Fragment>
        );
    }
}

export default withCookies(withRouter(RetargetingReport));
