import React, {useEffect} from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import {Backdrop, Button, Card, CardContent, CircularProgress, TextField} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useForm} from 'react-hook-form'
import Snackbar from "../../../components/Snackbar/Snackbar";
import AddAlert from "@material-ui/icons/AddAlert";
import {get, post} from "../../../common/ApiUtils";
import Description from "./Description";
import {CatalogApi} from "../../../api/CatalogApi";


const useStyles = makeStyles((theme) => ({
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function TikTokCreateTab() {
    const classes = useStyles();
    const [tl, setTL] = React.useState(false);
    const [tr, setTR] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [appOptions, setAppOptions] = React.useState([]);
    const [appId, setAppId] = React.useState('');
    const [account, setAccount] = React.useState({label: "", value: "", id: "account"});
    const [accounts, setAccounts] = React.useState([]);
    const [query, setQuery] = React.useState({filters: []});
    const [queryOptions, setQueryOptions] = React.useState([]);
    const [audienceName, setAudienceName] = React.useState('');
    const {register, handleSubmit} = useForm()
    const [loading, setLoading] = React.useState(false);


    function showLoading() {
        setLoading(true);
    }

    function hideLoading() {
        setLoading(false);
    }

    function loadAccountsByAppId(appId) {
        setAccount('')
        setAccounts([])
        get("/api/tiktok/accounts?appId=" + appId).then(resp => {
            const accountOptions = [];
            resp.body.forEach(elem => accountOptions.push({
                value: elem.id,
                label: elem.name,
                id: "account"
            }));
            setAccount(accountOptions[0]);
            setAccounts(accountOptions)
        })
    }

    async function fetchData() {
        CatalogApi.getMainAppWithPlatformOptions().then(apps => {
            setAppOptions(apps);
            setAppId(apps[0])
            loadAccountsByAppId(apps[0].value);
        });

        CatalogApi.getAutoUpdateAudienceQueryOptions().then(options => {
            setQuery(options[0]);
            setQueryOptions(options);
        });
    }


    useEffect(() => {
        fetchData();
    }, [])

    function getBody(data) {
        let filters = {}
        query.filters.forEach(filter => {
            if (filter.name === "eventDate" || filter.name === "installDate") {
                filters.startDate = data.startDate
                filters.endDate = data.endDate
            } else if (filter.name === "appId") {
                filters.appId = appId.value;
            } else {
                let value = data[filter.name].value;
                if (value) {
                    filters[filter.name] = value
                } else {
                    filters[filter.name] = data[filter.name]
                }

            }
        })
        return {
            name: audienceName,
            queryId: query.value,
            accountId: account.value,
            filters: filters
        };
    }

    const formInvalid = () => {
        let formValid = !!appId && !!account && !!audienceName && !!query;

        return !formValid
    }

    function onAudienceCreate(data) {
        showLoading();

        const body = getBody(data)

        post('/api/tiktok/audience', body)
            .then(response => {
                setSuccessMessage("Audience  successfully created")
                showNotification("tl");
            })
            .catch(responseText => {
                setErrorMessage(responseText);
                showNotification("tr");
            })
            .finally(() => hideLoading());
    }

    function showNotification(place) {
        switch (place) {
            case "tl":
                setTL(true);
                break;
            case "tr":
                setTR(true);
                break;
            default:
                break;
        }
    }

    return (
        <div>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Card>
                <CardContent>
                    <form className={classes.form} noValidate onSubmit={handleSubmit(onAudienceCreate)}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    value={appId}
                                    onChange={(event, newValue) => {
                                        setAppId(newValue);
                                        loadAccountsByAppId(newValue.value)
                                    }}
                                    options={appOptions}
                                    getOptionLabel={(option) => option.label || ''}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="App"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    value={account}
                                    onChange={(event, newValue) => setAccount(newValue)}
                                    options={accounts}
                                    getOptionLabel={(option) => option.label || ''}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Account"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    value={query}
                                    onChange={(event, newValue) => {
                                        setQuery(newValue);
                                    }}
                                    options={queryOptions}
                                    getOptionLabel={(option) => option.label || ''}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Query"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <TextField fullWidth required onChange={(event => setAudienceName(event.target.value))}
                                           name="name" label="Audience name"/>
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                                <Button type={"submit"} variant="contained" color="primary"
                                        disabled={formInvalid()}>Create</Button>
                                <Snackbar
                                    place="tl"
                                    color="success"
                                    icon={AddAlert}
                                    message={successMessage}
                                    open={tl}
                                    closeNotification={() => setTL(false)}
                                    close
                                />
                                <Snackbar
                                    place="tr"
                                    color="error"
                                    icon={AddAlert}
                                    message={errorMessage}
                                    open={tr}
                                    closeNotification={() => setTR(false)}
                                    close
                                />
                            </GridItem>
                        </GridContainer>
                    </form>
                </CardContent>
            </Card>
            <Description/>
        </div>
    );
}