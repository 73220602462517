import React, {useEffect} from "react";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import {makeStyles} from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import CardFooter from "../../../components/Card/CardFooter";
import Button from "@material-ui/core/Button";
import {useForm} from 'react-hook-form'
import {useCookies} from 'react-cookie'
import Snackbar from "../../../components/Snackbar/Snackbar";
import AddAlert from "@material-ui/icons/AddAlert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {CatalogApi} from "../../../api/CatalogApi";


const useStyles = makeStyles((theme) => ({
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const appOptions = [
    {value: "cm", label: "Clockmaker", id: "appCode"},
    {value: "fe", label: "Funky Bay", id: "appCode"},
    {value: "sc", label: "Solitaire Cruise", id: "appCode"},
    {value: "ba", label: "Bermuda Adventures", id: "appCode"},
];

const platformOptions = [
    {value: "an", label: "Android", id: "platform"},
    {value: "io", label: "iOS", id: "platform"},
    {value: "am", label: "Amazon", id: "platform"},
    {value: "fb", label: "Facebook", id: "platform"}
];

export default function FacebookCreateTab() {
    const classes = useStyles();
    const [tl, setTL] = React.useState(false);
    const [tr, setTR] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [appCode, setAppCode] = React.useState(appOptions[0]);
    const [platform, setPlatform] = React.useState(platformOptions[0]);
    const [audience, setAudience] = React.useState({});
    const [audienceOptions, setAudienceOptions] = React.useState([]);
    const [account, setAccount] = React.useState({label: "", value: "", id: "account"});
    const [fbAccounts, setFbAccounts] = React.useState([]);
    const [apps, setApps] = React.useState([]);
    const [query, setQuery] = React.useState({});
    const [queryOptions, setQueryOptions] = React.useState([]);
    const [appId, setAppId] = React.useState({label: "", value: "", id: "appId"});
    const {register, handleSubmit, control} = useForm()
    const {setCookie} = useCookies(['XSRF-TOKEN']);
    const [loading, setLoading] = React.useState(false);

    function showLoading() {
        setLoading(true);
    }

    function hideLoading() {
        setLoading(false);
    }

    async function fetchData() {
        await onAppPlatformUpdate();
        CatalogApi.getAppWithPlatformOptions().then(apps => setApps(apps))
        CatalogApi.getAudienceQueryOptions().then(queryOptions => {
            setQuery(queryOptions[0]);
            setQueryOptions(queryOptions);
        })
    }

    async function onAccountUpdate() {
        fetch("/api/facebook/account/" + account.value + "/audiences", {credentials: "include"})
            .then(resp => resp.json())
            .then(resp => {
                if (resp === "") {
                } else {
                    const audienceOptions = [];
                    resp.forEach(elem => audienceOptions.push({
                        value: elem.id,
                        label: elem.name,
                        id: "audience"
                    }));
                    setAudienceOptions(audienceOptions);
                    setAudience(audienceOptions[0]);
                }
            })
            .catch(error => console.log(error))
    }

    async function onAppPlatformUpdate() {
        let selectedApp = apps.filter(f => f.label === appCode.label + " " + platform.value);
        if (selectedApp.length > 0) {
            setAppId(selectedApp[0]);
        }
        fetch("/api/facebook/accounts?appCode=" + appCode.value + "&platform=" + platform.value, {credentials: "include"})
            .then(resp => resp.json())
            .then(resp => {
                if (resp === "") {
                } else {
                    const accountOptions = [];
                    resp.forEach(elem => accountOptions.push({
                        value: elem.id,
                        label: elem.name,
                        id: "account"
                    }));
                    setFbAccounts(accountOptions);
                    setAccount(accountOptions[0]);
                }
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        onAppPlatformUpdate();
    }, [appCode, platform])

    useEffect(() => {
        onAccountUpdate();
    }, [account])

    function onAudienceCreate(data) {
        console.log(JSON.stringify(data))
        showLoading();
        const cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith('XSRF-TOKEN'))
            .split('=')[1]
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-XSRF-TOKEN": cookieValue
            },
            body: JSON.stringify(data)
        };
        let url = "/api/facebook/account/" + account.value + "/audience";
        fetch(url, requestOptions)
            .then(async response => {
                let responseText = await response.text();
                let status = response.status;
                if (status === 200) {
                    console.log("success")
                    setSuccessMessage("Audience under account " + account.label + " successfully created")
                    showNotification("tl");
                } else {
                    setErrorMessage(responseText);
                    showNotification("tr");
                }
            })
            .catch(error => {
                console.log(error);
                setErrorMessage(error.toString);
                showNotification("tr");
            }).finally(() => hideLoading());
    }

    const showNotification = place => {
        switch (place) {
            case "tl":
                if (!tl) {
                    setTL(true);
                    setTimeout(function () {
                        setTL(false);
                    }, 6000);
                }
                break;
            case "tr":
                if (!tr) {
                    setTR(true);
                    setTimeout(function () {
                        setTR(false);
                    }, 60000);
                }
                break;
            default:
                break;
        }
    }
    return (
        <div>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Card>
                <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Create Audience</h4>
                    <p className={classes.cardCategoryWhite}>Create custom audience</p>
                </CardHeader>
                <CardBody>
                    <form className={classes.form} onSubmit={handleSubmit(onAudienceCreate)}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    value={appCode}
                                    onChange={(event, newValue) => {
                                        setAppCode(newValue);
                                    }}
                                    options={appOptions}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="App"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    value={platform}
                                    onChange={(event, newValue) => {
                                        setPlatform(newValue);
                                    }}
                                    options={platformOptions}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Platform"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    value={account}
                                    onChange={(event, newValue) => {
                                        setAccount(newValue);
                                    }}
                                    inputRef={register}
                                    options={fbAccounts}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Account"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <TextField fullWidth required inputRef={register} name="name" label="Audience name"/>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <TextField fullWidth required inputRef={register} name="description"
                                           label="Audience description"/>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Button type={"submit"} style={{float: 'left'}} variant="contained" color="primary">Create
                                    Audience</Button>
                                <Snackbar
                                    place="tl"
                                    color="success"
                                    icon={AddAlert}
                                    message={successMessage}
                                    open={tl}
                                    closeNotification={() => setTL(false)}
                                    close
                                />
                                <Snackbar
                                    place="tr"
                                    color="error"
                                    icon={AddAlert}
                                    message={errorMessage}
                                    open={tr}
                                    closeNotification={() => setTR(false)}
                                    close
                                />
                            </GridItem>
                        </GridContainer>
                    </form>
                </CardBody>
                <CardFooter>
                </CardFooter>
            </Card>
        </div>
    );
}