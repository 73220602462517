import React, {useEffect} from "react";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import {makeStyles} from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import {useForm} from 'react-hook-form'
import DateRangeSelect from "../../../searchpanel/datepicker/DateRangeSelect";
import moment from "moment";
import Snackbar from "../../../components/Snackbar/Snackbar";
import AddAlert from "@material-ui/icons/AddAlert";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import MaterialTable from "material-table";
import tableIcons from "assets/jss/material-dashboard-react/components/materialTableStyle.js";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from "@material-ui/core/IconButton";
import {CatalogApi} from "../../../api/CatalogApi";


const useStyles = makeStyles((theme) => ({
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const appOptions = [
    {value: "cm", label: "Clockmaker", id: "appCode"},
    {value: "fe", label: "Funky Bay", id: "appCode"},
    {value: "sc", label: "Solitaire Cruise", id: "appCode"},
    {value: "ba", label: "Bermuda Adventures", id: "appCode"}
];

const periodOptions = [
    {value: "DAILY", label: "DAILY", id: "period"},
    {value: "WEEKLY", label: "WEEKLY", id: "period"},
    {value: "MONTHLY", label: "MONTHLY", id: "period"}
];

const platformOptions = [
    {value: "an", label: "Android", id: "platform"},
    {value: "io", label: "iOS", id: "platform"},
    {value: "am", label: "Amazon", id: "platform"},
    {value: "fb", label: "Facebook", id: "platform"}
];

const schemaOptions = [
    {value: "MADID", label: "MADID"},
    {value: "COUNTRY_CODE", label: "COUNTRY_CODE"},
    {value: "LOOKALIKE_VALUE", label: "LOOKALIKE_VALUE"}
]

export default function FacebookAutoUploadTab() {
    const classes = useStyles();

    const [appCode, setAppCode] = React.useState(appOptions[0]);
    const [platform, setPlatform] = React.useState(platformOptions[0]);
    const [audience, setAudience] = React.useState({label: "", value: "", id: "audience"});
    const [schema, setSchema] = React.useState([schemaOptions[0]]);
    const [audienceOptions, setAudienceOptions] = React.useState([]);
    const [jobsOptions, setJobsOptions] = React.useState([]);
    const [account, setAccount] = React.useState({label: "", value: "", id: "account"});
    const [fbAccounts, setFbAccounts] = React.useState([]);
    const [apps, setApps] = React.useState([]);
    const [query, setQuery] = React.useState({filters: []});
    const [queryOptions, setQueryOptions] = React.useState([]);
    const [appId, setAppId] = React.useState({
        value: "com.belkatechnologies.clockmaker",
        label: "Clockmaker an",
        id: "appId"
    });
    const [startDate, setStartDate] = React.useState(moment().startOf('week').subtract(4, 'week').format("YYYY-MM-DD"));
    const [endDate, setEndDate] = React.useState(moment().subtract(2, 'days').format("YYYY-MM-DD"));
    const {register, handleSubmit, control} = useForm()
    const [tl, setTL] = React.useState(false);
    const [tr, setTR] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [period, setPeriod] = React.useState(periodOptions[0]);

    function showLoading() {
        setLoading(true);
    }

    function hideLoading() {
        setLoading(false);
    }

    function onDatePickerChange(start, end) {
        setStartDate(start.format("YYYY-MM-DD"))
        setEndDate(end.format("YYYY-MM-DD"))
    }

    async function fetchData() {
        CatalogApi.getAppWithPlatformOptions().then(apps => setApps(apps))
        await onAppPlatformUpdate();
        CatalogApi.getAudienceQueryOptions().then(queryOptions => {
            setQuery(queryOptions[0]);
            setQueryOptions(queryOptions);
        })
    }

    async function onAccountUpdate() {
        fetch("/api/facebook/account/" + account.value + "/audiences", {credentials: "include"})
            .then(resp => resp.json())
            .then(resp => {
                if (resp === "") {
                } else {
                    const audienceOptions = [];
                    resp.forEach(elem => audienceOptions.push({
                        value: elem.id,
                        label: elem.name,
                        id: "audience"
                    }));
                    setAudienceOptions(audienceOptions);
                    setAudience(audienceOptions[0]);
                }
            })
            .catch(error => console.log(error))
            .finally(() => {
            });
        updateJobs();
    }

    function updateJobs() {
        if (account)
            fetch("/api/facebook/account/" + account.value + "/autoupdated", {credentials: "include"})
                .then(resp => resp.json())
                .then(resp => {
                    let status = resp.status;
                    if (resp === "") {
                    } else {
                        if (status !== 404) {
                            setJobsOptions(resp);
                        }
                    }
                })
                .catch(error => console.log(error))
                .finally(() => {
                });
    }

    async function onAppPlatformUpdate() {
        let selectedApp = apps.filter(f => f.label === appCode.label + " " + platform.value);
        if (selectedApp.length > 0) {
            setAppId(selectedApp[0]);
        }
        fetch("/api/facebook/accounts?appCode=" + appCode.value + "&platform=" + platform.value, {credentials: "include"})
            .then(resp => resp.json())
            .then(resp => {
                if (resp === "") {
                } else {
                    const accountOptions = [];
                    resp.forEach(elem => accountOptions.push({
                        value: elem.id,
                        label: elem.name,
                        id: "account"
                    }));
                    setFbAccounts(accountOptions);
                    setAccount(accountOptions[0]);
                }
            })
            .catch(error => console.log(error))
            .finally(() => {
            });
    }

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        onAppPlatformUpdate();
    }, [appCode, platform])

    useEffect(() => {
        onAccountUpdate();
    }, [account])

    const showNotification = place => {
        switch (place) {
            case "tl":
                if (!tl) {
                    setTL(true);
                    setTimeout(function () {
                        setTL(false);
                    }, 6000);
                }
                break;
            case "tr":
                if (!tr) {
                    setTR(true);
                    setTimeout(function () {
                        setTR(false);
                    }, 60000);
                }
                break;
            default:
                break;
        }
    }

    function getBody(data) {
        let filters = {}
        query.filters.forEach(filter => {
            if (filter.name === "eventDate" || filter.name === "installDate") {
                filters.startDate = data.startDate
                filters.endDate = data.endDate
            } else if (filter.name === "appId") {
                filters.appId = appId.value;
            } else {
                let value = data[filter.name].value;
                if (value) {
                    filters[filter.name] = value
                } else {
                    filters[filter.name] = data[filter.name]
                }

            }
        })
        return {
            queryId: query.value,
            filters: filters,
            schema: data.schema.split(','),
            period: data.period
        };
    }

    function createAutoUpload(data) {
        showLoading();
        console.log(JSON.stringify(data));
        const cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith('XSRF-TOKEN'))
            .split('=')[1]
        const body = JSON.stringify(getBody(data))
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-XSRF-TOKEN": cookieValue
            },
            body: body
        };
        let url = "/api/facebook/account/" + account.value + "/audience/" + audience.value + "/autoupdated";
        fetch(url, requestOptions)
            .then(async response => {
                let responseText = await response.text();
                let status = response.status;
                if (status === 200) {
                    console.log("success")
                    setSuccessMessage("Autoupdate under account " + account.label + " successfully created")
                    showNotification("tl");
                } else {
                    setErrorMessage(responseText);
                    showNotification("tr");
                    console.log("error")
                }
            })
            .catch(error => {
                console.log(error);
                setErrorMessage(error.toString());
                showNotification("tr");
            })
            .finally(() => {
                updateJobs();
                hideLoading();
            });
    }


    function isVisible(id) {
        let element = query.filters.filter(f => f.name === id);
        return element.length > 0;
    }

    function deleteJob(id) {
        const cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith('XSRF-TOKEN'))
            .split('=')[1]
        const requestOptions = {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-XSRF-TOKEN": cookieValue
            },
        };
        let url = "/api/facebook/autoupdated/" + id;
        fetch(url, requestOptions)
            .then(async response => {
                let responseText = await response.text();
                let status = response.status;
                if (status === 200) {
                    console.log("success")
                    setSuccessMessage("Autoupdate under account " + account.label + " successfully deleted")
                    showNotification("tl");
                } else {
                    setErrorMessage(responseText);
                    showNotification("tr");
                    console.log("error")
                }
            })
            .catch(error => {
                console.log(error);
                setErrorMessage(error.toString());
                showNotification("tr");
            }).finally(() => updateJobs());
    }

    return (
        <div>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Card>
                <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Auto Upload Audience</h4>
                    <p className={classes.cardCategoryWhite}></p>
                </CardHeader>
                <CardBody>
                    <form className={classes.form} noValidate onSubmit={handleSubmit(createAutoUpload)}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    value={appCode}
                                    onChange={(event, newValue) => {
                                        setAppCode(newValue);
                                    }}
                                    options={appOptions}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="App"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    value={platform}
                                    onChange={(event, newValue) => {
                                        setPlatform(newValue);
                                    }}
                                    options={platformOptions}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Platform"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    value={account}
                                    onChange={(event, newValue) => {
                                        setAccount(newValue);
                                    }}
                                    inputRef={register}
                                    options={fbAccounts}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Account"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    value={query}
                                    onChange={(event, newValue) => {
                                        setQuery(newValue);
                                    }}
                                    options={queryOptions}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Query"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    value={audience}
                                    onChange={(event, newValue) => {
                                        setAudience(newValue);
                                    }}
                                    options={audienceOptions}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Audience"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    multiple
                                    options={schemaOptions}
                                    getOptionLabel={(option) => option.label}
                                    defaultValue={[schemaOptions[0]]}
                                    // name="schema"
                                    onChange={(event, newValue) => {
                                        setSchema(newValue);
                                    }}
                                    inputRef={register}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Schema fields"
                                        />
                                    )}
                                />
                            </GridItem>
                            <input type="hidden" ref={register} name="schema" value={schema.map(item => item.value)}/>
                        </GridContainer>
                        <GridContainer>
                            {isVisible("eventDate") || isVisible("installDate") ?
                                <GridItem xs={12} sm={12} md={4}>
                                    <DateRangeSelect onDatePickerChange={onDatePickerChange}/>
                                </GridItem> : null}
                            <input type="hidden" ref={register} name="startDate" value={startDate}/>
                            <input type="hidden" ref={register} name="endDate" value={endDate}/>
                            {isVisible("revenueLimit") ?
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormControl className={classes.formControl}>
                                        <TextField required inputRef={register} name="revenueLimit"
                                                   label="Revenue limit" defaultValue="=0"
                                        />
                                    </FormControl>
                                </GridItem> : null}
                            {isVisible("level") ?
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormControl className={classes.formControl}>
                                        <TextField required inputRef={register} name="level"
                                                   label="Level" defaultValue="level_5"
                                        />
                                    </FormControl>
                                </GridItem> : null}
                            {isVisible("cohortDay") ?
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormControl className={classes.formControl}>
                                        <TextField required inputRef={register} name="cohortDay"
                                                   label="Cohort day" defaultValue="7"
                                        />
                                    </FormControl>
                                </GridItem> : null}
                            {isVisible("retainedDay") ?
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormControl className={classes.formControl}>
                                        <TextField required inputRef={register} name="retainedDay"
                                                   label="Retained day" defaultValue=">0"
                                        />
                                    </FormControl>
                                </GridItem> : null}
                            {isVisible("activityHours") ?
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormControl className={classes.formControl}>
                                        <TextField required inputRef={register} name="activityHours"
                                                   label="Activity Hours" defaultValue="80"
                                        />
                                    </FormControl>
                                </GridItem> : null}
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    value={period}
                                    onChange={(event, newValue) => {
                                        setPeriod(newValue);
                                    }}
                                    options={periodOptions}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Period"
                                                                        variant="standard"/>}
                                />
                                <input type="hidden" ref={register} name="period" value={period.value}/>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <Button type={"submit"} variant="contained" color="primary">Create job</Button>
                                <Snackbar
                                    place="tl"
                                    color="success"
                                    icon={AddAlert}
                                    message={successMessage}
                                    open={tl}
                                    closeNotification={() => setTL(false)}
                                    close
                                />
                                <Snackbar
                                    place="tr"
                                    color="error"
                                    icon={AddAlert}
                                    message={errorMessage}
                                    open={tr}
                                    closeNotification={() => setTR(false)}
                                    close
                                />
                            </GridItem>
                        </GridContainer>
                    </form>
                </CardBody>
            </Card>
            <Paper>
                <MaterialTable
                    icons={tableIcons}
                    columns={[
                        {
                            title: "Account", field: "accountId",
                            render: rowData => {
                                let acc = fbAccounts.find(x => x.value === rowData.accountId);
                                return <span>
                                    {
                                        acc ? acc.label : rowData.accountId
                                    }
                                </span>
                            }
                        },
                        {
                            title: "Audience", field: "audienceId",
                            render: rowData => {
                                let aud = audienceOptions.find(x => x.value === rowData.audienceId);
                                return <span>
                                    {
                                        aud ? aud.label : rowData.audienceId
                                    }
                                </span>
                            }

                        },
                        // {title: "Query", field: "query"},
                        {title: "Period", field: "period"},
                        {title: "Last update", field: "lastUpdated"},
                        {
                            title: "Status",
                            field: "status",
                            render: rowData =>
                                <div>
                                    {/*{rowData.isActive ?*/}
                                    {/*    <IconButton onClick={handleJob(rowData.id)}>*/}
                                    {/*        <PauseCircleOutlineIcon/>*/}
                                    {/*    </IconButton>*/}
                                    {/*    : <IconButton color="primary" onClick={handleJob(rowData.id)}>*/}
                                    {/*        <PlayCircleOutlineIcon/>*/}
                                    {/*    </IconButton>}*/}
                                    <IconButton color="secondary" onClick={() => deleteJob(rowData.id)}>
                                        <DeleteForeverIcon/>
                                    </IconButton>
                                </div>
                        },
                    ]}
                    data={jobsOptions}
                    title="Current jobs"
                />
            </Paper>
        </div>
    );

}
