import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@ag-grid-enterprise/all-modules";
import { createBrowserHistory } from "history";
import {BrowserRouter as Router} from 'react-router-dom';

import {LicenseManager} from "@ag-grid-enterprise/core";
import {CookiesProvider} from "react-cookie";
import { SnackbarProvider } from 'notistack';

LicenseManager.setLicenseKey("CompanyName=SAMFINACO LIMITED,LicensedApplication=Belka marketing dashboard,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,ExpiryDate=14_February_2021_[v2]_MTYxMzI2MDgwMDAwMA==726e7d9db7dde1483f28441a378f1c3e");
const hist = createBrowserHistory();

ReactDOM.render(
    <CookiesProvider>
        <SnackbarProvider
                maxSnack={1}
                preventDuplicate
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}>
            <Router history={hist}>
                <App/>
            </Router>
        </SnackbarProvider>
    </CookiesProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
