import React from "react";
import {Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";

class ProfileNameModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            load: false,
            profileName: ''
        }
        this.handleProfileSave = this.handleProfileSave.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.toggleLoadFlag = this.toggleLoadFlag.bind(this)
    }

    toggleLoadFlag(show) {
        this.setState((prevState) => ({...prevState, load: show}))
    }

    handleProfileSave() {
        this.toggleLoadFlag(true)
        this.props.handleProfileSave(this.state.profileName)
            .then(() => this.setState((prevState) => ({...prevState, profileName: ''})))
            .finally(() => this.toggleLoadFlag(false))
    }

    closeModal() {
        this.props.closeModal()
        this.setState(({load: false, profileName: ''}))
    }

    render() {
        return (
            <Modal isOpen={this.props.showModal} toggle={this.closeModal} unmountOnClose={true}>
                <ModalHeader toggle={this.closeModal}>Define profile name</ModalHeader>
                <ModalBody>
                    <Input type="text"
                           id="profileName"
                           placeholder="Profile name"
                           value={this.state.profileName}
                           onChange={(event) => {
                               const profileName = event.target.value;
                               this.setState((prevState) => ({...prevState, profileName}))
                           }}/>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={this.state.load} color="primary" onClick={this.handleProfileSave}>
                        {this.state.load && <span><Spinner size="sm"> </Spinner>&nbsp;&nbsp;&nbsp;</span>}
                        Save profile
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ProfileNameModal;
