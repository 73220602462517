import React, {useEffect} from "react";
import {
    Backdrop,
    Button,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    Grid,
    Snackbar,
    TextField
} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment'
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import {APPS_OPTIONS} from "./AbTest";
import {makeStyles} from "@material-ui/core/styles";
import MuiAlert from '@material-ui/lab/Alert';
import {get, post} from "../../common/ApiUtils";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

const DEFAULT_BUDGETS = {
    US: 200,
    MX: 70,
    BR: 35
}

const defaultStartDate = () => {
    let startDate = moment.utc();
    startDate.add(4, 'hour')
    startDate.millisecond(0)
    startDate.second(0)

    return startDate
}

export default function AddTest(props) {

    const classes = useStyles();

    const [app, setApp] = React.useState(Object.keys(APPS_OPTIONS)[0]);
    const [creatives, setCreatives] = React.useState([]);
    const [geo, setGeo] = React.useState();
    const [budget, setBudget] = React.useState();
    const [fanBenchCreative, setFanBenchCreative] = React.useState();
    const [feedBenchCreative, setFeedBenchCreative] = React.useState();
    const [startDate, setStartDate] = React.useState(defaultStartDate());
    const [showConfirmation, setShowConfirmation] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [successMessage, setSuccessMessage] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [formDisabled, setFormDisabled] = React.useState(false);

    const [CREATIVES_OPTIONS, setCreativesOptions] = React.useState([]);

    // eslint-disable-next-line
    useEffect(() => loadCreativesOptions(app), [])

    const loadCreativesOptions = (app) => {
        get(`/api/facebook-ab-test/creative-names-by-app?app=${app}`).then(response => setCreativesOptions(response.body))
    }

    const formInvalid = () => {
        let formValid = !!app && !!geo && !!budget && budget > 0 && !!fanBenchCreative && !!feedBenchCreative
            && !!startDate && creatives.length > 0;

        return !formValid
    }

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage(null);
        setSuccessMessage(null);
    };

    const submitForm = (confirmed) => {
        if (confirmed) {
            setLoading(true)
            setFormDisabled(true)

            const body = {
                app,
                geo,
                budget,
                startDate,
                feedCreatives: [...creatives],
                fanCreatives: [...creatives],
                feedBenchCreative,
                fanBenchCreative
            }

            post('/api/facebook-ab-test', body)
                .then(response => {
                    setErrorMessage(null);
                    setSuccessMessage(`Test #${response.body} created successfully`)
                })
                .catch(response => setErrorMessage(response.body.message))
                .finally((_) => {
                    setLoading(false)
                    setFormDisabled(false)
                });
        }
        setShowConfirmation(false)
    }

    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Card>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <Autocomplete fullWidth options={Object.keys(APPS_OPTIONS)}
                                          value={app}
                                          getOptionLabel={(option) => APPS_OPTIONS[option]}
                                          onChange={(_event, option) => {
                                              setApp(option)
                                              setCreatives([])
                                              setFanBenchCreative('')
                                              setFeedBenchCreative('')
                                              loadCreativesOptions(option)
                                          }}
                                          disableClearable
                                          renderInput={(params) => <TextField {...params} required
                                                                              label="Title name"/>}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete fullWidth options={CREATIVES_OPTIONS}
                                          value={creatives}
                                          onChange={(event, newValue) => setCreatives(newValue)}
                                          multiple
                                          disableClearable
                                          renderTags={(value, _getTagProps) => value.join(', ')}
                                          renderInput={(params) => <TextField {...params} required
                                                                              label="Add Creative"/>}/>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Autocomplete fullWidth options={props.countryOptions}
                                          disableClearable
                                          getOptionDisabled={(option) => option === '--'}
                                          value={geo}
                                          onChange={(event, newValue) => {
                                              setGeo(newValue)
                                              setBudget(DEFAULT_BUDGETS[newValue] || 0)
                                          }}
                                          renderInput={(params) => <TextField {...params} required
                                                                              label="GEO"/>}/>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField fullWidth label="Campaign Details - Budget($)" type="number" required
                                       InputLabelProps={{
                                           shrink: !!budget || budget === 0
                                       }}
                                       value={budget}
                                       onChange={(event) => setBudget(event.target.value)}/>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Autocomplete fullWidth options={CREATIVES_OPTIONS}
                                          value={fanBenchCreative}
                                          disableClearable
                                          onChange={(event, newValue) => setFanBenchCreative(newValue)}
                                          renderInput={(params) => <TextField {...params} required
                                                                              label="FAN Bench creative"/>}/>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Autocomplete fullWidth options={CREATIVES_OPTIONS}
                                          value={feedBenchCreative}
                                          disableClearable
                                          onChange={(event, newValue) => setFeedBenchCreative(newValue)}
                                          renderInput={(params) => <TextField {...params} required
                                                                              label="Feed Bench creative"/>}/>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                                <DateRangePicker startDate={startDate}
                                                 minDate={defaultStartDate()}
                                                 autoUpdateInput
                                                 singleDatePicker
                                                 timePicker
                                                 onApply={(event, picker) => setStartDate(picker.startDate)}
                                                 alwaysShowCalendars={true}>
                                    <div className="form-group">
                                        <TextField id="standard-basic" style={{width: "100%"}} readOnly required
                                                   label="Start Date (UTC)"
                                                   value={startDate.format('MM/DD/YYYY  hh:mm A')}/>
                                    </div>
                                </DateRangePicker>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Button variant="contained" color="primary"
                            disabled={formDisabled || formInvalid()}
                            onClick={() => setShowConfirmation(true)}>Start campaign</Button>
                    <ConfirmationDialog open={!!showConfirmation}
                                        onClose={submitForm}>
                        Are you sure you want to run test with this configuration?
                    </ConfirmationDialog>
                </CardContent>
            </Card>

            <ul style={{color: 'red', marginTop: '1rem', textAlign: 'left'}}>
                <li>
                    После того как кампании будут созданы, в панели Facebook Ads Manager нужно вручную добавить тексты в
                    поля "Primary text" и "Headline" для всех ссзданых Ad Group.
                    <br/>
                    Тексты в зависимости от GEO следующие:
                    <ul>
                        <li>
                            <u>US:</u>
                            <br/>
                            Primary text - 🌟Very addictive game! You'll want to play 24/7 🌟
                            <br/>
                            Headline - Unlock all the levels!
                        </li>
                        <li>
                            <u>MX:</u>
                            <br/>
                            Primary text - 🌟¡Un juego muy adictivo! Querrás jugar todo el tiempo 🌟
                            <br/>
                            Headline - ¡Desbloquea todos los niveles!
                        </li>
                        <li>
                            <u>BR:</u>
                            <br/>
                            Primary text - 🌟Jogo viciante! Você não vai querer parar de jogar 🌟
                            <br/>
                            Headline - Desbloqueie todos os níveis!
                        </li>
                    </ul>
                </li>
                <li>
                    Креативы отключатся при достижении нужного спенда. Работает только для пресетов US, MX, BR. Для
                    остальных гео - правила нужно выставлять вручную в кабинете фейсбука
                </li>
                <li>
                    Тестовые кампании после проверки в Facebook Ads Manager нужно включать вручную
                </li>
                <li>
                    Список актуальных тестовых аккаунтов:
                    <ul>
                        <li>
                            Clockmaker - Clockmaker_Android_3
                            <br/>
                            ID: 738617607029194
                        </li>
                        <li>
                            Solitaire Cruise - Solitaire_Cruise_GP_5
                            <br/>
                            ID: 799023644265920
                        </li>
                        <li>
                            Bermuda - Bermuda_Adventures_GP_tests
                            <br/>
                            ID: 623061818803583
                        </li>
                        <li>
                            Funky Bay - Funky_Bay_Android_2
                            <br/>
                            ID: 849376795397170
                        </li>
                    </ul>
                </li>
            </ul>
            <Snackbar open={!!errorMessage} autoHideDuration={18000} onClose={handleCloseSnackBar}>
                <MuiAlert severity="error">{errorMessage}</MuiAlert>
            </Snackbar>
            <Snackbar open={!!successMessage} autoHideDuration={18000} onClose={handleCloseSnackBar}>
                <MuiAlert severity="success">{successMessage}</MuiAlert>
            </Snackbar>
        </React.Fragment>
    )
}
