import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useTheme} from '@material-ui/core/styles';

export default function ConfirmationDialog(props) {

    const [text] = React.useState(props.text);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = (result) => {
        props.onClose(result);
    };

    return (
        <Dialog fullScreen={fullScreen}
                open={props.open}
                onClose={() => handleClose(false)}
                aria-labelledby="responsive-dialog-title">

            <DialogTitle id="responsive-dialog-title">{"Confirmation"}</DialogTitle>
            <DialogContent>
                <DialogContentText >
                    {props.children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => handleClose(false)} color="primary">No</Button>
                <Button onClick={() => handleClose(true)} color="primary" autoFocus>Yes</Button>
            </DialogActions>
        </Dialog>
    );
}