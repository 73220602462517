import React, {useEffect} from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import {useForm} from 'react-hook-form'
import {makeStyles} from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Button from "../../components/CustomButtons/Button";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import MaterialTable from "material-table";
import tableIcons from "assets/jss/material-dashboard-react/components/materialTableStyle.js";
import MaterialDateRange from "../../components/DateRangeSelect/MaterialDateRange";
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


const appOptions = [
    {value: "cm", label: "Clockmaker", id: "appCode"},
    {value: "fe", label: "Funky Bay", id: "appCode"},
    {value: "sc", label: "Solitaire Cruise", id: "appCode"},
    {value: "ba", label: "Bermuda Adventures", id: "appCode"},
    {value: "mwe", label: "Merge with Expedition", id: "appCode"},
];

const platformOptions = [
    {value: "io", label: "iOS", id: "platform"},
    {value: "an", label: "Android", id: "platform"},
];

const allSourceOptions = [
    {value: "APP_REF", label: "APP_REF", id: "source"},
    {value: "SEARCH", label: "SEARCH", id: "source"},
    {value: "EXPLORE", label: "EXPLORE", id: "source"},
    {value: "WEB_REF", label: "WEB_REF", id: "source"},
    {value: "UNKNOWN", label: "UNKNOWN", id: "source"},
    {value: "UNCLEAN_SEARCH", label: "UNCLEAN_SEARCH", id: "source"},
];

const ioSourceIndexes = [0, 1, 2, 3, 4, 5]
const anSourceIndexes = [0, 1, 2]

const countryOptions = [
    {value: "All", label: "All", id: "country"},
    {value: "US", label: "US", id: "country"},
    {value: "UK", label: "UK", id: "country"},
    {value: "CA", label: "CA", id: "country"},
    {value: "AU", label: "AU", id: "country"},
    {value: "CN", label: "CN", id: "country"},
    {value: "DE", label: "DE", id: "country"},
    {value: "TW", label: "TW", id: "country"},
    {value: "IT", label: "IT", id: "country"},
    {value: "ES", label: "ES", id: "country"},
    {value: "JP", label: "JP", id: "country"},
    {value: "FR", label: "FR", id: "country"},
    {value: "KR", label: "KR", id: "country"},
    {value: "SG", label: "SG", id: "country"},
    {value: "HK", label: "HK", id: "country"},
    {value: "AT", label: "AT", id: "country"},
    {value: "SE", label: "SE", id: "country"},
    {value: "CH", label: "CH", id: "country"},
    {value: "RU", label: "RU", id: "country"},
    {value: "DK", label: "DK", id: "country"},
    {value: "NO", label: "NO", id: "country"},
    {value: "ROW", label: "ROW", id: "country"}
];


export default function AsoComparativeReport(_props) {
    const classes = useStyles();

    const [appCode, setAppCode] = React.useState(appOptions[0]);
    const [platform, setPlatform] = React.useState(platformOptions[0]);
    const [firstSource, setFirstSource] = React.useState([allSourceOptions[0]]);
    const [secondSource, setSecondSource] = React.useState([allSourceOptions[0]]);
    const [sourceOptions, setSourceOptions] = React.useState(allSourceOptions);
    const [country, setCountry] = React.useState([countryOptions[0]]);
    const [fromFirst, setFromFirst] = React.useState(moment().startOf('week').subtract(4, 'week').format("YYYY-MM-DD"));
    const [toFirst, setToFirst] = React.useState(moment().subtract(2, 'days').format("YYYY-MM-DD"));
    const [data, setData] = React.useState([]);
    const [fromSecond, setFromSecond] = React.useState(moment().startOf('week').subtract(4, 'week').format("YYYY-MM-DD"));
    const [toSecond, setToSecond] = React.useState(moment().subtract(2, 'days').format("YYYY-MM-DD"));

    const {register, handleSubmit} = useForm();
    const [loading, setLoading] = React.useState(false);

    function showLoading() {
        setLoading(true);
    }

    function hideLoading() {
        setLoading(false);
    }

    useEffect(() => {
        onPlatformUpdate();
    }, [platform])

    function onPlatformUpdate() {
        console.log(platform.value)
        let result = [];
        let indexes = [];
        if (platform.value === 'io') {
            indexes = ioSourceIndexes;
        } else if (platform.value === 'an') {
            indexes = anSourceIndexes;
        }
        indexes.forEach(e => result.push(allSourceOptions[e]))
        setSourceOptions(result);
        setFirstSource([result[0]]);
        setSecondSource([result[0]]);
    }

    function onFirstDatePickerChange(start, end) {
        setFromFirst(start.format("YYYY-MM-DD"))
        setToFirst(end.format("YYYY-MM-DD"))
    }

    function onSecondDatePickerChange(start, end) {
        setFromSecond(start.format("YYYY-MM-DD"))
        setToSecond(end.format("YYYY-MM-DD"))
    }

    function isIos() {
        return platform === platformOptions[0];
    }

    function onSearch(data) {
        console.log(JSON.stringify(data))
        let params = Object.entries(data).filter(e => e[1] !== "").map(e => e[0] + "=" + e[1]).join("&");
        let url = "/api/aso/comparative?" + params;
        console.log(url);
        showLoading();
        fetch(url, {credentials: "include"})
            .then(resp => resp.json())
            .then(resp => {
                let status = resp.status;
                if (resp === "") {
                } else {
                    if (status !== 404) {
                        setData(prepareData(resp))
                    }
                }
            })
            .catch(error => console.log(error))
            .finally(() => hideLoading());

    }

    function prepareData(resp) {
        let result = [];
        resp.items.forEach(e => fillArray(e, result));
        fillArray(resp.total, result);
        return result;
    }

    function fillArray(e, result) {
        let first = e.first;
        first.country = "";
        // first.period = fromFirst + "-" + toFirst;
        first.period = 'period1'
        result.push(e.first)
        let second = e.second;
        second.country = e.country;
        // second.period = fromSecond + "-" + toSecond;
        second.period = 'period2'
        result.push(e.second)
        let deltas = e.deltas;
        deltas.country = "";
        deltas.period = "difference";
        result.push(deltas);
    }

    function cellRenderer(rowData) {
        let index = rowData.tableData.id;
        return <div>
            {index > 1 ? "difference" : "period" + (++index)}
        </div>
    }

    function percentFormat(scale, params) {
        if (typeof params === "number") {
            return parseFloat((params).toFixed(scale)).toLocaleString() + '%';
        }
        return params;
    }

    function getCellContent(value, rowData, isDigit) {
        let isFooter = rowData.period === 'difference';
        let background = isDigit && isFooter ? (value < 0 ? '#FF9E80' : '#B9F6CA') : null;
        let formattedValue = isDigit && isFooter ? percentFormat(2, value) : value;
        return <div
            style={{
                background: background,
                padding: '8px',
                // borderBottom:border
            }}
        >
            {formattedValue}
        </div>
    }

    return (
        <React.Fragment>
            <CssBaseline/>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Container maxWidth={false}>
                <form className={classes.form} onSubmit={handleSubmit(onSearch)}>
                    <Grid container spacing={3}>
                        <Grid item sm={12} md={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={appCode}
                                    onChange={(event, newValue) => {
                                        setAppCode(newValue);
                                    }}
                                    options={appOptions}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="App"
                                                                        variant="standard"/>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} md={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    value={platform}
                                    onChange={(event, newValue) => {
                                        setPlatform(newValue);
                                    }}
                                    options={platformOptions}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Platform"
                                                                        variant="standard"/>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    multiple
                                    value={firstSource}
                                    onChange={(event, newValue) => {
                                        setFirstSource(newValue);
                                    }}
                                    options={sourceOptions}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="First source"
                                                                        variant="standard"/>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    multiple
                                    value={secondSource}
                                    onChange={(event, newValue) => {
                                        setSecondSource(newValue);
                                    }}
                                    options={sourceOptions}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Second source"
                                                                        variant="standard"/>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    multiple
                                    value={country}
                                    onChange={(event, newValue) => {
                                        setCountry(newValue);
                                    }}
                                    options={countryOptions}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Country"
                                                                        variant="standard"/>}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <FormControl fullWidth>
                                <MaterialDateRange name="First period" style={{width: "100%"}}
                                                   onDatePickerChange={onFirstDatePickerChange}/>
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <FormControl fullWidth>
                                <MaterialDateRange name="Second period" style={{width: "100%"}}
                                                   onDatePickerChange={onSecondDatePickerChange}/>
                            </FormControl>
                        </Grid>
                        <Grid item sm={12} md={2}>
                            <Button type={"submit"} color="primary">Search</Button>
                        </Grid>
                        <input type="hidden" ref={register} name="appCode" value={appCode.value}/>
                        <input type="hidden" ref={register} name="platform" value={platform.value}/>
                        <input type="hidden" ref={register} name="firstSources"
                               value={firstSource.map(item => item.value)}/>
                        <input type="hidden" ref={register} name="secondSources"
                               value={secondSource.map(item => item.value)}/>
                        <input type="hidden" ref={register} name="countries" value={country.map(item => item.value)}/>
                        <input type="hidden" ref={register} name="from" value={fromFirst + "," + fromSecond}/>
                        <input type="hidden" ref={register} name="to" value={toFirst + "," + toSecond}/>
                    </Grid>
                </form>
                <Paper>
                    <MaterialTable
                        icons={tableIcons}
                        columns={[
                            {
                                title: "Country", field: "country", align: "center", width: 100, cellStyle: {
                                    borderBottom: '0px',
                                    borderRight: '1px black',
                                    padding: '0px'
                                },
                                render: rowData => getCellContent(rowData.country, rowData, false)
                            },
                            {
                                title: "Period", field: "period", align: "right", width: 100,
                                render: rowData => getCellContent(rowData.period, rowData, false)
                            },
                            {
                                title: "Impr",
                                field: "impressions",
                                align: "right",
                                width: 110,
                                render: rowData => getCellContent(rowData.impressions, rowData, true)
                            },
                            {
                                title: "Page views", field: "page_views", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.page_views, rowData, true)
                            },
                            {
                                title: "Installs", field: "installs", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.installs, rowData, true)
                            },
                            {
                                title: "CRV inst/impr", field: "cvr_ii", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.cvr_ii, rowData, true)
                            },
                            {
                                title: "CRV views/impr", field: "cvr_pi", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.cvr_pi, rowData, true)
                            },
                            {
                                title: "CRV inst/views", field: "cvr_ip", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.cvr_ip, rowData, true)
                            },
                            {
                                title: "Retention d1", field: "ret1", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.ret1, rowData, true)
                            },
                            {
                                title: "ARPI d1", field: "arpi1", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.arpi1, rowData, true)
                            },
                            {
                                title: "LTV", field: "ltv", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.ltv, rowData, true)
                            },
                            {
                                title: "payments d1", field: "payments1", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.payments1, rowData, true)
                            },
                            {
                                title: "Revenue d1", field: "rev1", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.rev1, rowData, true)
                            },
                            {
                                title: "payers % d1", field: "payers1", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.payers1, rowData, true)
                            },
                            {
                                title: "Retention d3", field: "ret3", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.ret3, rowData, true)
                            },
                            {
                                title: "Retention d7", field: "ret7", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.ret7, rowData, true)
                            },
                            {
                                title: "Retention d14", field: "ret14", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.ret14, rowData, true)
                            },
                            {
                                title: "Retention d30", field: "ret30", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.ret30, rowData, true)
                            },
                            {
                                title: "Revenue d3", field: "rev3", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.rev3, rowData, true)
                            },
                            {
                                title: "Revenue d7", field: "rev7", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.rev7, rowData, true)
                            },
                            {
                                title: "Revenue d14", field: "rev14", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.rev14, rowData, true)
                            },
                            {
                                title: "Revenue d30", field: "rev30", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.rev30, rowData, true)
                            },
                            {
                                title: "ARPI d3", field: "arpi3", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.arpi3, rowData, true)
                            },
                            {
                                title: "ARPI d7", field: "arpi7", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.arpi7, rowData, true)
                            },
                            {
                                title: "ARPI d14", field: "arpi14", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.arpi14, rowData, true)
                            },
                            {
                                title: "ARPI d30", field: "arpi30", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.arpi30, rowData, true)
                            },
                            {
                                title: "payers % d3", field: "payers3", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.payers3, rowData, true)
                            },
                            {
                                title: "payers % d7", field: "payers7", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.payers7, rowData, true)
                            },
                            {
                                title: "payers % d14", field: "payers14", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.payers14, rowData, true)
                            },
                            {
                                title: "payments d3 ", field: "payments3", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.payments3, rowData, true)
                            },
                            {
                                title: "payments d7", field: "payments7", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.payments7, rowData, true)
                            },
                            {
                                title: "payments d14", field: "payments14", align: "right", width: 110,
                                render: rowData => getCellContent(rowData.payments14, rowData, true)
                            }
                        ]}
                        data={data}
                        title="Comparative"
                        options={{
                            cellStyle: {padding: '0px'},
                            paging: false
                        }}
                    />
                </Paper>
            </Container>
        </React.Fragment>
    )
}
