import React, {useEffect} from "react";
import {Backdrop, Button, Card, CardContent, CircularProgress, TextField} from '@material-ui/core';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import {makeStyles} from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useForm} from 'react-hook-form'
import Snackbar from "../../../components/Snackbar/Snackbar";
import AddAlert from "@material-ui/icons/AddAlert";
import {get, put} from "../../../common/ApiUtils";
import Description from "./Description";
import {CatalogApi} from "../../../api/CatalogApi";


const useStyles = makeStyles((theme) => ({
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


export default function TikTokUpdateTab() {
    const classes = useStyles();

    const [audience, setAudience] = React.useState('');
    const [audienceOptions, setAudienceOptions] = React.useState([]);
    const [appOptions, setAppOptions] = React.useState([]);
    const [appId, setAppId] = React.useState('');
    const [query, setQuery] = React.useState({filters: []});
    const [queryOptions, setQueryOptions] = React.useState([]);
    const {register, handleSubmit} = useForm()
    const [tl, setTL] = React.useState(false);
    const [tr, setTR] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);


    useEffect(() => {
        fetchData();
    }, [])

    function showLoading() {
        setLoading(true);
    }

    function hideLoading() {
        setLoading(false);
    }

    function loadAudiencesByAppId(appId) {
        setAudience('')
        setAudienceOptions([])
        get("/api/tiktok/audiences?appId=" + appId, {credentials: "include"})
            .then(resp => {
                const audienceOptions = [];
                resp.body.forEach(elem => {
                    audienceOptions.push({
                        value: elem.id,
                        label: elem.name,
                        advertiserId: elem.advertiserId,
                        id: "audience"
                    })
                });
                setAudienceOptions(audienceOptions);
                setAudience(audienceOptions[0]);
            });
    }

    async function fetchData() {
        CatalogApi.getMainAppWithPlatformOptions().then(apps => {
            setAppOptions(apps);
            setAppId(apps[0])
            loadAudiencesByAppId(apps[0].value);
        })
        CatalogApi.getAutoUpdateAudienceQueryOptions().then(options => {
            setQuery(options[0]);
            setQueryOptions(options);
        });
    }

    function showNotification(place) {
        switch (place) {
            case "tl":
                setTL(true);
                break;
            case "tr":
                setTR(true);
                break;
            default:
                break;
        }
    }

    function getBody(data) {
        let filters = {}
        query.filters.forEach(filter => {
            if (filter.name === "eventDate" || filter.name === "installDate") {
                filters.startDate = data.startDate
                filters.endDate = data.endDate
            } else if (filter.name === "appId") {
                filters.appId = appId.value;
            } else {
                let value = data[filter.name].value;
                if (value) {
                    filters[filter.name] = value
                } else {
                    filters[filter.name] = data[filter.name]
                }
            }
        })
        return {
            advertiserId: audience.advertiserId,
            audienceId: audience.value,
            queryId: query.value,
            filters: filters
        };
    }


    function onAudienceUpload(data) {
        showLoading();
        const body = getBody(data)

        put("/api/tiktok/audience", body).then(async response => {
            let status = response.status;
            if (status === 200) {
                setSuccessMessage("Audience successfully updated")
                showNotification("tl");
            } else {
                setErrorMessage(JSON.stringify(response));
                showNotification("tr");
            }
        }).catch(error => {
            setErrorMessage(JSON.stringify(error.body));
            showNotification("tr");
        }).finally(() => hideLoading());
    }

    const formInvalid = () => {
        let formValid = !!appId && !!query && !!audience;

        return !formValid
    }

    return (
        <div>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Card>
                <CardContent>
                    <form className={classes.form} noValidate onSubmit={handleSubmit(onAudienceUpload)}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    value={appId}
                                    onChange={(event, newValue) => {
                                        setAppId(newValue);
                                        loadAudiencesByAppId(newValue.value)
                                    }}
                                    options={appOptions}
                                    getOptionLabel={(option) => option.label || ''}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="App"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={8}>
                                <Autocomplete
                                    value={audience}
                                    onChange={(event, newValue) => {
                                        setAudience(newValue);
                                    }}
                                    options={audienceOptions}
                                    getOptionLabel={(option) => option.label || ''}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Audience"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    value={query}
                                    onChange={(event, newValue) => {
                                        setQuery(newValue);
                                    }}
                                    options={queryOptions}
                                    getOptionLabel={(option) => option.label || ''}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Query"
                                                                        variant="standard"/>}
                                />
                            </GridItem>

                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={3}>
                                <Button type={"submit"} variant="contained" color="primary"
                                        disabled={formInvalid()}>Upload</Button>
                                <Snackbar
                                    place="tl"
                                    color="success"
                                    icon={AddAlert}
                                    message={successMessage}
                                    open={tl}
                                    closeNotification={() => setTL(false)}
                                    close
                                />
                                <Snackbar
                                    place="tr"
                                    color="error"
                                    icon={AddAlert}
                                    message={errorMessage}
                                    open={tr}
                                    closeNotification={() => setTR(false)}
                                    close
                                />
                            </GridItem>
                        </GridContainer>
                    </form>
                </CardContent>
            </Card>
            <Description/>
        </div>
    );
}