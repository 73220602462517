import moment from 'moment';

export const emptyFbTest = {
    "id": 0,
    "tests": [
        {
            "id": -1,
            "network": "FAN Female",
            "startDate": moment().subtract(3, 'days'),
            "endDate": moment().subtract(2, 'days'),
            "control": {
                "id": 0,
                "country": "",
                "adName": "",
                "spend": "",
                "impressions": "",
                "clicks": "",
                "installs": ""
            },
            "variation": {
                "id": 0,
                "country": "",
                "adName": "",
                "spend": "",
                "impressions": "",
                "clicks": "",
                "installs": ""
            },
            "result": ""
        },
        {
            "id": -1,
            "network": "FEED Female",
            "startDate": moment().subtract(3, 'days'),
            "endDate": moment().subtract(2, 'days'),
            "control": {
                "id": 0,
                "country": "",
                "adName": "",
                "spend": "",
                "impressions": "",
                "clicks": "",
                "installs": ""
            },
            "variation": {
                "id": 0,
                "country": "",
                "adName": "",
                "spend": "",
                "impressions": "",
                "clicks": "",
                "installs": ""
            },
            "result": ""
        },
        {
            "id": -1,
            "network": "FEED Male",
            "startDate": moment().subtract(3, 'days'),
            "endDate": moment().subtract(2, 'days'),
            "control": {
                "id": 0,
                "country": "",
                "adName": "",
                "spend": "",
                "impressions": "",
                "clicks": "",
                "installs": ""
            },
            "variation": {
                "id": 0,
                "country": "",
                "adName": "",
                "spend": "",
                "impressions": "",
                "clicks": "",
                "installs": ""
            },
            "result": ""
        }
    ]
}

export const emptyGATest = {
    "id": 0,
    "tests": [
        {
            "id": -1,
            "network": "AdMob",
            "startDate": moment().subtract(3, 'days'),
            "endDate": moment().subtract(2, 'days'),
            "control": {
                "id": 0,
                "country": "",
                "adName": "",
                "spend": "",
                "impressions": "",
                "clicks": "",
                "installs": ""
            },
            "variation": {
                "id": 0,
                "country": "",
                "adName": "",
                "spend": "",
                "impressions": "",
                "clicks": "",
                "installs": ""
            },
            "result": ""
        },
        {
            "id": -1,
            "network": "Youtube",
            "startDate": moment().subtract(3, 'days'),
            "endDate": moment().subtract(2, 'days'),
            "control": {
                "id": 0,
                "country": "",
                "adName": "",
                "spend": "",
                "impressions": "",
                "clicks": "",
                "installs": ""
            },
            "variation": {
                "id": 0,
                "country": "",
                "adName": "",
                "spend": "",
                "impressions": "",
                "clicks": "",
                "installs": ""
            },
            "result": ""
        }
    ]
}

export const emptyISTest = {
    "id": 0,
    "tests": [
        {
            "id": -1,
            "network": "Ironsource",
            "startDate": moment().subtract(3, 'days'),
            "endDate": moment().subtract(2, 'days'),
            "control": {
                "id": 0,
                "country": "",
                "adName": "",
                "spend": "",
                "impressions": "",
                "clicks": "",
                "installs": ""
            },
            "variation": {
                "id": 0,
                "country": "",
                "adName": "",
                "spend": "",
                "impressions": "",
                "clicks": "",
                "installs": ""
            },
            "result": ""
        }
    ]
}

export const emptyLiftoffTest = {
    "id": 0,
    "tests": [
        {
            "id": -1,
            "network": "Liftoff",
            "startDate": moment().subtract(3, 'days'),
            "endDate": moment().subtract(2, 'days'),
            "control": {
                "id": 0,
                "country": "",
                "adName": "",
                "spend": "",
                "impressions": "",
                "clicks": "",
                "installs": ""
            },
            "variation": {
                "id": 0,
                "country": "",
                "adName": "",
                "spend": "",
                "impressions": "",
                "clicks": "",
                "installs": ""
            },
            "result": ""
        }
    ]
}

export const emptyUnityTest = {
    "id": 0,
    "tests": [
        {
            "id": -1,
            "network": "Unity Ads",
            "startDate": moment().subtract(3, 'days'),
            "endDate": moment().subtract(2, 'days'),
            "control": {
                "id": 0,
                "country": "",
                "adName": "",
                "spend": "",
                "impressions": "",
                "clicks": "",
                "installs": ""
            },
            "variation": {
                "id": 0,
                "country": "",
                "adName": "",
                "spend": "",
                "impressions": "",
                "clicks": "",
                "installs": ""
            },
            "result": ""
        }
    ]
}

export const emptyLiveTest = {
    "id": 0,
    "tests": [
        {
            "id": -1,
            "network": "FEED Live",
            "startDate": moment().subtract(3, 'days'),
            "endDate": moment().subtract(2, 'days'),
            "control": {
                "id": 0,
                "country": "",
                "adName": "",
                "spend": "0",
                "impressions": "0",
                "clicks": "0",
                "installs": "0"
            },
            "variation": {
                "id": 0,
                "country": "",
                "adName": "",
                "spend": "0",
                "impressions": "0",
                "clicks": "0",
                "installs": "0"
            },
            "result": "-"
        },
        // {
        //     "id": -1,
        //     "network": "FAN Live",
        //     "startDate": moment().subtract(3, 'days'),
        //     "endDate": moment().subtract(2, 'days'),
        //     "control": {
        //         "id": 0,
        //         "country": "",
        //         "adName": "",
        //         "spend": "0",
        //         "impressions": "0",
        //         "clicks": "0",
        //         "installs": "0"
        //     },
        //     "variation": {
        //         "id": 0,
        //         "country": "",
        //         "adName": "",
        //         "spend": "0",
        //         "impressions": "0",
        //         "clicks": "0",
        //         "installs": "0"
        //     },
        //     "result": "-"
        // },
        // {
        //     "id": -1,
        //     "network": "AdMob Live",
        //     "startDate": moment().subtract(3, 'days'),
        //     "endDate": moment().subtract(2, 'days'),
        //     "control": {
        //         "id": 0,
        //         "country": "",
        //         "adName": "",
        //         "spend": "0",
        //         "impressions": "0",
        //         "clicks": "0",
        //         "installs": "0"
        //     },
        //     "variation": {
        //         "id": 0,
        //         "country": "",
        //         "adName": "",
        //         "spend": "0",
        //         "impressions": "0",
        //         "clicks": "0",
        //         "installs": "0"
        //     },
        //     "result": "-"
        // },
        // {
        //     "id": -1,
        //     "network": "YouTube Live",
        //     "startDate": moment().subtract(3, 'days'),
        //     "endDate": moment().subtract(2, 'days'),
        //     "control": {
        //         "id": 0,
        //         "country": "",
        //         "adName": "",
        //         "spend": "0",
        //         "impressions": "0",
        //         "clicks": "0",
        //         "installs": "0"
        //     },
        //     "variation": {
        //         "id": 0,
        //         "country": "",
        //         "adName": "",
        //         "spend": "0",
        //         "impressions": "0",
        //         "clicks": "0",
        //         "installs": "0"
        //     },
        //     "result": "-"
        // },
        // {
        //     "id": -1,
        //     "network": "Unity Ads Live",
        //     "startDate": moment().subtract(3, 'days'),
        //     "endDate": moment().subtract(2, 'days'),
        //     "control": {
        //         "id": 0,
        //         "country": "",
        //         "adName": "",
        //         "spend": "0",
        //         "impressions": "0",
        //         "clicks": "0",
        //         "installs": "0"
        //     },
        //     "variation": {
        //         "id": 0,
        //         "country": "",
        //         "adName": "",
        //         "spend": "0",
        //         "impressions": "0",
        //         "clicks": "0",
        //         "installs": "0"
        //     },
        //     "result": "-"
        // }
    ]
}

export const zeroStub = {
    id: 0,
    country: "",
    adName: "",
    spend: 0,
    impressions: 0,
    clicks: 0,
    installs: 0,
}

export const emptyRow = (id) => {
    return {
        id: id,
        admob: "-",
        admobLive: "-",
        control: "",
        endDate: "",
        fan: "-",
        fanLive: "-",
        feed: "-",
        feedLive: "-",
        is: "-",
        liftoff: "-",
        startDate: "",
        unity: "-",
        unityLive: "-",
        variation: "",
        youtube: "-",
        youtubeLive: "-"
    }
}