import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';

export default function StopCampaignDialog(props) {

    const [campaign, setCampaign] = React.useState(null);

    const closeWithSubmit = () => {
        if (!!campaign) {
            props.handleClose({campaign: campaign, testId: props.testToStop.testId})
            setCampaign(null)
        }
    }

    const closeWithoutSubmit = () => {
        setCampaign(null)
        props.handleClose()
    }

    if (!props.testToStop) {
        return null;
    }

    return (
        <Dialog fullWidth={true}
                maxWidth="sm"
                open={props.open}
                onClose={() => closeWithoutSubmit()}
                aria-labelledby="responsive-dialog-title">

            <DialogTitle id="responsive-dialog-title">Stop Campaign</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Autocomplete fullWidth
                                      options={props.testToStop.campaigns.map(data => ({
                                          title: data.campaignName,
                                          value: data.campaignId
                                      }))}
                                      value={campaign}
                                      getOptionLabel={(option) => option.title}
                                      onChange={(event, option) => setCampaign(option)}
                                      renderInput={(params) => <TextField {...params} label="Campaign"/>}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={() => closeWithoutSubmit()} color="primary">Cancel</Button>
                <Button onClick={() => closeWithSubmit()} color="primary" autoFocus>Stop</Button>
            </DialogActions>
        </Dialog>
    );
}