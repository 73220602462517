import React from 'react';
import '../app/App.css';
import {withCookies} from 'react-cookie';
import {withRouter} from 'react-router-dom';
import {ReportApi} from "../api/ReportApi";
import Report from "./common/Report";
import {
    CREATIVE_REPORT_COLUMNS,
    CREATIVE_REPORT_DEFAULT_GROUPINGS,
    CREATIVE_REPORT_DEFAULT_SORTING_MODEL,
    CREATIVE_REPORT_FILTERS_CONFIG
} from "./CreativeReportConfig";
import AppNavbar from "../navigation/AppNavbar";


class CreativeReport extends React.Component {

    componentDidMount() {
        document.title = 'BI Creative';
    }

    render() {
        return (
            <React.Fragment>
                <AppNavbar authorities={this.props.authorities}/>
                <Report authorities={this.props.authorities}
                        profileKey="CREATIVE_REPORT"
                        filterDefs={CREATIVE_REPORT_FILTERS_CONFIG}
                        gridColumnDefs={CREATIVE_REPORT_COLUMNS}
                        gridDefaultGroupings={CREATIVE_REPORT_DEFAULT_GROUPINGS}
                        gridDefaultSortingModel={CREATIVE_REPORT_DEFAULT_SORTING_MODEL}
                        loadFunc={ReportApi.getCreativeData}
                        exportFunc={ReportApi.exportCreativeData}
                />
            </React.Fragment>
        );
    }
}

export default withCookies(withRouter(CreativeReport));
