/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
// core components
import BarChartIcon from '@material-ui/icons/BarChart';
import styles from "assets/jss/material-dashboard-react/components/sidebarStyle.js";
import { NavbarBrand } from "reactstrap";
import ListItemIcon from "@material-ui/core/IconButton";
import ViewListIcon from '@material-ui/icons/ViewList';
import clsx from "clsx";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
    const classes = useStyles();
    const [openMenu, setOpenMenu] = React.useState(false);

    const handleClick = () => {
        setOpenMenu(!openMenu);
    };

    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName) {
        return window.location.href.indexOf(routeName) > -1;
    }

    const {color, logo, image, logoText, routes} = props;
    let links = (
        <List className={classes.list}>
            {routes.map((prop, key) => {
                let activePro = " ";
                let listItemClasses;
                if (prop.path === "/upgrade-to-pro") {
                    activePro = classes.activePro + " ";
                    listItemClasses = classNames({
                        [" " + classes[color]]: true
                    });
                } else {
                    listItemClasses = classNames({
                        [" " + classes[color]]: activeRoute(prop.layout + prop.path)
                    });
                }
                const whiteFontClasses = classNames({
                    [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
                });

                function getItem(item) {
                    const to = item ? item.layout + item.path : prop.layout + prop.path;
                    const name = item ? item.name : prop.name;
                    const icon = item ? item.icon : prop.icon;
                    return <NavLink
                        to={to}
                        className={activePro + classes.item}
                        activeClassName="active"
                        key={key}>
                        <ListItem key={key} button className={classes.itemLink + listItemClasses}>
                            {typeof icon === "string" ? (
                                <Icon
                                    className={classNames(classes.itemIcon, whiteFontClasses, {
                                        [classes.itemIconRTL]: props.rtlActive
                                    })}
                                >
                                    {icon}
                                </Icon>
                            ) : (
                                <prop.icon
                                    className={classNames(classes.itemIcon, whiteFontClasses, {
                                        [classes.itemIconRTL]: props.rtlActive
                                    })}
                                />
                            )}
                            <ListItemText
                                primary={name}
                                className={classNames(classes.itemText, whiteFontClasses, {
                                    [classes.itemTextRTL]: props.rtlActive
                                })}
                                disableTypography={true}
                            />
                        </ListItem>
                    </NavLink>
                }

                return (
                    prop.childs && prop.childs.length > 0
                        ? <div>
                            <ListItem button onClick={handleClick}>
                                <ListItemIcon>
                                    <ViewListIcon className={classNames(classes.itemIcon, whiteFontClasses, {
                                        [classes.itemIconRTL]: props.rtlActive
                                    })}/>
                                </ListItemIcon>
                                <ListItemText primary={props.rtlActive ? prop.rtlName : prop.name}
                                              className={classNames(classes.itemText, whiteFontClasses, {
                                                  [classes.itemTextRTL]: props.rtlActive
                                              })}
                                              disableTypography={true}/>
                                {open ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>
                            <Collapse in={openMenu} classes={classNames(classes.itemIcon, whiteFontClasses, {
                                [classes.itemIconRTL]: props.rtlActive
                            })} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {prop.childs.map(item => getItem(item))}
                                </List>
                            </Collapse>
                        </div>
                        : getItem()
                );
            })}
        </List>
    );
    var brand = (
        <span className={classes.logo}>
            <NavbarBrand tag={Link} to="/">
                <span
                    className={classNames(classes.logoLink, {
                        [classes.logoLinkRTL]: props.rtlActive
                    })}>
                    <span className={classes.logoImage}>
                        <img src={logo} alt="logo" className={classes.img}/>
                    </span>
                    Dashboard
                </span>
            </NavbarBrand>
        </span>
    );
    return (
        <div>
            <Hidden smDown implementation="css">
                <Drawer
                    anchor="left"
                    variant="persistent"
                    open={props.open}
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                >
                    {brand}
                    <div className={classes.sidebarWrapper}>{links}</div>
                    {image !== undefined ? (
                        <div
                            className={classes.background}
                            style={{backgroundImage: "url(" + image + ")"}}
                        />
                    ) : null}
                </Drawer>
            </Hidden>
        </div>
    );
}

Sidebar.propTypes = {
    rtlActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
    logo: PropTypes.string,
    image: PropTypes.string,
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool
};
