import React, {useEffect, useRef} from "react";
import {HotColumn, HotTable} from '@handsontable/react';
import {
    Backdrop,
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    Grid,
    Snackbar,
    TextField
} from '@material-ui/core';
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import moment from 'moment'
import {makeStyles} from "@material-ui/core/styles";
import {Autocomplete, Pagination} from '@material-ui/lab';
import {APPS_OPTIONS, TEST_RESULT_OPTIONS} from "./AbTest";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import AddCreativeDialog from "./AddCreativeDialog";
import {get, post} from "../../common/ApiUtils";
import MuiAlert from '@material-ui/lab/Alert';
import StopCampaignDialog from "./StopCampaignDialog";
import {CreativeNameRenderer} from "./AbTestUtils";

const useStyles = makeStyles((_theme) => ({
    root: {
        'margin-top': '1rem',
    },
    backdrop: {
        zIndex: _theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const PAGE_SIZE = 5;

export default function ActiveTests(props) {

    const classes = useStyles();
    const [app, setApp] = React.useState(null);
    const [startDate, setStartDate] = React.useState(moment().startOf('week').subtract(4, 'week'));
    const [endDate, setEndDate] = React.useState(moment().endOf('day'));
    const [creative, setCreative] = React.useState();
    const [campaignName, setCampaignName] = React.useState();
    const [geo, setGeo] = React.useState();
    const [fanTestResult, setFanTestResult] = React.useState();
    const [feedTestResult, setFeedTestResult] = React.useState();
    const [fanBenchCreative, setFanBenchCreative] = React.useState();
    const [feedBenchCreative, setFeedBenchCreative] = React.useState();
    const [CREATIVES_OPTIONS, setCreativesOptions] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [allTests, setAllTests] = React.useState([]);
    const [testsPage, setTestsPage] = React.useState([]);
    const [testToSubmit, setTestToSubmit] = React.useState();
    const [testToModify, setTestToModify] = React.useState();
    const [testToStop, setTestToStop] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState(null);
    const [successMessage, setSuccessMessage] = React.useState(null);
    const itemEls = useRef({})

    // eslint-disable-next-line
    useEffect(() => {
        loadCreativesOptions(app);
        loadAllTests();
    }, [])

    useEffect(() => loadTestsForPage(1), [allTests]);

    const loadCreativesOptions = (app) => {
        get('/api/facebook-ab-test/creative-names-by-app' + (app ? `?app=${app}` : '')).then(resp => setCreativesOptions(resp.body))
    }

    const loadAllTests = () => {
        const body = {
            status: 'INITIALIZED',
            app, startDate, endDate, creative, geo, fanTestResult, feedTestResult, fanBenchCreative, feedBenchCreative,
            campaignName: !!campaignName ? campaignName : null
        }

        setLoading(true);
        post('/api/facebook-ab-test/grid', body)
            .then(response => setAllTests(response.body))
            .finally((_) => {
                setLoading(false)
            })
    }

    const loadTestsForPage = (pageNumber) => {
        const page = allTests.slice(PAGE_SIZE * (pageNumber - 1), PAGE_SIZE * pageNumber);
        setTestsPage(page)
        setCurrentPage(pageNumber)
    }

    const approve = (confirmed) => {
        if (confirmed) {
            post(`/api/facebook-ab-test/${testToSubmit.testId}/submit`).then(_ => loadAllTests())
        }
        setTestToSubmit(null)
    }

    const addCreative = (result) => {
        setTestToModify(null)

        if (result) {
            const requestParams = new URLSearchParams({
                testId: result.testId,
                campaignId: result.campaignId,
                creative: result.creative
            })
            setLoading(true)
            post(`/api/facebook-ab-test/add-creative?` + requestParams)
                .then(_ => {
                    setErrorMessage(null)
                    setSuccessMessage('Creative added')
                    loadAllTests()
                })
                .catch(response => setErrorMessage(response.body.message))
                .finally((_) => {
                    setLoading(false)
                })
        }
    }

    const stopCampaign = (result) => {
        if (result) {
            const requestParams = new URLSearchParams({
                testId: result.testId,
                campaignId: result.campaign.value
            })
            post(`/api/facebook-ab-test/stop-campaign?` + requestParams)
                .then(_ => {
                    setErrorMessage(null)
                    setSuccessMessage(`Campaign ${result.campaign.title} stopped`)
                    loadAllTests()
                })
                .catch(response => setErrorMessage(response.body.message))
        }
        setTestToStop(null)
    }

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setErrorMessage(null);
        setSuccessMessage(null);
    };

    function onRowUpdate(changes, source, test) {
        if (source === 'edit' && changes) {
            changes.forEach(([row, prop, oldValue, newValue]) => {
                if (prop === 'note') {
                    const creativeId = itemEls.current[test.testId].hotInstance.getDataAtRowProp(row, 'creativeId')
                    if (!!creativeId) {
                        const body = {
                            testId: test.testId,
                            creativeId: creativeId,
                            note: newValue
                        }
                        post('/api/facebook-ab-test/note', body)
                            .then(_ => setSuccessMessage(`Note saved`))
                            .catch(response => setErrorMessage(response.body.message))
                    }
                }
            })
        }
    }

    return (
        <React.Fragment>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Card>
                <CardContent>
                    <h5>Filters</h5>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <Autocomplete fullWidth options={Object.keys(APPS_OPTIONS)}
                                          value={app}
                                          getOptionLabel={(option) => APPS_OPTIONS[option]}
                                          onChange={(event, option) => {
                                              loadCreativesOptions(option)
                                              setFanBenchCreative(null)
                                              setFeedBenchCreative(null)
                                              setApp(option)
                                          }}
                                          disableClearable
                                          renderInput={(params) => <TextField {...params} label="Title name"/>}
                            />
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth>
                                <DateRangePicker
                                    onApply={(event, picker) => {
                                        setStartDate(picker.startDate)
                                        setEndDate(picker.endDate)
                                    }}
                                    initialSettings={{
                                        startDate: startDate,
                                        endDate: endDate,
                                    }}
                                    alwaysShowCalendars={true}>
                                    <div className="form-group">
                                        <TextField id="standard-basic" style={{width: "100%"}} readOnly
                                                   label="Date range"
                                                   value={startDate.format('MM/DD/YYYY') + ' - ' + endDate.format('MM/DD/YYYY')}/>
                                    </div>
                                </DateRangePicker>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Autocomplete fullWidth options={CREATIVES_OPTIONS}
                                          value={creative}
                                          onChange={(event, newValue) => setCreative(newValue)}
                                          renderInput={(params) => <TextField {...params}
                                                                              label="Creative"/>}/>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <TextField fullWidth label="Campaign name"
                                       value={campaignName}
                                       onChange={(event) => setCampaignName(event.target.value)}/>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Autocomplete fullWidth options={TEST_RESULT_OPTIONS}
                                          value={fanTestResult}
                                          onChange={(event, newValue) => setFanTestResult(newValue)}
                                          renderInput={(params) => <TextField {...params}
                                                                              label="FAN Test Result"/>}/>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Autocomplete fullWidth options={TEST_RESULT_OPTIONS}
                                          value={feedTestResult}
                                          onChange={(event, newValue) => setFeedTestResult(newValue)}
                                          renderInput={(params) => <TextField {...params}
                                                                              label="FEED Test Result"/>}/>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Autocomplete fullWidth options={CREATIVES_OPTIONS}
                                          value={fanBenchCreative}
                                          onChange={(event, newValue) => setFanBenchCreative(newValue)}
                                          renderInput={(params) => <TextField {...params}
                                                                              label="FAN Bench creative"/>}/>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Autocomplete fullWidth options={CREATIVES_OPTIONS}
                                          value={feedBenchCreative}
                                          onChange={(event, newValue) => setFeedBenchCreative(newValue)}
                                          renderInput={(params) => <TextField {...params}
                                                                              label="Feed Bench creative"/>}/>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <Autocomplete fullWidth options={props.countryOptions}
                                          getOptionDisabled={(option) => option === '--'}
                                          value={geo}
                                          onChange={(event, newValue) => setGeo(newValue)}
                                          renderInput={(params) => <TextField {...params} label="GEO"/>}/>
                        </Grid>
                    </Grid>
                    <div style={{marginTop: '1rem'}}>
                        <Button variant="contained" color="primary" onClick={() => loadAllTests()}>Find</Button>
                    </div>
                </CardContent>
            </Card>

            {testsPage.map((test) =>
                <Card key={test.testId} className={classes.root}>
                    <CardContent>
                        <h5>Test
                            #{test.testId}: {new Date(test.testStartDate).toLocaleDateString()}</h5>

                        <div style={{textAlign: 'left'}}>
                            <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                <Button onClick={() => setTestToSubmit(test)}>Approve</Button>
                                <Button onClick={() => setTestToModify(test)}>Add creative</Button>
                                <Button onClick={() => setTestToStop(test)}>Stop campaign</Button>
                            </ButtonGroup>
                        </div>
                        <HotTable
                            data={test.campaigns}
                            nestedRows={true}
                            style={{textAlign: 'left'}}
                            fixedColumnsLeft={2}
                            ref={(element) => itemEls.current[test.testId] = element}
                            afterChange={(changes, source) => onRowUpdate(changes, source, test)}
                            height="auto"
                            licenseKey="non-commercial-and-evaluation">

                            <HotColumn title="Campaign name" data="campaignName" settings={{readOnly: true}}/>
                            <HotColumn title="Creative name" data="creativeName" width={180}
                                       settings={{readOnly: true}}>
                                <CreativeNameRenderer hot-renderer/>
                            </HotColumn>
                            <HotColumn title="FAN Test Result" data="fanTestResult" settings={{readOnly: true}}/>
                            <HotColumn title="Feed Test Result" data="feedTestResult" settings={{readOnly: true}}/>
                            <HotColumn title="CTR" data="ctr" settings={{readOnly: true, type: 'numeric'}}/>
                            <HotColumn title="IR" data="ir" settings={{readOnly: true, type: 'numeric'}}/>
                            <HotColumn title="IPM" data="ipm" settings={{readOnly: true, type: 'numeric'}}/>
                            <HotColumn title="CPI" data="cpi" settings={{readOnly: true, type: 'numeric'}}/>
                            <HotColumn title="CRetU1d" data="cretU1d" settings={{readOnly: true, type: 'numeric'}}/>
                            <HotColumn title="CPM" data="cpm" settings={{readOnly: true, type: 'numeric'}}/>
                            <HotColumn title="RPM 1d" data="rpm1d" settings={{readOnly: true, type: 'numeric'}}/>
                            <HotColumn title="RET 1d" data="ret1d" settings={{readOnly: true, type: 'numeric'}}/>
                            <HotColumn title="Asana Link" data="asanaLink" settings={{readOnly: true}}>
                                <LinkRenderer hot-renderer/>
                            </HotColumn>
                            <HotColumn title="GDrive link" data="driveLink" settings={{readOnly: true}}>
                                <LinkRenderer hot-renderer/>
                            </HotColumn>
                            {/*<HotColumn title="Note" width={300} data="note"/>*/}
                        </HotTable>
                    </CardContent>
                </Card>
            )}
            {
                allTests.length > 0 &&
                <div style={{marginTop: '1rem', 'display': 'flex', justifyContent: 'center'}}>
                    <Pagination count={Math.ceil(allTests.length / PAGE_SIZE)} page={currentPage}
                                onChange={(e, page) => loadTestsForPage(page)}
                                color="secondary"/>
                </div>
            }

            <ConfirmationDialog open={!!testToSubmit} onClose={approve}>
                Are you sure you want to approve this test?
            </ConfirmationDialog>
            <AddCreativeDialog open={!!testToModify}
                               testToModify={testToModify}
                               creativeOptions={CREATIVES_OPTIONS}
                               handleClose={addCreative}/>
            <StopCampaignDialog open={!!testToStop}
                                testToStop={testToStop}
                                handleClose={stopCampaign}/>

            <Snackbar open={!!errorMessage} autoHideDuration={18000} onClose={handleCloseSnackBar}>
                <MuiAlert severity="error">{errorMessage}</MuiAlert>
            </Snackbar>
            <Snackbar open={!!successMessage} autoHideDuration={9000} onClose={handleCloseSnackBar}>
                <MuiAlert severity="success">{successMessage}</MuiAlert>
            </Snackbar>

        </React.Fragment>
    );
}

export const LinkRenderer = function (props) {
    const value = props.value

    if (!!value) {
        const anchor = document.createElement('a');
        anchor.setAttribute('href', value);
        anchor.innerText = "Link";
        anchor.target = "_blank";
        props['TD'].innerHTML = '';
        props['TD'].appendChild(anchor);
    }
    return props.value
}
