import {
    AD_FCST360,
    AD_REVENUE,
    ARPPU,
    ARPU_SKAN,
    CAMPAIGN,
    CLICKS,
    CPI,
    CPP,
    CTR,
    DATE,
    FCST360,
    FULL_APP_NAME,
    IMPRESSIONS,
    INAPP_NET_REVENUE,
    INSTALLS,
    IPM,
    IR,
    LTV360,
    MEDIA_SOURCE,
    MONTH,
    NET_REVENUE_SKAN,
    NULL_CV_COUNT,
    NULL_CV_SHARE,
    PAYERS,
    ROAS,
    ROAS_FACT_SKAN,
    SPEND,
    TITLE,
    WEEK,
    ZERO_CV_COUNT,
    ZERO_CV_SHARE
} from "../common/ColumnDefs";
import {show} from "../common/Utils";
import {FilterDef} from "./common/ReportFiltersDef";

export const UAM_SKAN_ONLY_REPORT_COLUMNS = [
    MONTH, WEEK, TITLE, FULL_APP_NAME, DATE, MEDIA_SOURCE, CAMPAIGN, INSTALLS, IMPRESSIONS, CLICKS, SPEND,
    show(INAPP_NET_REVENUE), show(AD_REVENUE), NET_REVENUE_SKAN, show(PAYERS), CPI, show(FCST360), show(AD_FCST360), LTV360,
    ROAS, ROAS_FACT_SKAN, show(CPP), ARPU_SKAN, ARPPU, NULL_CV_SHARE, ZERO_CV_SHARE, ZERO_CV_COUNT, NULL_CV_COUNT, IPM, CTR, IR
]

export const UAM_SKAN_ONLY_REPORT_DEFAULT_GROUPINGS = [
    FULL_APP_NAME.colId, MONTH.colId, WEEK.colId, MEDIA_SOURCE.colId, CAMPAIGN.colId, DATE.colId
];

export const UAM_SKAN_ONLY_REPORT_DEFAULT_SORTING_MODEL = [
    {colId: MONTH.colId, sort: "asc"},
    {colId: WEEK.colId, sort: "asc"},
    {colId: DATE.colId, sort: "asc"},
    {colId: SPEND.colId, sort: "desc"}
]

export const UAM_SKAN_ONLY_REPORT_FILTERS_CONFIG = [
    FilterDef.TITLE,
    FilterDef.APP,
    FilterDef.MEDIA_SOURCE,
    FilterDef.INSTALL_DATE,
    FilterDef.CAMPAIGN,
]


