import {Label} from "reactstrap";
import Select from "react-select";
import React from "react";

export default function MultiSelect(props) {

    const handleMultiSelectChange = (selectedOptions, element) => {
        let result;
        if (element.option?.value === 'All') {
            result = [element.option];
        } else {
            let filteredOptions = selectedOptions?.filter(options => options.value !== 'All') || [];
            result = filteredOptions.length > 0 ? filteredOptions : DEFAULT_MULTISELECT_VALUE;
        }

        return props.onChange(result);
    };

    return (
        <div>
            {props.label && <Label>{props.label}</Label>}
            <Select
                id={props.id}
                name={props.id}
                options={props.options}
                value={props.value}
                onChange={handleMultiSelectChange}
                isMulti
            />
        </div>
    )
}

export const DEFAULT_MULTISELECT_VALUE = [
    {label: "All", value: "All"}
];