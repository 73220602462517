import jStat from 'jstat';

export function toPercent(value, scale) {
    return Number.isNaN(value) ? "-" : parseFloat((value * 100).toFixed(scale)).toLocaleString() + '%';
}

export function toDollars(value, scale) {
    return Number.isNaN(value) ? "-" : '$' + parseFloat((value).toFixed(scale)).toLocaleString();
}

export function toFloat(value, scale) {
    return Number.isNaN(value) ? "-" : parseFloat((value).toFixed(scale)).toLocaleString();
}



const normalBound = 1.64;

export function calculateCreativeMetrics(a) {
    let cpi = a.spend / a.installs;
    let ctr = a.clicks / a.impressions;
    let ir = a.installs / a.clicks;
    let cvr = a.installs / a.impressions;
    let cpm = a.spend / a.impressions * 1000;
    let variance = cvr / (1 - cvr);
    let waldLowCVR = cvr - normalBound * Math.sqrt(variance / a.impressions);
    let waldHighCVR = cvr + normalBound * Math.sqrt(variance / a.impressions);
    let wilsonLowCVR = (2 * a.impressions * cvr + Math.pow(normalBound, 2) - normalBound * Math.sqrt(Math.pow(normalBound, 2) + 4 * a.impressions * variance)) / (2 * (a.impressions + Math.pow(normalBound, 2)));
    let wilsonHighCVR = (2 * a.impressions * cvr + Math.pow(normalBound, 2) + normalBound * Math.sqrt(Math.pow(normalBound, 2) + 4 * a.impressions * variance)) / (2 * (a.impressions + Math.pow(normalBound, 2)));
    let ctrSigma = ctr * (1 - ctr);
    let irSigma = ir * (1 - ir);
    let cvrSigma = cvr * (1 - cvr);
    return {
        country: a.country,
        adName: a.adName,
        spend: a.spend,
        impressions: a.impressions,
        clicks: a.clicks,
        installs: a.installs,
        cpiText: toDollars(cpi, 2),
        cpi: cpi,
        ctrText: toPercent(ctr, 2),
        ctr: ctr,
        irText: toPercent(ir, 2),
        ir: ir,
        cvrText: toPercent(cvr, 2),
        cvr: cvr,
        cpmText: toDollars(cpm, 2),
        cpm: cpm,
        variance: toPercent(variance, 2),
        waldLowCVR: toPercent(waldLowCVR, 2),
        waldHighCVR: toPercent(waldHighCVR, 2),
        wilsonLowCVR: toPercent(wilsonLowCVR, 2),
        wilsonHighCVR: toPercent(wilsonHighCVR, 2),
        ctrSigma: toFloat(ctrSigma, 5),
        irSigma: toFloat(irSigma, 5),
        cvrSigma: toFloat(cvrSigma, 5),
    };
}

export function getRows(abTest)  {
    const rows = [];
    let result = calculateTestMetrics(abTest);
    let control = result.a
    let variation = result.b
    let currentResult = abTest && abTest.result !== "" ? abTest.result : result.testResult;
    control.result = currentResult;
    variation.result = currentResult;
    rows.push(control);
    rows.push(variation);
    return rows;
}

export function calculateTestMetrics(test) {
    let b = calculateCreativeMetrics(test.variation);
    let a = calculateCreativeMetrics(test.control);

    let ctrAux = (a.clicks + b.clicks) / (a.impressions + b.impressions);
    let ctrStat = (b.ctr - a.ctr) / Math.sqrt(ctrAux * (1 - ctrAux) * (1 / a.impressions + 1 / b.impressions));
    let ctrP = 2 * (1 - jStat.normal.cdf(Math.abs(ctrStat), 0, 1));
    let ctrDelta = b.ctr / a.ctr - 1;
    let ctrZCore90 = ctrP > (1 - 0.9) ? "NO" : "YES";
    let ctrZCore95 = ctrP > (1 - 0.95) ? "NO" : "YES";
    let ctrZCore99 = ctrP > (1 - 0.99) ? "NO" : "YES";
    let ctrResult = ctrZCore90 === "YES" ? (ctrDelta > 0 ? "good" : "bad") : "normal";


    let irAux = (a.installs + b.installs) / (a.clicks + b.clicks);
    let irStat = (b.ir - a.ir) / Math.sqrt(irAux * (1 - irAux) * (1 / a.clicks + 1 / b.clicks));
    let irP = 2 * (1 - jStat.normal.cdf(Math.abs(irStat), 0, 1));
    let irDelta = b.ir / a.ir - 1;
    let irZCore90 = irP > (1 - 0.9) ? "NO" : "YES";
    let irZCore95 = irP > (1 - 0.95) ? "NO" : "YES";
    let irZCore99 = irP > (1 - 0.99) ? "NO" : "YES";
    let irResult = irZCore90 === "YES" ? (irDelta > 0 ? "good" : "bad") : "normal";

    let cvrAux = (a.installs + b.installs) / (a.impressions + b.impressions);
    let cvrStat = (b.cvr - a.cvr) / Math.sqrt(cvrAux * (1 - cvrAux) * (1 / a.impressions + 1 / b.impressions));
    let cvrP = 2 * (1 - jStat.normal.cdf(Math.abs(cvrStat), 0, 1));
    let cvrDelta = b.cvr / a.cvr - 1;
    let cvrZCore90 = cvrP > (1 - 0.9) ? "NO" : "YES";
    let cvrZCore95 = cvrP > (1 - 0.95) ? "NO" : "YES";
    let cvrZCore99 = cvrP > (1 - 0.99) ? "NO" : "YES";
    let cvrResult = cvrZCore90 === "YES" ? (cvrDelta > 0 ? "good" : "bad") : "normal";

    let byBounds = (b.wilsonHighCVR < a.wilsonLowCVR && b.waldHighCVR < a.waldLowCVR) ? "bad" : ((b.wilsonLowCVR > a.wilsonHighCVR && b.waldLowCVR > a.waldHighCVR) ? "best" : "ambigous");
    let testResult = (a.impressions < 10000 || b.impressions < 10000) ? "no result" : (byBounds==="ambigous" ? cvrResult : byBounds)

    return {
        a: a,
        b: b,
        byBounds: byBounds,
        testResult: testResult,
        ctrZCore90: ctrZCore90,
        ctrZCore95: ctrZCore95,
        ctrZCore99: ctrZCore99,
        ctrAux: toFloat(ctrAux, 6),
        ctrDelta: toPercent(ctrDelta, 2),
        ctrResult: ctrResult,
        ctrStat: toFloat(ctrStat, 2),
        ctrP: toPercent(ctrP, 2),
        irZCore90: irZCore90,
        irZCore95: irZCore95,
        irZCore99: irZCore99,
        irAux: toFloat(irAux, 6),
        irDelta: toPercent(irDelta, 2),
        irResult: irResult,
        irStat: toFloat(irStat, 2),
        irP: toPercent(irP, 2),
        cvrZCore90:cvrZCore90,
        cvrZCore95:cvrZCore95,
        cvrZCore99:cvrZCore99,
        cvrAux:toFloat(cvrAux, 6),
        cvrDelta:toPercent(cvrDelta, 2),
        cvrResult:cvrResult,
        cvrStat:toFloat(cvrStat, 2),
        cvrP:toPercent(cvrP, 2),
    };
}