import React from 'react';
import '../app/App.css';
import {withCookies} from 'react-cookie';
import {withRouter} from 'react-router-dom';
import {CatalogApi} from "../api/CatalogApi";
import {ReportApi} from "../api/ReportApi";
import Report from "./common/Report";
import {Card} from "reactstrap";

import AppNavbar from "../navigation/AppNavbar";
import {
    WEB_REPORT_COLUMNS,
    WEB_REPORT_DEFAULT_GROUPINGS,
    WEB_REPORT_DEFAULT_SORTING_MODEL,
    WEB_REPORT_FILTERS_CONFIG
} from "./WebReportConfig";


class WebReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {activeCampaigns: []}
    }

    componentDidMount() {
        document.title = 'BI WEB';
        CatalogApi.getActiveCampaigns().then(activeCampaigns => this.setState({activeCampaigns}))
    }

    render() {
        return (
            <React.Fragment>
                <AppNavbar authorities={this.props.authorities}/>
                <Card className="block form" style={{"textAlign": "left", "fontSize": "smaller"}}>
                    <span>
                    <span style={{"color": "red", "fontWeight": "bold"}}>*</span>
                        В этом очете за установку принимается не факт инициализации
                        SDK Adjust, а ивент 'login_complete', т.к. в web версии пользователь может просто открыть веб-сайт,
                        но не пройти логин. Соответственно, все метрики в этом отчете рассчитаны относительно этого ивента,
                        а не "установки" в привычном ее понимании. Метрику installs стоит интерепретировать как кол-во
                        ивентов 'login_complete', произошедших в конкретный день.
                        </span>
                </Card>
                <Report id="report"
                        profileKey="WEB_REPORT"
                        filterDefs={WEB_REPORT_FILTERS_CONFIG}
                        gridColumnDefs={WEB_REPORT_COLUMNS}
                        gridDefaultGroupings={WEB_REPORT_DEFAULT_GROUPINGS}
                        gridDefaultSortingModel={WEB_REPORT_DEFAULT_SORTING_MODEL}
                        gridActiveCampaigns={this.state.activeCampaigns}
                        loadFunc={ReportApi.getWebData}
                        exportFunc={ReportApi.exportWebData}
                />
            </React.Fragment>

        );
    }
}

export default withCookies(withRouter(WebReport));