import React from "react";
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {calculateTestMetrics} from "../../../common/CreativeTestFunctions";


const StyledTableCell = withStyles((_theme) => ({
    root: {
        padding: 6,
        fontSize: "12px",
        border: "1px solid rgba(224, 224, 224, 1)"
    },
    body: {}
}))(TableCell);

export default function CalculationsTable(props) {
    const render = () => {
        let result = calculateTestMetrics(props.abTest);
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell colspan={17}>Сравнение двух независимых креативов</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell colspan={5} align="center">CTR</StyledTableCell>
                        <StyledTableCell align="center"/>
                        <StyledTableCell colspan={5} align="center">IR</StyledTableCell>
                        <StyledTableCell align="center"/>
                        <StyledTableCell colspan={5} align="center">CVR</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell/>
                        <StyledTableCell>Impressions</StyledTableCell>
                        <StyledTableCell>Click</StyledTableCell>
                        <StyledTableCell>ctr</StyledTableCell>
                        <StyledTableCell>σ</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell/>
                        <StyledTableCell>Click</StyledTableCell>
                        <StyledTableCell>Installs</StyledTableCell>
                        <StyledTableCell>ir</StyledTableCell>
                        <StyledTableCell>σ</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell/>
                        <StyledTableCell>Impressions</StyledTableCell>
                        <StyledTableCell>Installs</StyledTableCell>
                        <StyledTableCell>cvr</StyledTableCell>
                        <StyledTableCell>σ</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <StyledTableCell>A</StyledTableCell>
                        <StyledTableCell>{result.a.impressions}</StyledTableCell>
                        <StyledTableCell>{result.a.clicks}</StyledTableCell>
                        <StyledTableCell>{result.a.ctrText}</StyledTableCell>
                        <StyledTableCell>{result.a.ctrSigma}</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell>A</StyledTableCell>
                        <StyledTableCell>{result.a.clicks}</StyledTableCell>
                        <StyledTableCell>{result.a.installs}</StyledTableCell>
                        <StyledTableCell>{result.a.irText}</StyledTableCell>
                        <StyledTableCell>{result.a.irSigma}</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell>A</StyledTableCell>
                        <StyledTableCell>{result.a.impressions}</StyledTableCell>
                        <StyledTableCell>{result.a.installs}</StyledTableCell>
                        <StyledTableCell>{result.a.cvrText}</StyledTableCell>
                        <StyledTableCell>{result.a.cvrSigma}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>B</StyledTableCell>
                        <StyledTableCell>{result.b.impressions}</StyledTableCell>
                        <StyledTableCell>{result.b.clicks}</StyledTableCell>
                        <StyledTableCell>{result.b.ctrText}</StyledTableCell>
                        <StyledTableCell>{result.b.ctrSigma}</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell>B</StyledTableCell>
                        <StyledTableCell>{result.b.clicks}</StyledTableCell>
                        <StyledTableCell>{result.b.installs}</StyledTableCell>
                        <StyledTableCell>{result.b.irText}</StyledTableCell>
                        <StyledTableCell>{result.b.irSigma}</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell>B</StyledTableCell>
                        <StyledTableCell>{result.b.impressions}</StyledTableCell>
                        <StyledTableCell>{result.b.installs}</StyledTableCell>
                        <StyledTableCell>{result.b.cvrText}</StyledTableCell>
                        <StyledTableCell>{result.b.cvrSigma}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell colspan={2}>Значимость на уровне:</StyledTableCell>
                        <StyledTableCell>z-score</StyledTableCell>
                        <StyledTableCell>auxiliary</StyledTableCell>
                        <StyledTableCell>delta cvr</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell colspan={2}>Значимость на уровне:</StyledTableCell>
                        <StyledTableCell>z-score</StyledTableCell>
                        <StyledTableCell>auxiliary</StyledTableCell>
                        <StyledTableCell>delta cvr</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell colspan={2}>Значимость на уровне:</StyledTableCell>
                        <StyledTableCell>z-score</StyledTableCell>
                        <StyledTableCell>auxiliary</StyledTableCell>
                        <StyledTableCell>delta cvr</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell colspan={2}>90% </StyledTableCell>
                        <StyledTableCell>{result.ctrZCore90}</StyledTableCell>
                        <StyledTableCell>P</StyledTableCell>
                        <StyledTableCell>{result.ctrDelta}</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell colspan={2}>90% </StyledTableCell>
                        <StyledTableCell>{result.irZCore90}</StyledTableCell>
                        <StyledTableCell>P</StyledTableCell>
                        <StyledTableCell>{result.irDelta}</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell colspan={2}>90%</StyledTableCell>
                        <StyledTableCell>{result.cvrZCore90}</StyledTableCell>
                        <StyledTableCell>P</StyledTableCell>
                        <StyledTableCell>{result.cvrDelta}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell colspan={2}>95% </StyledTableCell>
                        <StyledTableCell>{result.ctrZCore95}</StyledTableCell>
                        <StyledTableCell>{result.ctrAux}</StyledTableCell>
                        <StyledTableCell>Result</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell colspan={2}>95% </StyledTableCell>
                        <StyledTableCell>{result.irZCore95}</StyledTableCell>
                        <StyledTableCell>{result.irAux}</StyledTableCell>
                        <StyledTableCell>Result</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell colspan={2}>95%</StyledTableCell>
                        <StyledTableCell>{result.cvrZCore95}</StyledTableCell>
                        <StyledTableCell>{result.cvrAux}</StyledTableCell>
                        <StyledTableCell>Result</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell colspan={2}>99% </StyledTableCell>
                        <StyledTableCell>{result.ctrZCore99}</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell>{result.ctrResult}</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell colspan={2}>99% </StyledTableCell>
                        <StyledTableCell>{result.irZCore99}</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell>{result.irResult}</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell colspan={2}>99%</StyledTableCell>
                        <StyledTableCell>{result.cvrZCore99}</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell>{result.cvrResult}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell colspan={2}>z-score </StyledTableCell>
                        <StyledTableCell colspan={3} rowspan={3}/>
                        <StyledTableCell/>
                        <StyledTableCell colspan={2}>z-score </StyledTableCell>
                        <StyledTableCell colspan={3} rowspan={3}/>
                        <StyledTableCell/>
                        <StyledTableCell colspan={2}>z-score </StyledTableCell>
                        <StyledTableCell colspan={3} rowspan={3}/>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>stat</StyledTableCell>
                        <StyledTableCell>{result.ctrStat}</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell>stat</StyledTableCell>
                        <StyledTableCell>{result.irStat}</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell>stat</StyledTableCell>
                        <StyledTableCell>{result.cvrStat}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>p</StyledTableCell>
                        <StyledTableCell>{result.ctrP}</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell>p</StyledTableCell>
                        <StyledTableCell>{result.irP}</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell>p</StyledTableCell>
                        <StyledTableCell>{result.cvrP}</StyledTableCell>
                    </TableRow>
                </TableBody>
            </Table>);
    }

    return (
        <div>
            {render()}
        </div>
    );
}