import React, {useEffect} from "react";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import {makeStyles} from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import {useForm} from 'react-hook-form'
import DateRangeSelect from "../../../searchpanel/datepicker/DateRangeSelect";
import moment from "moment";
import Snackbar from "../../../components/Snackbar/Snackbar";
import AddAlert from "@material-ui/icons/AddAlert";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Dialog from '@material-ui/core/Dialog';
import {DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {CatalogApi} from "../../../api/CatalogApi";


const useStyles = makeStyles((theme) => ({
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const appOptions = [
    {value: "cm", label: "Clockmaker", id: "appCode"},
    {value: "fe", label: "Funky Bay", id: "appCode"},
    {value: "sc", label: "Solitaire Cruise", id: "appCode"},
    {value: "ba", label: "Bermuda Adventures", id: "appCode"},
];

const platformOptions = [
    {value: "an", label: "Android", id: "platform"},
    {value: "io", label: "iOS", id: "platform"},
    {value: "am", label: "Amazon", id: "platform"},
    {value: "fb", label: "Facebook", id: "platform"}
];

const sourceOptions = [
    {value: "USER_PROVIDED_ONLY", label: "USER_PROVIDED_ONLY", id: "source"},
    {value: "PARTNER_PROVIDED_ONLY", label: "PARTNER_PROVIDED_ONLY", id: "source"},
    {value: "BOTH_USER_AND_PARTNER_PROVIDED", label: "BOTH_USER_AND_PARTNER_PROVIDED", id: "source"}
];

const schemaOptions = [
    {value: "MADID", label: "MADID"},
    {value: "COUNTRY_CODE", label: "COUNTRY_CODE"},
    {value: "LOOKALIKE_VALUE", label: "LOOKALIKE_VALUE"}
]

const countryDefaultValue = [
    {label: "All", value: "All", id: "country"}
];

export default function FacebookUpdateTab(props) {
    const classes = useStyles();

    const [count, setCount] = React.useState(0);
    const [appCode, setAppCode] = React.useState(appOptions[0]);
    const [platform, setPlatform] = React.useState(platformOptions[0]);
    const [source, setSource] = React.useState(sourceOptions[0]);
    const [audience, setAudience] = React.useState({label: "", value: "", id: "audience"});
    const [schema, setSchema] = React.useState([schemaOptions[0]]);
    const [country, setCountry] = React.useState([props.countryOptions[0]]);
    const [audienceOptions, setAudienceOptions] = React.useState([]);
    const [account, setAccount] = React.useState({label: "", value: "", id: "account"});
    const [fbAccounts, setFbAccounts] = React.useState([]);
    const [apps, setApps] = React.useState([]);
    const [query, setQuery] = React.useState({filters: []});
    const [queryOptions, setQueryOptions] = React.useState([]);
    const [appId, setAppId] = React.useState({
        value: "com.belkatechnologies.clockmaker",
        label: "Clockmaker an",
        id: "appId"
    });
    const [startDate, setStartDate] = React.useState(moment().startOf('week').subtract(4, 'week').format("YYYY-MM-DD"));
    const [endDate, setEndDate] = React.useState(moment().subtract(2, 'days').format("YYYY-MM-DD"));
    const {register, handleSubmit} = useForm()
    const [tl, setTL] = React.useState(false);
    const [tr, setTR] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    function showLoading() {
        setLoading(true);
    }

    function hideLoading() {
        setLoading(false);
    }

    function onDatePickerChange(start, end) {
        setStartDate(start.format("YYYY-MM-DD"))
        setEndDate(end.format("YYYY-MM-DD"))
    }

    async function fetchData() {
        CatalogApi.getAppWithPlatformOptions().then(apps => setApps(apps))
        await onAppPlatformUpdate();
        CatalogApi.getAudienceQueryOptions().then(queryOptions => {
            setQuery(queryOptions[0]);
            setQueryOptions(queryOptions);
        })
    }

    async function onAccountUpdate() {
        fetch("/api/facebook/account/" + account.value + "/audiences", {credentials: "include"})
            .then(resp => resp.json())
            .then(resp => {
                if (resp === "") {
                } else {
                    const audienceOptions = [];
                    resp.forEach(elem => audienceOptions.push({
                        value: elem.id,
                        label: elem.name,
                        id: "audience"
                    }));
                    setAudienceOptions(audienceOptions);
                    setAudience(audienceOptions[0]);
                }
            })
            .catch(error => console.log(error))
            .finally(() => {
            });
    }

    async function onAppPlatformUpdate() {
        let selectedApp = apps.filter(f => f.label === appCode.label + " " + platform.value);
        if (selectedApp.length > 0) {
            setAppId(selectedApp[0]);
        }
        fetch("/api/facebook/accounts?appCode=" + appCode.value + "&platform=" + platform.value, {credentials: "include"})
            .then(resp => resp.json())
            .then(resp => {
                if (resp === "") {
                } else {
                    const accountOptions = [];
                    resp.forEach(elem => accountOptions.push({
                        value: elem.id,
                        label: elem.name,
                        id: "account"
                    }));
                    setFbAccounts(accountOptions);
                    setAccount(accountOptions[0]);
                }
            })
            .catch(error => console.log(error))
            .finally(() => {
            });
    }

    useEffect(() => {
        fetchData();
    }, [])

    useEffect(() => {
        onAppPlatformUpdate();
    }, [appCode, platform])

    useEffect(() => {
        onAccountUpdate();
    }, [account])

    const showNotification = place => {
        switch (place) {
            case "tl":
                if (!tl) {
                    setTL(true);
                    setTimeout(function () {
                        setTL(false);
                    }, 6000);
                }
                break;
            case "tr":
                if (!tr) {
                    setTR(true);
                    setTimeout(function () {
                        setTR(false);
                    }, 60000);
                }
                break;
            default:
                break;
        }
    }

    function getBody(data) {
        let filters = {}
        query.filters.forEach(filter => {
            if (filter.name === "eventDate" || filter.name === "installDate") {
                filters.startDate = data.startDate
                filters.endDate = data.endDate
            } else if (filter.name === "appId") {
                filters.appId = appId.value;
            } else {
                let value = data[filter.name].value;
                if (value) {
                    filters[filter.name] = value
                } else {
                    filters[filter.name] = data[filter.name]
                }

            }
        });
        filters.countryCode = data.country.split(',');
        return {
            queryId: query.value,
            filters: filters,
            schema: data.schema.split(',')
        };
    }

    function onAudienceUpload(data) {
        showLoading();
        const cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith('XSRF-TOKEN'))
            .split('=')[1]
        const body = JSON.stringify(getBody(data))
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-XSRF-TOKEN": cookieValue
            },
            body: body
        };
        let url = "/api/facebook/account/" + account.value + "/audience/" + audience.value;
        fetch(url, requestOptions)
            .then(async response => {
                let responseText = await response.text();
                let status = response.status;
                if (status === 200) {
                    console.log("success")
                    setSuccessMessage("Audience under account " + account.label + " successfully updated")
                    showNotification("tl");
                } else {
                    setErrorMessage(responseText);
                    showNotification("tr");
                    console.log("error")
                }
            })
            .catch(error => {
                console.log(error);
                setErrorMessage(error.toString());
                showNotification("tr");
            })
            .finally(() => hideLoading());
    }

    function onAudienceCount(data) {
        showLoading();
        console.log(JSON.stringify(data))
        const cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith('XSRF-TOKEN'))
            .split('=')[1]
        const body = JSON.stringify(getBody(data))
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "X-XSRF-TOKEN": cookieValue
            },
            body: body
        };
        let url = "/api/facebook/account/" + account.value + "/audience/" + audience.value + "/count";
        fetch(url, requestOptions)
            .then(async response => {
                let responseText = await response.text();
                let status = response.status;
                if (status === 200) {
                    setCount(responseText)
                    handleClickOpen();
                    console.log("success")
                    setSuccessMessage("Audience under account " + account.label + " successfully updated")
                    showNotification("tl");
                } else {
                    setErrorMessage(responseText);
                    showNotification("tr");
                    console.log("error")
                }
            })
            .catch(error => {
                console.log(error);
                setErrorMessage(error.toString());
                showNotification("tr");
            })
            .finally(() => hideLoading());
    }

    function onAudiencePreview(data) {
        showLoading();
        console.log(JSON.stringify(data))
        const cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith('XSRF-TOKEN'))
            .split('=')[1]
        const body = JSON.stringify(getBody(data))
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "text/csv; charset=utf-8",
                // "Accept": "application/json",
                "X-XSRF-TOKEN": cookieValue
            },
            body: body
        };
        let url = "/api/facebook/account/" + account.value + "/audience/" + audience.value + "/preview";
        fetch(url, requestOptions)
            .then(async response => {
                let blob = await response.blob();
                let status = response.status;
                if (status === 200) {
                    console.log("success")
                    let fileName = "audience_preview.csv";
                    let a = document.createElement('a');
                    a.href = window.URL.createObjectURL(blob);
                    a.download = fileName;
                    a.dispatchEvent(new MouseEvent('click'));
                    setSuccessMessage("Audience under account " + account.label + " successfully returned preview records")
                    showNotification("tl");
                } else {
                    let responseText = response.text();
                    setErrorMessage(responseText);
                    showNotification("tr");
                    console.log("error")
                }
            })
            .catch(error => {
                console.log(error);
                setErrorMessage(error.toString());
                showNotification("tr");
            })
            .finally(() => hideLoading());
    }

    const handleMultiChange = (option) => {
        let result = option;
        if (option) {
            let countries = option.filter(country => {
                return country.value !== 'All'
            });
            if (countries.length > 0) {
                result = countries;
            } else {
                result = countryDefaultValue;
            }
        } else {
            result = countryDefaultValue;
        }
        setCountry(result);
    }

    function isVisible(id) {
        let element = query.filters.filter(f => f.name === id);
        return element.length > 0;
    }

    const handleUpload = handleSubmit(data => onAudienceUpload(data))
    const handlePreview = handleSubmit(data => onAudiencePreview(data))
    const handleCount = handleSubmit(data => onAudienceCount(data))

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Card>
                <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Upload Audience</h4>
                    <p className={classes.cardCategoryWhite}/>
                </CardHeader>
                <CardBody>
                    <form className={classes.form} noValidate onSubmit={handleSubmit(onAudienceUpload)}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    value={appCode}
                                    onChange={(event, newValue) => {
                                        setAppCode(newValue);
                                    }}
                                    options={appOptions}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="App"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    value={platform}
                                    onChange={(event, newValue) => {
                                        setPlatform(newValue);
                                    }}
                                    options={platformOptions}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Platform"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    value={account}
                                    onChange={(event, newValue) => {
                                        setAccount(newValue);
                                    }}
                                    inputRef={register}
                                    options={fbAccounts}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Account"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    value={query}
                                    onChange={(event, newValue) => {
                                        setQuery(newValue);
                                    }}
                                    options={queryOptions}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Query"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    value={audience}
                                    onChange={(event, newValue) => {
                                        setAudience(newValue);
                                    }}
                                    options={audienceOptions}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable
                                    renderInput={(params) => <TextField {...params} label="Audience"
                                                                        variant="standard"/>}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    multiple
                                    options={schemaOptions}
                                    getOptionLabel={(option) => option.label}
                                    defaultValue={[schemaOptions[0]]}
                                    onChange={(event, newValue) => {
                                        setSchema(newValue);
                                    }}
                                    inputRef={register}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Schema fields"
                                        />
                                    )}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <Autocomplete
                                    fullWidth
                                    multiple
                                    value={country}
                                    options={props.countryOptions}
                                    getOptionLabel={(option) => option.label}
                                    defaultValue={[props.countryOptions[0]]}
                                    onChange={(event, newValue) => {
                                        handleMultiChange(newValue)
                                    }}
                                    inputRef={register}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Country"
                                        />
                                    )}
                                />
                            </GridItem>
                            <input type="hidden" ref={register} name="schema" value={schema.map(item => item.value)}/>
                            <input type="hidden" ref={register} name="country" value={country.map(item => item.value)}/>
                        </GridContainer>
                        <GridContainer>
                            {isVisible("eventDate") || isVisible("installDate") ?
                                <GridItem xs={12} sm={12} md={3}>
                                    <DateRangeSelect onDatePickerChange={onDatePickerChange}/>
                                </GridItem> : null}
                            <input type="hidden" ref={register} name="startDate" value={startDate}/>
                            <input type="hidden" ref={register} name="endDate" value={endDate}/>
                            {isVisible("revenueLimit") ?
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormControl className={classes.formControl}>
                                        <TextField required inputRef={register} name="revenueLimit"
                                                   label="Revenue limit" defaultValue="=0"
                                        />
                                    </FormControl>
                                </GridItem> : null}
                            {isVisible("level") ?
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormControl className={classes.formControl}>
                                        <TextField required inputRef={register} name="level"
                                                   label="Level" defaultValue="level_5"
                                        />
                                    </FormControl>
                                </GridItem> : null}
                            {isVisible("cohortDay") ?
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormControl className={classes.formControl}>
                                        <TextField required inputRef={register} name="cohortDay"
                                                   label="Cohort day" defaultValue="7"
                                        />
                                    </FormControl>
                                </GridItem> : null}
                            {isVisible("retainedDay") ?
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormControl className={classes.formControl}>
                                        <TextField required inputRef={register} name="retainedDay"
                                                   label="Retained day" defaultValue=">0"
                                        />
                                    </FormControl>
                                </GridItem> : null}
                            {isVisible("activityHours") ?
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormControl className={classes.formControl}>
                                        <TextField required inputRef={register} name="activityHours"
                                                   label="Activity Hours" defaultValue="80"
                                        />
                                    </FormControl>
                                </GridItem> : null}
                            {isVisible("amount") ?
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormControl className={classes.formControl}>
                                        <TextField required inputRef={register} name="amount"
                                                   label="Amount" defaultValue=">0"
                                        />
                                    </FormControl>
                                </GridItem> : null}
                            <GridItem xs={12} sm={12} md={3}>
                                <Button type={"submit"} variant="contained" color="primary">Upload</Button>
                                <Snackbar
                                    place="tl"
                                    color="success"
                                    icon={AddAlert}
                                    message={successMessage}
                                    open={tl}
                                    closeNotification={() => setTL(false)}
                                    close
                                />
                                <Snackbar
                                    place="tr"
                                    color="error"
                                    icon={AddAlert}
                                    message={errorMessage}
                                    open={tr}
                                    closeNotification={() => setTR(false)}
                                    close
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <Button type={"button"} onClick={handlePreview} variant="contained"
                                        color="primary">Sample</Button>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={3}>
                                <Button type={"button"} onClick={handleCount} variant="contained"
                                        color="primary">Count</Button>
                            </GridItem>
                        </GridContainer>
                    </form>
                </CardBody>
            </Card>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Audience count"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{count}</DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );

}
