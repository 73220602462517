/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Person from "@material-ui/icons/Person";

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import FacebookIcon from '@material-ui/icons/Facebook';
import TableChartIcon from '@material-ui/icons/TableChart';
import AppleIcon from '@material-ui/icons/Apple';
// core components/views for Admin layout
import Audience from "./views/audience/facebook/Audience";
import DeviceReport from "./report/DeviceReport";
import TikTokAudience from "./views/audience/tiktok/TikTokAudience";
import AsoComparativeReport from "./views/aso/AsoComparativeReport";
import EditAbtestPage from "./views/creativeTest/EditAbtestPage";
import CreativeReportBq from "./report/CreativeReport";
import RetargetingReport from 'report/RetargetingReport';
import UserManagement from "./page/UserManagement";
import FacebookAbTest from "./views/facebookAbTest/AbTest";
import UamReport from "./report/UamReport";
import AdMonetizationReport from "./views/adMonetization/AdMonetizationReport";


export const dashboardRoutes = (authorities) => {
    let result = [];

    if (authorities.includes("UPLOAD_FB_AUDIENCE") ||
        authorities.includes("READ_FB_AUDIENCE") ||
        authorities.includes("DELETE_FB_AUDIENCE") ||
        authorities.includes("CREATE_FB_AUDIENCE")
    ) {
        result.push({
            path: "/audience/facebook",
            name: "Facebook Audience",
            icon: Person,
            component: Audience,
            layout: "/tool"
        })
    }

    if (authorities.includes("READ_TIKTOK_AUDIENCE") ||
        authorities.includes("UPDATE_TIKTOK_AUDIENCE") ||
        authorities.includes("CREATE_TIKTOK_AUDIENCE")
    ) {
        result.push({
            path: "/audience/tiktok",
            name: "TikTok Audience",
            icon: Person,
            component: TikTokAudience,
            layout: "/tool"
        })
    }

    // if (authorities.includes("SEARCH_CREATIVES")) {
    //     result.push({
    //         path: "/creativeSearch",
    //         name: "Creative Search",
    //         icon: ImageSearchIcon,
    //         component: CreativeSearch,
    //         layout: "/tool"
    //     })
    // }
    if (authorities.includes("READ_ASO_COMPARATIVE_REPORT")) {
        result.push({
            path: "/aso",
            name: "ASO Comparative",
            icon: AppleIcon,
            component: AsoComparativeReport,
            layout: "/tool"
        })
    }
    result.push({
        path: "/facebook-ab-tests",
        name: "Facebook A/B tests",
        icon: FacebookIcon,
        component: FacebookAbTest,
        layout: "/tool"
    })
    // if (authorities.includes("READ_TOTAL_AB_TEST")) {
    //     result.push({
    //         path: "/abtests",
    //         name: "Total list",
    //         icon: FacebookIcon,
    //         component: TotalExcelList,
    //         layout: "/tool"
    //     })
    // }
    // if (authorities.includes("CREATE_AB_TEST")) {
    //     result.push({
    //         path: "/newabtest",
    //         name: "Add test",
    //         icon: FacebookIcon,
    //         component: CreateAbtestPage,
    //         layout: "/tool"
    //     })
    // }
    if (authorities.includes("READ_AB_TEST") ||
        authorities.includes("EDIT_AB_TEST")
    ) {
        result.push({
            path: "/abtest/:id",
            name: "Edit test",
            icon: FacebookIcon,
            component: EditAbtestPage,
            layout: "/tool",
            hide: true
        })
    }

    if (authorities.includes("MANAGE_USERS")) {
        result.push({
            path: "/user-management",
            name: "User Management",
            icon: Person,
            component: UserManagement,
            layout: "/tool"
        })
    }

    if (authorities.includes("READ_AD_REVENUE_COMPARE_REPORT")) {
        result.push({
            path: "/ad-monetization",
            name: "Ad Monetization",
            icon: MonetizationOnIcon,
            component: AdMonetizationReport,
            layout: "/tool"
        })
    }

    return result;
};
