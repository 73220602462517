import React, {useEffect} from "react";
// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
// core components
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FacebookIcon from '@material-ui/icons/Facebook';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import FacebookCreateTab from "./FacebookCreateTab";
import FacebookUpdateTab from "./FacebookUpdateTab";
import FacebookAutoUploadTab from "./FacebookAutoUploadTab";
import {CatalogApi} from "../../../api/CatalogApi";

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const useStyles = makeStyles(styles);

export default function Audience(props) {

    document.title = 'Facebook Audience';

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [countryOptions, setCountryOptions] = React.useState([]);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        fetchData();
    }, [])

    const fetchData = () => {
        CatalogApi.getCountriesOptions().then(countryOptions => setCountryOptions(countryOptions))
    }

    return (
        <div>
            <h5 style={{color: 'red'}}><b>Инструмент создания и обновления на данный момент не поддерживаются!</b></h5>
            <h5 style={{color: 'red'}}><b>Автообновление работает.</b></h5>
            <Tabs
                value={value}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon tabs example">

                <Tab icon={<FacebookIcon/>} aria-label="phone" label="Create"/>
                <Tab icon={<FacebookIcon/>} aria-label="phone" label="Update"/>
                <Tab icon={<FacebookIcon/>} aria-label="phone" label="Autoupload"/>
            </Tabs>
            <TabPanel value={value} index={0}>
                <FacebookCreateTab {...props}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <FacebookUpdateTab countryOptions={countryOptions}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <FacebookAutoUploadTab countryOptions={countryOptions}/>
            </TabPanel>
        </div>
    );
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}