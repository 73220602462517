import Handsontable from 'handsontable';

export const baseMetricRenderer = function (instance, td, row, col, prop, value, cellProperties) {
    td.style.background = '#e2ffda';
    Handsontable.renderers.NumericRenderer.apply(this, arguments);
}

export const calcMetricRenderer = function (instance, td, row, col, prop, value, cellProperties) {
    td.style.background = '#f9ffb5';
    if (Number.isNaN(value) || !Number.isFinite(value)) {
        arguments[5] = '-'
    }
    Handsontable.renderers.NumericRenderer.apply(this, arguments);
}

export const resultRenderer = function (instance, td, row, col, prop, value, cellProperties) {
    const escaped = Handsontable.helper.stringify(value);
    if (escaped === 'bad') {
        td.style.background = '#ea3b3b';
    } else if (escaped === 'normal') {
        td.style.background = '#fdf3bf';
    } else if (escaped === 'good') {
        td.style.background = '#a0d086';
    } else if (escaped === 'best') {
        td.style.background = '#6ed42f';
    } else {
        td.style.background = '#ffffff';
    }
    Handsontable.renderers.TextRenderer.apply(this, arguments);
}