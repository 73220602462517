import React from 'react';
import '../app/App.css';
import {withCookies} from 'react-cookie';
import {withRouter} from 'react-router-dom';
import {ReportApi} from "../api/ReportApi";
import {
    DEVICE_REPORT_COLUMNS,
    DEVICE_REPORT_DEFAULT_GROUPINGS,
    DEVICE_REPORT_DEFAULT_SORTING_MODEL,
    DEVICE_REPORT_FILTERS_CONFIG
} from "./DeviceReportConfig";
import Report from "./common/Report";
import {CatalogApi} from "../api/CatalogApi";
import AppNavbar from "../navigation/AppNavbar";


class DeviceReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeCampaigns: [],
        }
    }

    componentDidMount() {
        document.title = 'BI Device';
        CatalogApi.getActiveCampaigns().then(activeCampaigns => this.setState({activeCampaigns}))
    }

    render() {
        return (
            <React.Fragment>
                <AppNavbar authorities={this.props.authorities}/>
                <Report authorities={this.props.authorities}
                        profileKey="DEVICE_REPORT"
                        filterDefs={DEVICE_REPORT_FILTERS_CONFIG}
                        gridColumnDefs={DEVICE_REPORT_COLUMNS}
                        gridDefaultGroupings={DEVICE_REPORT_DEFAULT_GROUPINGS}
                        gridDefaultSortingModel={DEVICE_REPORT_DEFAULT_SORTING_MODEL}
                        gridActiveCampaigns={this.state.activeCampaigns}
                        loadFunc={ReportApi.getUamData}
                        exportFunc={ReportApi.exportUamData}
                />
            </React.Fragment>
        );
    }
}

export default withCookies(withRouter(DeviceReport));
