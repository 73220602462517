import React from 'react';
import '../app/App.css';
import {withCookies} from 'react-cookie';
import {withRouter} from 'react-router-dom';
import {CatalogApi} from "../api/CatalogApi";
import {ReportApi} from "../api/ReportApi";

import Report from "./common/Report";
import {
    EVENT_REPORT_COLUMNS,
    EVENT_REPORT_DEFAULT_GROUPINGS,
    EVENT_REPORT_DEFAULT_SORTING_MODEL,
    EVENT_REPORT_FILTERS_CONFIG
} from "./EventReportConfig";
import AppNavbar from "../navigation/AppNavbar";


class EventReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeCampaigns: [],
            columnDefs: null
        }
    }

    componentDidMount() {
        document.title = 'BI Events';
        CatalogApi.getActiveCampaigns().then(activeCampaigns => this.setState((prevState) => ({
            ...prevState,
            activeCampaigns
        })))
        CatalogApi.getEvents().then(eventColumns => this.setState((prevState) => ({
            ...prevState,
            columnDefs: [...eventColumns, ...EVENT_REPORT_COLUMNS]
        })))
    }

    render() {
        return (
            <React.Fragment>
                <AppNavbar authorities={this.props.authorities}/>
                {this.state.columnDefs &&
                <Report authorities={this.props.authorities}
                        profileKey="EVENT_REPORT"
                        filterDefs={EVENT_REPORT_FILTERS_CONFIG}
                        gridColumnDefs={this.state.columnDefs}
                        gridDefaultGroupings={EVENT_REPORT_DEFAULT_GROUPINGS}
                        gridDefaultSortingModel={EVENT_REPORT_DEFAULT_SORTING_MODEL}
                        gridActiveCampaigns={this.state.activeCampaigns}
                        loadFunc={ReportApi.getEventData}
                        exportFunc={ReportApi.exportEventData}
                />
                }
            </React.Fragment>
        )
    }
}

export default withCookies(withRouter(EventReport));

