import React from 'react';
import '../app/App.css';
import {withCookies} from 'react-cookie';
import {withRouter} from 'react-router-dom';
import {withSnackbar} from 'notistack';
import AppNavbar from "../navigation/AppNavbar";
import {CatalogApi} from "../api/CatalogApi";
import {ReportApi} from "../api/ReportApi";
import {
    UAM_REPORT_COLUMNS,
    UAM_REPORT_DEFAULT_GROUPINGS,
    UAM_REPORT_DEFAULT_SORTING_MODEL,
    UAM_REPORT_FILTERS_CONFIG
} from "./UamReportConfig";
import Report from "./common/Report";


class UamReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {activeCampaigns: []}
    }

    componentDidMount() {
        document.title = 'BI UAM';
        CatalogApi.getActiveCampaigns().then(activeCampaigns => this.setState({activeCampaigns}))
    }

    render() {
        return (
            <React.Fragment>
                <AppNavbar authorities={this.props.authorities}/>
                <Report authorities={this.props.authorities}
                        profileKey="UAM_REPORT"
                        filterDefs={UAM_REPORT_FILTERS_CONFIG}
                        gridColumnDefs={UAM_REPORT_COLUMNS}
                        gridDefaultGroupings={UAM_REPORT_DEFAULT_GROUPINGS}
                        gridDefaultSortingModel={UAM_REPORT_DEFAULT_SORTING_MODEL}
                        gridActiveCampaigns={this.state.activeCampaigns}
                        loadFunc={ReportApi.getUamData}
                        exportFunc={ReportApi.exportUamData}
                />
            </React.Fragment>
        );
    }
}

export default withCookies(withSnackbar(withRouter(UamReport)));

export function buildAggregateFilterModel(state) {
    let filters = {};
    if (state.additionalFilters.find((option) => option.value === 'hideInactive')) {
        filters.installs = {'filterType': 'number', 'filter': 0, 'type': 'notEqual'};
        filters.spend = {'filterType': 'number', 'filter': 0, 'type': 'notEqual'};
    }

    return filters
}