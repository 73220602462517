import React, {Component} from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import TextField from "@material-ui/core/TextField";
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import {withCookies} from "react-cookie";
import moment from 'moment'

class MaterialDateRange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: props.startDate ? moment(props.startDate) : moment().startOf('week').subtract(4, 'week'),
            endDate: props.endDate ? moment(props.endDate) : moment().subtract(2, 'days')
        };
        this.onEvent = this.onEvent.bind(this);
    }

    onEvent(start, end) {
        this.setState({
            startDate: start,
            endDate: end
        });
        this.props.onDatePickerChange(start, end);
    }

    render() {
        let label = this.state.startDate.format("YYYY-MM-DD") + " - " + this.state.endDate.format("YYYY-MM-DD");
        let maxDate = moment().subtract(1, 'days');
        return (
            <DateRangePicker
                initialSettings={{
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    minDate: this.state.minDate,
                    maxDate: maxDate,
                    ranges: {
                        'Default Period': [moment().startOf('week').subtract(4, 'week'), moment().subtract(2, 'days')],
                        'Today': [moment(), moment()],
                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                        'Last 4 Weeks': [moment().subtract(4, 'weeks').startOf('week').add('days', 1), moment().startOf('week')],
                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                    },
                    alwaysShowCalendars: true,
                    locale: {
                        format: 'YYYY-MM-DD'
                    }
                }}
                onCallback={this.onEvent}>
                <div className="form-group">
                    <TextField id="standard-basic" style={this.props.style} readOnly label={this.props.name} value={label}/>
                </div>
            </DateRangePicker>
        );
    }
}

export default withCookies(MaterialDateRange);