/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} body      The body of POST request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {Promise}           The request promise
 */
export function post(url, body, options) {
    let headers = makeHeaders();

    let defaultOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers
    };
    options = Object.assign(defaultOptions, options);

    return executeRequest(url, options);
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} body      The body of PUT request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {Promise}           The request promise
 */
export function put(url, body, options) {
    let headers = makeHeaders();

    let defaultOptions = {
        method: "PUT",
        body: JSON.stringify(body),
        headers
    };
    options = Object.assign(defaultOptions, options);

    return executeRequest(url, options);
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} body      The body of DELETE request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {Promise}           The request promise
 */
export function del(url, options) {
    let headers = makeHeaders();

    let defaultOptions = {
        method: "DELETE",
        headers
    };
    options = Object.assign(defaultOptions, options);

    return executeRequest(url, options);
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {Promise}           The request promise
 */
export function get(url, options) {
    return executeRequest(url, options);
}

function makeHeaders() {
    return {"Content-Type": "application/json"};
}

function executeRequest(url, options) {
    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then(parseJsonResponse)
            .then((response) => response.ok ? resolve(response) : reject(response))
            .catch((error) => {
                console.log(error.message);
                reject({networkError: error.message,})
            });
    });
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON, status from the response
 */
function parseJsonResponse(response) {
    return new Promise((resolve) =>
        response.text()// Parse the response as text
            .then(text => {
                try {
                    const data = JSON.parse(text); // Try to parse the response as JSON
                    // the response was a JSON object, put response json to data property
                    return resolve({
                        status: response.status,
                        ok: response.ok,
                        body: data,
                    })
                } catch (err) {
                    // the response wasn't a JSON object, use plain text body for data property
                    return resolve({
                        status: response.status,
                        ok: response.ok,
                        body: text,
                    })
                }
            })
    );
}


function parseFileResponse(response) {
    return new Promise((resolve) =>
        response.blob() // Parse the response as blob
            .then(blob => {
                try {
                    return resolve({
                        status: response.status,
                        ok: response.ok,
                        body: blob,
                    })
                } catch (err) {
                    // the response wasn't a JSON object, use plain text body for data property
                    return resolve({
                        status: response.status,
                        ok: response.ok,
                        body: null,
                    })
                }
            })
    );
}


export function postAndDownload(url, body, fileName, options) {
    let headers = makeHeaders();

    let defaultOptions = {
        method: "POST",
        body: JSON.stringify(body),
        headers
    };
    options = Object.assign(defaultOptions, options);

    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then(parseFileResponse)
            .then((response) => {
                if (response.ok) {
                    executeDownload(response, fileName)
                    resolve(response);
                } else {
                    reject(response);
                }
            })
            .catch((error) => {
                console.log(error.message);
                reject({networkError: error.message})
            });
    });
}

export function executeDownload(response, fileName) {
    let a = document.createElement('a');
    a.href = window.URL.createObjectURL(response.body);
    a.download = fileName;
    a.dispatchEvent(new MouseEvent('click'));

    return response;
}
