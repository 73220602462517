import {del, get, post, put} from "../common/ApiUtils";

export const UserApi = {

    getUsers() {
        return get('/api/users')
    },

    saveUser(user) {
        return post('/api/users', user)
    },

    deleteUser(userId) {
        return del('/api/users/' + userId)
    },

    saveProfile(profile) {
        return post('/api/users/profiles', profile)
    },

    updateProfile(profile) {
        return put('/api/users/profiles', profile)
    },

    deleteProfile(profileId) {
        return del('/api/users/profiles/' + profileId)
    },

    listAvailableProfiles(profileKey) {
        return get("/api/users/profiles/" + profileKey)
    },

    shareProfile(profileId, userIds) {
        let params = new URLSearchParams({
            profileId: profileId,
            userIds: userIds,
        });
        return get('/api/users/profiles/share?' + params)
    },
}
