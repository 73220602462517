import React from "react";
import {withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {calculateTestMetrics} from "../../../common/CreativeTestFunctions";


const StyledTableCell = withStyles((_theme) => ({
    root: {
        padding: 6,
        fontSize: "12px",
        border: "1px solid rgba(224, 224, 224, 1)"
    },
    body: {
    },
}))(TableCell);

export default function TopCalculationsTable(props) {
    const render = () => {
        let result = calculateTestMetrics(props.abTest);
        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell colspan={11}>Устойчивость Confidence 90% normal bound 1.64</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell colspan={2} rowspan={2} align="center">Analysis</StyledTableCell>
                        <StyledTableCell colspan={4} align="center">Bounds</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell colspan={2} align="center">Wald</StyledTableCell>
                        <StyledTableCell colspan={2} align="center">Wilson</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>Variance</StyledTableCell>
                        <StyledTableCell>by bounds</StyledTableCell>
                        <StyledTableCell>lowCVR</StyledTableCell>
                        <StyledTableCell>highCVR</StyledTableCell>
                        <StyledTableCell>lowCVR</StyledTableCell>
                        <StyledTableCell>highCVR</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <StyledTableCell>{result.a.variance}</StyledTableCell>
                        <StyledTableCell/>
                        <StyledTableCell>{result.a.waldLowCVR}</StyledTableCell>
                        <StyledTableCell>{result.a.waldHighCVR}</StyledTableCell>
                        <StyledTableCell>{result.a.wilsonLowCVR}</StyledTableCell>
                        <StyledTableCell>{result.a.wilsonHighCVR}</StyledTableCell>
                    </TableRow>
                    <TableRow>
                        <StyledTableCell>{result.b.variance}</StyledTableCell>
                        <StyledTableCell>{result.byBounds}</StyledTableCell>
                        <StyledTableCell>{result.b.waldLowCVR}</StyledTableCell>
                        <StyledTableCell>{result.b.waldHighCVR}</StyledTableCell>
                        <StyledTableCell>{result.b.wilsonLowCVR}</StyledTableCell>
                        <StyledTableCell>{result.b.wilsonHighCVR}</StyledTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        );
    }

    return (
        <div>
            {render()}
        </div>
    );
}