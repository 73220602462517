import React, {Component} from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import {withCookies} from "react-cookie";
import moment from 'moment'

class DateRangeSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().startOf('week').subtract(4, 'week'),
            endDate: moment().subtract(2, 'days'),
            minDate: props.minDate ? moment(props.minDate) : moment("2021-04-01"),
            maxDate: props.maxDate ? moment(props.maxDate) : moment().subtract(1, 'days')
        };
        this.onEvent = this.onEvent.bind(this);
    }

    onEvent(start, end) {
        this.setState({
            startDate: start,
            endDate: end
        });
        this.props.onDatePickerChange(start, end);
    }

    render() {
        let startDate = this.state.startDate.isAfter(this.state.minDate)
            ? this.state.startDate
            : this.state.minDate;
        return (
            <div>
                <DateRangePicker
                    onCallback={this.onEvent}
                    initialSettings={{
                        startDate: startDate,
                        endDate: this.state.endDate,
                        minDate: this.state.minDate,
                        maxDate: this.state.maxDate,
                        ranges: {
                            'Default Period': [moment().startOf('week').subtract(4, 'week'), moment().subtract(2, 'days')],
                            'Today': [moment(), moment()],
                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                            'Last 4 Weeks': [moment().subtract(4, 'weeks').startOf('week').add('days', 1), moment().startOf('week')],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                        },
                        alwaysShowCalendars: true,
                        locale: {
                            format: 'YYYY-MM-DD'
                        }
                    }}>

                    <input type="text" className="date-range-select"/>
                </DateRangePicker>
            </div>
        );
    }
}

export default withCookies(DateRangeSelect);
